import React, {useState} from "react"
import {Post} from './post'
import {PostOptions} from "./post/post-options"
import {PostEdit, ReportPost} from "../../modals"
import {ConfirmationBox, DeletedItem, ImageGallery} from "../../../components"
import {LikeList, Replies, GoalList} from './modals'

export const Feed = ({
                       posts,
                       avatars,
                       router,
                       openModal,
                       userId,
                       selectPost,
                       selectedPost,
                       deletePost,
                       selectedMedia,
                       clearSelectedMedia,
                       postSaveActions,
                       dictionary,
                     }) => {

  const [postForOptions, setPostForOptions] = useState(null)
  const [postForDeletion, setPostForDeletion] = useState(null)
  const [postForLikeList, setPostForLikeList] = useState(null)
  const [postForGoalList, setPostForGoalList] = useState(null)

  return (
    <>
      {
        posts?.length
          ?
          posts.map((post, index) => {
            return (
              !post.post_active || post?.deleted
                ?
                <DeletedItem
                  text={post?.deleted ? dictionary.deleted_post : dictionary.reported_post}
                  key={index}
                />
                :
                <Post key={index}
                      post={post}
                      avatars={avatars}
                      parent={router}
                      setPostForOptions={setPostForOptions}
                      setPostForLikeList={setPostForLikeList}
                      setPostForGoalList={setPostForGoalList}
                      openModal={openModal}
                      postSaveActions={postSaveActions}
                />
            )
          })
          :
          <></>
      }

      {/*{hasMorePosts && <IonButton size='small' onClick={() => {downloadPosts(selectedJourney?.id, true)}}>See more posts</IonButton>}*/}

      <PostOptions
        selectedPost={postForOptions}
        openModal={openModal}
        userId={userId}
        selectPost={selectPost}
        setPostForOptions={setPostForOptions}
        setPostForDeletion={setPostForDeletion}
        dictionary={dictionary}/>

      <PostEdit
        selectedPost={selectedPost}
        selectPost={selectPost}
        parent={router}
        postSaveActions={postSaveActions}/>

      <Replies
        parent={router}
        postSaveActions={postSaveActions}
        setPostForLikeList={setPostForLikeList} />

      <ConfirmationBox
        isOpen={Boolean(postForDeletion)}
        message={dictionary.confirm_delete}
        handler={() => deletePost(postForDeletion, postSaveActions)}
        onDismiss={() => setPostForDeletion(null)} />

      <ImageGallery
        selectedMedia={selectedMedia}
        clearSelectedMedia={clearSelectedMedia}
        parent={router} />

      <LikeList
        post={postForLikeList}
        close={() => setPostForLikeList(null)}
        parent={router} />

      <GoalList
        post={postForGoalList}
        close={() => setPostForGoalList(null)}
        parent={router} />

      <GoalList />

      <ReportPost parent={router} />
    </>
  )
}