import {
  IonItem,
  IonSkeletonText,
  IonList, IonCard, IonCardContent
} from '@ionic/react';
import React from "react";

export const SkeletonText = ({ isVisible, styles }) => {
  if (!isVisible) return null

  return (
    <IonList lines='none'>
      <IonItem>
        <IonCard style={{ width: '100%' }}>
          <IonCardContent>
            <div className={styles.goal_container}>

              <div className={styles.content}>
                <div className={styles.rating}>
                  <IonSkeletonText animated style={{ height: '80px'}} />
                </div>
                <div className={styles.details}>
                  <h1>
                    <IonSkeletonText animated style={{ width : '50%' }} />
                  </h1>
                  <p>
                    <IonSkeletonText animated />
                    <IonSkeletonText animated />
                    <IonSkeletonText animated />
                  </p>
                </div>
              </div>

              <div className={styles.footer}>
                <IonSkeletonText animated style={{ width: '20px', height: '20px' }} />
                <IonSkeletonText animated style={{ width: '20px', height: '20px' }} />
              </div>

            </div>

          </IonCardContent>
        </IonCard>
      </IonItem>

      <IonItem>
        <IonCard style={{ width: '100%' }}>
          <IonCardContent>
            <div className={styles.goal_container}>

              <div className={styles.content}>
                <div className={styles.rating}>
                  <IonSkeletonText animated style={{ height: '80px'}} />
                </div>
                <div className={styles.details}>
                  <h1>
                    <IonSkeletonText animated style={{ width : '50%' }} />
                  </h1>
                  <p>
                    <IonSkeletonText animated />
                    <IonSkeletonText animated />
                    <IonSkeletonText animated />
                  </p>
                </div>
              </div>

              <div className={styles.footer}>
                <IonSkeletonText animated style={{ width: '20px', height: '20px' }} />
                <IonSkeletonText animated style={{ width: '20px', height: '20px' }} />
              </div>

            </div>

          </IonCardContent>
        </IonCard>
      </IonItem>
    </IonList>
  )
}