import {tokenActive } from "../utils/api/user"
import { setLoggedIn } from "../user-management/action-creators"
import {setAppInitialised, setBusy, setOrganisationSelected,
        setValidLoginToken, setVersionNumber, setOrganisationSettings} from "./action-types"
import { initialiseUser } from '../user-management/logic'
import {initialiseJourneys} from '../user-journey'
import {Capacitor} from "@capacitor/core"
import {App} from "@capacitor/app"
import {selectLanguage} from "./actions"

export const initialiseApp = () => async (dispatch) => {

  dispatch(setAppInitialised(false))
  dispatch(setBusy(true))

  // Set version number
  dispatch(setVersionNumber(await getVersionNumber()))

  // Set organisation settings
  const orgSettings = await getOrganisationSettings()
  dispatch(setOrganisationSettings(orgSettings))

  // Set the language dictionary for the app
  dispatch(selectLanguage())

  // Check if the user has used their access code
  const organisationName = localStorage.getItem('organisation_details')
  dispatch(setOrganisationSelected(!!organisationName))
  if(!organisationName){
    dispatch(setAppInitialised(true))
    dispatch(setBusy(false))
    return
  }

  const tokenIsActive = await tokenActive(dispatch)
  dispatch(setValidLoginToken(!!tokenIsActive))
  if(!tokenIsActive){
    dispatch(setAppInitialised(true))
    dispatch(setBusy(false))
    return
  }

  const userId = await initialiseUser(dispatch)
  if(!userId){
    dispatch(setAppInitialised(true))
    dispatch(setBusy(false))
    return
  }

  await dispatch(initialiseJourneys(userId))

  dispatch(setAppInitialised(true))
  dispatch(setLoggedIn(true))
  dispatch(setBusy(false))

}

export const getStartPage = (organisationSelected, validLoginToken, journeys, selectedJourney) => {

  if (!organisationSelected) return '/access'

  if (!validLoginToken) return '/login'

  if (!journeys?.length) return '/user/account'

  if (selectedJourney) return '/journey/story'

  return '/user/supporting'
}

const getVersionNumber = async () => {
  if(Capacitor.getPlatform() === 'web'){
    return 'Web Version'
  }
  else{
      const info = await App.getInfo()
      return info.version
  }
}

export const getOrganisationSettings = async () => {

  const settings = {}
  settings.textToSpeechEnabled =  JSON.parse(localStorage.getItem('organisation_details'))?.text_to_speech_enabled ?? false

  return settings
}