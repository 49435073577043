import { createStore, applyMiddleware, combineReducers  } from 'redux'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import { appReducer } from "./app";
import userManagementReducer from './user-management/reducer'
import {storyReducer} from './user-journey/story/state/reducer'
import {userJourneyReducer} from './user-journey/state/reducer'
import {goalReducer} from './user-journey/goal/state/reducer'
import {meetingReducer} from './user-journey/meetings/state/reducer'
import {profileReducer} from './user-journey/profile/state/reducer'
import {userActionReducer} from './user-journey/user-actions/state/reducer'
import {multiFeedReducer} from './user-journey/multi-feed'

const rootReducer = combineReducers({
  app: appReducer,
  userManagement: userManagementReducer,
  journey: userJourneyReducer,
  story: storyReducer,
  goals: goalReducer,
  meetings: meetingReducer,
  profile: profileReducer,
  userActions: userActionReducer,
  multiFeed: multiFeedReducer
})

const initialState = (localStorage.getItem('isLoggedIn') === null) ? {} : {userManagement: { isLoggedIn: true }}

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
)

export default store