import React, {useState} from 'react';
import { IonButton, IonItem, IonLabel, IonInput, IonAlert } from '@ionic/react'
import styles from './styles.module.scss'
import { Logo } from '../../components'

export const Login = ({ loginMessage,
                        setLoginMessage,
                        login,
                        resetApp,
                        dictionary,
                      }) => {

  const [username, setUsername] = useState(null)
  const [password, setPassword] = useState(null)
  const [confirmMessage, setConfirmMessage] = useState(null)

  const resetPassword = () => {
    const orgDetails = JSON.parse(localStorage.getItem('organisation_details'))
    const siteUrl = orgDetails.url
    window.open(`${siteUrl}/user/password`,'_blank')
  }

  return (
      <div className={styles.login_box_outer}>
        <div className={styles.login_box}>
          <div className={styles.login_box_inner}>
            <div className={styles.header}>
              <Logo />
              <p>Sign in to continue</p>
            </div>

              <div className={styles.login_container}>
                <div>
                  <IonItem>
                    <IonLabel position="stacked">{dictionary.credentials}</IonLabel>
                    <IonInput value={username} onIonChange={(e) => {
                      setUsername(e.target.value)
                    }}> </IonInput>
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked">{dictionary.password}</IonLabel>
                    <IonInput value={password} type={'password'} onIonChange={(e) => {
                      setPassword(e.target.value)
                    }}> </IonInput>
                  </IonItem>
                </div>

                <IonButton expand="block" color="primary" onClick={() => {login(username, password, dictionary)}}>
                  {dictionary.login}
                </IonButton>

                <div className={styles.options}>
                  <div className={styles.forgotten} onClick={() => { resetPassword() }}>
                    {dictionary.forgotten_password}
                  </div>

                  <div className={styles.forgotten} onClick={() => setConfirmMessage(true)}>
                    {dictionary.change_org}
                  </div>
                </div>

              </div>
            </div>
          </div>

        <IonAlert
            isOpen={!!loginMessage}
            onDidDismiss={() => setLoginMessage(null)}
            header={dictionary.alert}
            message={loginMessage}
            buttons={dictionary.ok}
        />

        <IonAlert
            isOpen={confirmMessage}
            onDidDismiss={() => setConfirmMessage(false)}
            header={dictionary.confirm}
            message={dictionary.change_org_confirm}
            buttons={[
              {
                text: dictionary.no,
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: dictionary.yes,
                handler: () => {
                  resetApp();
                }
              }
            ]}
        />

      </div>
  );
}
