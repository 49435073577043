import {
  IonAvatar,
  IonCard,
  IonCardContent, IonCardHeader, IonCardSubtitle,
  IonIcon,
  useIonRouter,
} from "@ionic/react";
import styles from "./styles.module.scss"
import {findAvatar} from "../../../story/logic"
import {ActionButton} from "../../../../components"
import {
  calendarOutline,
  chatbubbleEllipsesOutline,
  pricetagOutline,
  thumbsUp,
  thumbsUpOutline,
  ellipsisHorizontalOutline,
  readerOutline,
  micOutline,
  stopCircleOutline,
} from "ionicons/icons"
import {MediaBox} from "../../../story/components"
import React, {useCallback, useMemo, useState} from "react"
import {startSpeaking, stopSpeaking} from '../../../../utils/functions/speak'

export const Post = ({ post,
                       avatars,
                       toggleLike,
                       openModal,
                       parent,
                       setPostForOptions,
                       setPostForLikeList,
                       setPostForGoalList,
                       userDetails,
                       fetchMedia,
                       postSaveActions,
                       textToSpeechEnabled,
                       userId,
                       journeys,
                       selectJourney,
                       dictionary,
                       viewingOwnJourney,
                       journeyPermissions,
                     }) => {

  const router = useIonRouter()
  const [speaking, setSpeaking] = useState(false)

  const viewingMultiFeed = useMemo(() => {
    return post?.post_recipient
  }, [post])

  const journey = useMemo(() => {
    return journeys?.find(j => parseInt(j.id) === parseInt(post.profile_id))
  }, [journeys, post])

  const speak = async (text) => {
    if (speaking){
      await stopSpeaking()
      setSpeaking(false)
    }
    else{
      setSpeaking(true)
      await startSpeaking(text)
      setSpeaking(false)
    }
  }

  const showLikes = useCallback((post) => {
    if (post.like_count > 0) {
      setPostForLikeList(post)
    }
  }, [setPostForLikeList])

  const openJourney = useCallback( async () => {
    if (!viewingMultiFeed) return

    await selectJourney(journey, userId)
    router.push('/journey/story')
  }, [selectJourney, router, journey, userId, viewingMultiFeed])

  return (
    <IonCard key={post.id} className={styles.post}>

      {
        post.post_recipient &&
        <IonCardHeader>
          <IonIcon icon={readerOutline} />
          <IonCardSubtitle>
            Posted to <b>{post.post_recipient}'s</b> Story
          </IonCardSubtitle>
        </IonCardHeader>
      }

      <IonCardContent>
        <div className={styles.header}>
          <div className={styles.user} onClick={() => openJourney()}>
            <IonAvatar slot="start">
              <img src={findAvatar(avatars, post?.group_user_id || post?.posted_by_id)} alt="" />
            </IonAvatar>
            <h3>{post.group_user_name || post.posted_by_name}</h3>
          </div>
          <ActionButton icon={ellipsisHorizontalOutline} action={()=>{setPostForOptions(post)}} />
        </div>

        <div className={styles.post_info}>
          <div className={styles.post_date}>
            <p><IonIcon className={styles.date_icon} icon={calendarOutline} />  {post.post_date}</p>
          </div>
          {
            (viewingOwnJourney || parseInt(journeyPermissions?.view_goals) === 1) && parseInt(post.goal_count) > 0 &&
            <div className={styles.post_goal_tag}>
              <p className={styles.link}
                 onClick={() => setPostForGoalList(post)}>{post.goal_count} {parseInt(post.goal_count) === 1 ? dictionary?.single_tagged_goal : dictionary?.multiple_tagged_goal}</p>
              <ActionButton icon={pricetagOutline}
                            action={() => setPostForGoalList(post)} />
            </div>
          }
        </div>

        <div className={styles.post_content}>
          <p onClick={() => speak(post.post_text)} className={`${textToSpeechEnabled ? styles.speech_enabled : ''}`}>
            {post.post_text}
            {post?.post_text && textToSpeechEnabled && <IonIcon className={`${speaking ? styles.speech_started : ''}`} icon={speaking ? stopCircleOutline : micOutline} />}
          </p>
          {post.cover_picture &&
            <MediaBox
              post={post}
              parent={parent}
              onClick={fetchMedia}
            />
          }
        </div>

        <div className={styles.footer}>
          <div className={styles.replies}>
            <ActionButton
              icon={chatbubbleEllipsesOutline}
              action={() => openModal('POST_REPLIES', post)}
            />
            <p>{post.reply_count} {dictionary.replies}</p>
          </div>

          <div className={styles.likes}>
            <p className={`${post?.like_count > 0 ? styles.link : ''}`} onClick={() => showLikes(post)}>{post.like_count} {dictionary.likes}</p>
            <ActionButton icon={+post.like_active === 0 ? thumbsUpOutline : thumbsUp}
                          action={() => toggleLike(parseInt(post.like_active) === 0, post, post.like_id, userDetails?.uid, postSaveActions)} />
          </div>
        </div>

        { post.reply_text &&
          <div className={styles.latest_reply}>
            <IonAvatar className={styles.reply_avatar} slot="start">
              <img src={findAvatar(avatars, post.reply_by_id)} alt=""/>
            </IonAvatar>
            <div className={styles.reply_left} onClick={() => openModal('POST_REPLIES', post)}>
              <p className={styles.reply_name}>{post.reply_name}</p>
              <p>{post.reply_text}</p>
            </div>

          </div>
        }
      </IonCardContent>
    </IonCard>
  )

}