import {connect} from 'react-redux'
import {StoryFilter} from './story-filter'
import {goals, downloadGoals} from '../../../goal'
import {selectedJourney} from '../../../state'
import {setFilter, storyFilter} from '../../state'
import {appDictionary, getOpenModal} from "../../../../app"
import {STORY_FILTER} from '../../../../constants/modals'
import {dismissModal} from "../../../../app";

const mapStateToProps = (state) => ({
  selectedJourney: selectedJourney(state),
  goals: goals(state),
  storyFilter: storyFilter(state),
  isOpen: getOpenModal(state, STORY_FILTER),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadGoals: (journeyId) => dispatch(downloadGoals(journeyId)),
  setFilter: (filter) => dispatch(setFilter(filter)),
  close: () => dispatch(dismissModal(null, null))
})

export const StoryFilterConnected = connect(mapStateToProps, mapDispatchToProps)(StoryFilter)