import {connect} from 'react-redux'
import {App} from './app'
import {initialiseApp} from '../logic'
import {
  appBusy,
  organisationSelected,
  validLoginToken,
  appInitialised,
  isOnline,
  alertMessage,
  toastMessage, appDictionary,
} from "../selectors"
import {selectedJourney, journeys} from '../../user-journey'
import {showAlertMessage, showToastMessage} from "../actions";

const mapStateToProps = (state) => ({
  appBusy: appBusy(state),
  selectedJourney: selectedJourney(state),
  journeys: journeys(state),
  organisationSelected: organisationSelected(state),
  validLoginToken: validLoginToken(state),
  appInitialised: appInitialised(state),
  isOnline: isOnline(state),
  alertMessage: alertMessage(state),
  toastMessage: toastMessage(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  startup: () => dispatch(initialiseApp()),
  closeAlert: () => dispatch(showAlertMessage(null)),
  clearToast: () => dispatch(showToastMessage(null))
})

export const AppConnected = connect(mapStateToProps, mapDispatchToProps)(App)