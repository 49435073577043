import {
  closeModal,
  setBusy,
  setDownloading,
  setNetworkStatus,
  setOpenModal,
  setGroupAction,
  setAlertMessage,
  setToastMessage,
  setTfaCodeRequired, setOrganisationSettings, setOrganisationSelected, setAppLanguage,
} from './action-types'
import { get } from '../utils/api/api'
import {getAccess, TfaCodeRequired, validateTfaCode} from '../utils/api/app'
import {getOrganisationSettings, initialiseApp} from './logic'
import english from "../utils/languages/english.json"
import welsh from "../utils/languages/welsh.json"

export function setBusyIndicator(busy){
  return dispatch => {
    dispatch(setBusy(busy))
  }
}

export function setDownloadingIndicator(busy){
  return dispatch => {
    dispatch(setDownloading(busy))
  }
}

export const updateNetworkStatus = (status) => {
  return async (dispatch) =>  {

    if(status === 'OFFLINE'){
      dispatch(setNetworkStatus(status))
      return
    }

    let test = await get("/user/login_status")
    const testSuccessful = (parseInt(test) === 1 || parseInt(test) === 0)

    testSuccessful ? dispatch(setNetworkStatus('ONLINE')) : dispatch(setNetworkStatus('OFFLINE'))

    return testSuccessful
  }
}

export const openModal = (modal, modalData) => {
  return (dispatch) => {
    dispatch(setOpenModal(modal, modalData))
  }
}

export const updateGroupActionProps = (action, args, uid) => {
  return (dispatch) => {
    dispatch(setGroupAction(action, args, uid))
  }
}

export const dismissModal = () => {
  return (dispatch) => {
    dispatch(closeModal())
  }
}

export const showAlertMessage = (msg) => {
  return (dispatch) => {
    dispatch(setAlertMessage(msg))
  }
}

export const showToastMessage = (msg) => {
  return (dispatch) => {
    dispatch(setToastMessage(msg))
  }
}

export const handleFetchError = (errorMessage) => {
  return async (dispatch) => {

    // An error will occur in the fetch if the user is required to enter a TFA code
    const tfaCodeRequired = await TfaCodeRequired()
    if (tfaCodeRequired) {
      dispatch(setTfaCodeRequired(true))
      return
    }

    dispatch(setToastMessage(errorMessage))
  }
}

export const checkTfaCode = (code) => {
  return async (dispatch) => {

    dispatch(setBusyIndicator(true))

    const codeCorrect = await validateTfaCode(code)
    if (codeCorrect) {
      dispatch(setBusyIndicator(false))
      dispatch(setTfaCodeRequired(false))
      dispatch(initialiseApp())
    }
    else{
      dispatch(setBusyIndicator(false))
      dispatch(showAlertMessage("The code you have entered is not valid"))
    }

  }
}

export const checkOrganisationCode = (accessCode) => {
  return async (dispatch) => {
    let validAccessCode = await getAccess(accessCode, dispatch)

    if(validAccessCode?.message) {
      dispatch(showAlertMessage(validAccessCode?.message))
      return
    }

    if(validAccessCode?.length === 0) {
      dispatch(showAlertMessage('Invalid Access Code'))
      return
    }

    // Set organisation settings
    dispatch(setOrganisationSettings(await getOrganisationSettings()))

    dispatch(setOrganisationSelected(true))
  }
}

export const selectLanguage = (language) => {
  return (dispatch) => {

    if(!language) {
      language = localStorage.getItem('selected_language')
    }

    console.log(language)

    switch(language) {
      case 'english':
        localStorage.setItem('selected_language', 'english')
        dispatch(setAppLanguage({selectedLanguage: 'english', dictionary: english}))
        break

      case 'welsh':
        localStorage.setItem('selected_language', 'welsh')
        dispatch(setAppLanguage({selectedLanguage: 'welsh', dictionary: welsh}))
        break

      default:
        localStorage.setItem('selected_language', 'english')
        dispatch(setAppLanguage({selectedLanguage: 'english', dictionary: english}))
    }
  }
}


