import {
  IonItem,
  IonList, IonSkeletonText
} from '@ionic/react';
import React from "react";

export const SkeletonText= ({ isVisible, styles }) => {
  if (!isVisible) return null

  return (
      <IonList>
        <IonItem>
          <div className={styles.date} style={{border: 'none'}}>
            <IonSkeletonText animated style={{ width : '80%' }} />
          </div>

          <div className={styles.title} style={{display: "inherit"}}>
            <IonSkeletonText animated style={{ width : '100%' }} />
          </div>
        </IonItem>

        <IonItem>
          <div className={styles.date} style={{border: 'none'}}>
            <IonSkeletonText animated style={{ width : '80%' }} />
          </div>

          <div className={styles.title} style={{display: "inherit"}}>
            <IonSkeletonText animated style={{ width : '100%' }} />
          </div>
        </IonItem>
      </IonList>
  )
}