export const userDetails = (state) =>
    state?.userManagement?.userDetails

export const userId = (state) =>
    state?.userManagement?.userDetails?.uid

export const userDisplayName = (state) =>
    state?.userManagement.userDetails?.display_name

export const isGroupAccount = (state) =>
  parseInt(state?.userManagement?.userDetails?.shared_device_login) === 1