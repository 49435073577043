import React, {useMemo} from "react"
import {IonIcon} from "@ionic/react"
import {RatingDial} from "../rating-dial"
import {ActionButton} from "../../../../components"
import {checkmarkCircleOutline, ellipsisHorizontalOutline} from "ionicons/icons"
import styles from './styles.module.scss'

export const GoalItem = ({
                           goal,
                           openModal,
                           setSelectedGoal,
                           userId,
                           goalActions,
                           rowDisplay = true,
                         }) => {

  const addedBy = useMemo(() => {
    return parseInt(goal?.created_by_id) === parseInt(userId) ? 'You' : goal.created_by
  }, [goal, userId])

  return (
    <div className={styles.goal_container}>

      <div className={`${rowDisplay ? styles.content_row : styles.content_column}`}>
        <div className={styles.rating} onClick={() => openModal && openModal('GOAL_RATINGS', {...goal, ...{actions: goalActions}})}>
          <RatingDial rating={goal.rating ?? 0} />
        </div>
        <div className={styles.details}>
          <div className={styles.goal_item_title}>
            <h4>{goal.title}</h4>
            {
              parseInt(goal.complete) === 1 ? <IonIcon icon={checkmarkCircleOutline} /> : null
            }
          </div>
          <p className={styles.goal_text}>{goal.description}</p>
        </div>
      </div>

      <div className={styles.footer}>
        <div>Added by <b>{addedBy}</b></div>
        {
          setSelectedGoal &&
          <ActionButton icon={ellipsisHorizontalOutline} action={() => setSelectedGoal(goal)} />
        }
      </div>
    </div>
  )
}