import {connect} from 'react-redux'
import {TfaAccessCode} from "./TfaAccessCode"
import {appDictionary, tfaCodeRequired} from '../../../selectors'
import {checkTfaCode} from '../../../actions'
import {doLogout} from "../../../../user-management/actions"

const mapStateToProps = (state) => ({
  isOpen: tfaCodeRequired(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  checkTfaCode: (code) => dispatch(checkTfaCode(code)),
  logout: () => dispatch(doLogout()),
})

export const TfaAccessCodeConnected = connect(mapStateToProps, mapDispatchToProps)(TfaAccessCode)