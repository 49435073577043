import {
  DOWNLOADING_JOURNEYS,
  SET_JOURNEYS,
  SET_SELECTED_JOURNEY,
  RESET_STATE,
  SET_AVATARS,
  SET_JOURNEY_PERMISSIONS,
} from './action-types'

import { mergeAvatars } from './logic'

const initialState = {journeysDownloaded: false}

export function userJourneyReducer(state = initialState, action) {
  switch (action.type) {

    case DOWNLOADING_JOURNEYS:
      return { ...state,
        downloadingJourneys: action.payload
      }

    case SET_JOURNEYS:
      return { ...state,
        journeys: action.payload
      }

    case SET_SELECTED_JOURNEY:
      return { ...state,
        selectedJourney: action.payload,
      }

    case SET_JOURNEY_PERMISSIONS:
      return { ...state,
        journeyPermissions: action.payload
      }

    case RESET_STATE:
      return {
        ...initialState,
      }

    case SET_AVATARS:
      return { ...state,
        avatars: mergeAvatars(state?.avatars ?? [], action.payload)
      }

    default:
      return state
  }
}