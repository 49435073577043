import {
  RESET_STATE,
  SET_DOWNLOADING_REPLIES, SET_GOAL_LIST, SET_LIKE_LIST,
  SET_POSTS, SET_REPLIES,
  SET_REPORTED_STATUS,
  SET_SELECT_POST_ID,
  SET_SELECTED_MEDIA,
  SET_STORY_FILTER,
  UPDATE_POST,
  UPDATE_REPLY
} from "./action-types"

export const setSelectedMedia = (media) => ({
  type: SET_SELECTED_MEDIA,
  payload: media
})

export const dispatchPosts = (posts, concat) => ({
  type: SET_POSTS,
  payload: { "concat": concat, "posts": posts }
});

export const setSelectedPostId = (postId) => ({
  type: SET_SELECT_POST_ID,
  payload: postId
})

export const updatePost = (post) => ({
  type: UPDATE_POST,
  payload: post
})

export const setReportedStatus = (reportedStatus) => ({
  type: SET_REPORTED_STATUS,
  payload: reportedStatus
})

export const setStoryFilter = (filter) => ({
  type: SET_STORY_FILTER,
  payload: filter
})

export const setDownloadingReplies = (downloading) => ({
  type: SET_DOWNLOADING_REPLIES,
  payload: downloading
})

export const setReplies = (replies) => ({
  type: SET_REPLIES,
  payload: replies
})

export const updateReply = (reply) => ({
  type: UPDATE_REPLY,
  payload: reply
})

export const setLikeList = (list) => ({
  type: SET_LIKE_LIST,
  payload: list
})

export const setGoalList = (goals) => ({
  type: SET_GOAL_LIST,
  payload: goals
})

export const resetState = () => ({
  type: RESET_STATE
})