import {connect } from 'react-redux'
import {Goal} from './goal'
import {
  downloadingGoals,
  showCompleteGoals,
  visibleGoals,
  setShowCompleteActions,
  downloadGoals,
  deleteGoalAndRefresh
} from './state'
import {downloadMeetings} from "../meetings"
import {appDictionary, openModal} from "../../app"
import {usersJourneyPermissions, selectedJourney} from "../state"
import {userId} from "../../user-management"
import {getActions, userActions} from "../user-actions"

const mapStateToProps = (state) => ({
  selectedJourney: selectedJourney(state),
  goals: visibleGoals(state),
  userId: userId(state),
  downloadingGoals: downloadingGoals(state),
  meetings: state.journey.meetings,
  goalActionsModalVisible: state.journey.goalActionsModalVisible,
  showComplete: showCompleteGoals(state),
  journeyPermissions: usersJourneyPermissions(state),
  actions: userActions(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadGoals: (profileId) => dispatch(downloadGoals(profileId)),
  downloadMeetings: (profileId) => dispatch(downloadMeetings(profileId)),
  downloadActions: (profileId) => dispatch(getActions(profileId)),
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData)),
  toggleCompletedGoals: (showComplete) => dispatch(setShowCompleteActions(showComplete)),
  deleteGoal: (goal) => dispatch(deleteGoalAndRefresh(goal)),
})

export const GoalConnected = connect(mapStateToProps, mapDispatchToProps)(Goal)