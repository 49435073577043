import {
  IonItem,
  IonList, IonSkeletonText
} from '@ionic/react';
import React from "react";

export const SkeletonTextReplies = ({ isVisible }) => {
  if (!isVisible) return null

  return (
      <IonList>
        <IonItem>
          <div style={{border: 'none', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <IonSkeletonText animated style={{ width : '30px', height: '30px', marginRight: '10px', borderRadius: '50px', marginTop: '10px', marginBottom: '10px' }} />

            <div style={{width: '70%'}}>
              <IonSkeletonText animated style={{ width : '40%' }} />
              <IonSkeletonText animated style={{ width : '100%' }} />
            </div>
          </div>
        </IonItem>

        <IonItem>
          <div style={{border: 'none', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <IonSkeletonText animated style={{ width : '30px', height: '30px', marginRight: '10px', borderRadius: '50px', marginTop: '10px', marginBottom: '10px' }} />

            <div style={{width: '70%'}}>
              <IonSkeletonText animated style={{ width : '40%' }} />
              <IonSkeletonText animated style={{ width : '100%' }} />
            </div>
          </div>
        </IonItem>
      </IonList>
  )
}