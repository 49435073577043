import {
  SET_MEETINGS,
  SET_MEETING_IMAGES,
  SET_MEETING_LINKS,
  RESET_STATE,
} from './action-types'

export const setMeetings = (meetings) => ({
  type: SET_MEETINGS,
  payload: meetings
});

export const setMeetingImages = (meetingId, images) => ({
  type: SET_MEETING_IMAGES,
  payload: {
    meetingId,
    images
  }
})

export const setMeetingLinks = (meetingId, links) => ({
  type: SET_MEETING_LINKS,
  payload: {
    meetingId,
    links
  }
})

export const resetState = () => ({
  type: RESET_STATE
})

