import {IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding} from "@ionic/react";
import styles from "./styles.module.scss";
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  ellipsisHorizontalOutline,
  thumbsDownOutline,
  thumbsUpOutline
} from "ionicons/icons";
import {ACTION_EDIT} from "../../../../constants/modals";
import {formatLongDate} from "../../../../utils/helpers/date";
import {ActionButton} from "../../../../components";
import React, {useMemo} from "react";
import cloneDeep from 'lodash/cloneDeep'

export const ActionItem = ( {
                              action,
                              openModal,
                              enabled,
                              setSelectedAction,
                              markAsComplete,
                              slider,
                              userId,
                            }) => {

  const addedBy = useMemo(() => {
    return parseInt(action?.created_by_id) === parseInt(userId) ? 'You' : action.created_by
  }, [action.created_by_id, userId, action.created_by])

  return (
    <IonItemSliding
      disabled={!enabled}>
      <IonItem>
        <div className={styles.action_item}>
          <div className={styles.complete_indicator}>
            {
              action.complete
                ?
                <IonIcon icon={checkmarkCircleOutline} className={styles.complete} />
                :
                <IonIcon icon={alertCircleOutline} className={styles.incomplete} />
            }
          </div>

          <div className={styles.action_summary} onClick={() => { openModal(ACTION_EDIT, cloneDeep(action)) }}>
            <h5>{unescape(action.action)}</h5>
            <div className={styles.date_wrapper}>
              <span className={styles.date}>{formatLongDate(action.due_date)}</span>
            </div>
            <div className={styles.date_wrapper}>
              <span className={styles.date}>Added by <b>{addedBy}</b></span>
            </div>
          </div>

          <div className={styles.options}>
            <ActionButton icon={ellipsisHorizontalOutline} action={() => setSelectedAction(action)} />
          </div>

        </div>
      </IonItem>
      <IonItemOptions side="start">
        <IonItemOption className={action.complete ? styles.option_complete : styles.option_incomplete} onClick={() => {markAsComplete(action.id, action.complete !== true); slider.current.closeSlidingItems() }}>
          {
            action.complete
              ?
              <IonIcon icon={thumbsDownOutline} />
              :
              <IonIcon icon={thumbsUpOutline} />
          }
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  )
}