import {
  IonMenu,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonLabel,
  IonThumbnail
} from "@ionic/react";
import React, {useMemo} from "react";
import styles from './styles.module.scss'
import { useHistory } from "react-router-dom";

export const Menu = ({ userDetails,
                       selectJourney,
                       ownJourney,
                       supportedJourneys,
                       avatars,
                       dictionary,
                     }) => {

  const history = useHistory()

  const avatar = useMemo(() => {
    return avatars?.find(a => parseInt(a?.uid) === parseInt(userDetails?.uid))?.user_picture
  }, [avatars, userDetails])

  return (
    <IonMenu side="start" contentId="main">
      <IonContent>
        <IonList>
          <IonMenuToggle auto-hide="false">
            <IonItem button routerLink={'/user/account'} routerDirection="none" className={styles.user_item}
                     lines="none">
              <IonThumbnail slot="start">
                <img src={avatar} alt="" />
              </IonThumbnail>
              <IonLabel>
                <span className={styles.label_display_name}>{userDetails && userDetails.display_name}</span>
                <br/>
                <span className={styles.label_email} />
              </IonLabel>
            </IonItem>
          </IonMenuToggle>
          {
            ownJourney &&
            <IonMenuToggle auto-hide="false">
              <IonItem button detail onClick={() => { selectJourney(ownJourney, userDetails.uid); history.push("/journey/story") }} routerDirection="none">
                <IonLabel>{dictionary.my_story}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {supportedJourneys?.length &&
            <IonMenuToggle auto-hide="false">
              <IonItem button detail routerLink={"/user/supporting"} routerDirection="none">
                <IonLabel>
                  {dictionary.supporting}
                  <br />
                  <span className={styles.help_text}>{dictionary.supporting_info}</span>
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }

          {supportedJourneys?.length > 1 &&
            <IonMenuToggle auto-hide="false">
              <IonItem button detail routerLink={"/multi-feed"} routerDirection="none">
                <IonLabel>
                  {dictionary.multi_feed}
                  <br />
                  <span className={styles.help_text}>{dictionary.multi_feed_info}</span>
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }

          <IonMenuToggle auto-hide="false">
            <IonItem button detail routerLink={"/app/about"} routerDirection="none">
              <IonLabel>
                {dictionary.about}
                <br />
                <span className={styles.help_text}>{dictionary.click_for_info}</span>
              </IonLabel>
            </IonItem>
          </IonMenuToggle>

        </IonList>
      </IonContent>
    </IonMenu>
  )
}