import { get, post, patch, validToken } from './api'

export const isLoggedIn = () => {

  return get("/user/login_status").then(
    (response) => {
      if(response != null){
        return (parseInt(response) === 1)
      }
    }
  )
}

export const tokenActive = async (dispatch) => {

  const currentToken = localStorage.getItem('jwt')

  if(!currentToken){
    return false
  }

  let token = await validToken(dispatch)

  return !!token
}

export const login = async (login, password, dispatch) => {
  let response = {}

  // If the login looks like an email address then try the email login first
  if (login.includes('@') && login.includes('.')) {
    response = await post("/user/email-login",  { "mail" : login, "pass" : password }, null, dispatch)

    if(response?.current_user){
      localStorage.setItem('isLoggedIn', 'true')
      localStorage.setItem('loginDetails', JSON.stringify(response))
      response.jwt_token ? localStorage.setItem('jwt', response.jwt_token) :  localStorage.removeItem('jwt')

      return {
        success: true,
        data: response
      }
    }
  }

  // If the email login failed then fall back to the standard username login
  response = await post("/user/login",  { "name" : login, "pass" : password }, null, dispatch)

  if(response?.current_user){
    localStorage.setItem('isLoggedIn', 'true')
    localStorage.setItem('loginDetails', JSON.stringify(response))
    localStorage.setItem('jwt', response.access_token)
  }

  return {
    success: Boolean(response.current_user),
    data: response
  }
}

export const logout = async () => {

  const login_details = localStorage.getItem('loginDetails')
  if (!login_details) {
    return false
  }

  const logoutToken = JSON.parse(login_details)?.logout_token
  if (!logoutToken) {
    return false
  }

  const response = await post("/user/logout", null, `token=${logoutToken}`)

  console.log({response})

  localStorage.removeItem('jwt')
  localStorage.removeItem('loginDetails')
  localStorage.removeItem('isLoggedIn')
  localStorage.removeItem('journeys')

  return true
}

export const downloadDetails = async (dispatch) => {
  let userDetailsArray = await get('/viewsjson/user', null, dispatch)

  if (!userDetailsArray){
    // alert("Failed to download user details")
    return null
  }

  if (userDetailsArray.message){
    alert(userDetailsArray.message)
    return null
  }

  if (!userDetailsArray?.length){
    return null
  }

  let userDetails = userDetailsArray[0]

  const isAdmin = JSON.parse(userDetails.admin_role)
  userDetails.isAdmin = isAdmin?.length && parseInt(isAdmin[0].is_admin) === 1 ? 1 : 0

  return userDetails
}

export const uploadDetails = async (userDetails) => {
  const body = {
    "field_alerts_meeting": [{'value': userDetails.alerts_meeting}]
  }
  const updated = await patch(`/user/${userDetails.uid}`, body)

  if (!updated) return false

  if(updated?.message){
    // alert(updated.message)
    return false
  }

  return true
}

export const downloadPermissions = (profileId) => {
  return get('/viewsjson/journey-list/circle-of-support', `profile_id=${profileId}`).then(
    (perms) => {
      return perms
    }
  )
}

export const uploadProfilePicture = async (base64ProfilePicture, dispatch) => {

  const upload = {
    "profile_pic": base64ProfilePicture
  }
  return await post('/profile_pic_upload', upload, null, dispatch)
}