// import { isOffline } from '../../constants/constants'
// import {updateNetworkStatus} from "../../app/actions";
// import {setNetworkStatus} from "../../app/action-types";
// const baseUrl = "https://www.here2there.test"
// const baseHTMLQueryString = "_format=html"

import {handleFetchError} from "../../app";

const baseUrl = "https://h2tadmin.com"
const baseJSONQueryString = "_format=json"

const getToken = () => {
  return localStorage.getItem('jwt')
}

const getUrl = (path, queryString) => {
  const siteUrl = JSON.parse(localStorage.getItem('organisation_details'))?.url ?? baseUrl

  if(queryString) {
    return `${siteUrl}${path}?${queryString}&${baseJSONQueryString}`
  }
  else{
    return `${siteUrl}${path}?${baseJSONQueryString}`
  }
}

const doAPICall = async (method, path, body, queryString) => {
  const token = getToken()
  const url = getUrl(path, queryString)

  const abort = new AbortController()
  const abortId = setTimeout(() => abort.abort(), 8000)

  let response = await fetch(url, {
    headers: {
      "Content-type": "application/json",
      "Accept": "application/json",
      "Authorization": (token) ? `Bearer ${token}` : null
    },
    method: method,
    body: body ? JSON.stringify(body) : null,
    signal: abort.signal,
  })

  clearTimeout(abortId)

  // Success but no content returned
  if (response.status === 204) {
    return {}
  }

  let json_response = await response.json()

  if(parseInt(json_response.error) === 1){
    alert('Post Error: ' + json_response.message)
    return null
  }

  return json_response
}

export const validToken = async (dispatch) => {
  const token = getToken()

  if(!token){
    return false
  }

  const url = JSON.parse(localStorage.getItem('organisation_details'))?.url ?? baseUrl
  let response = {}
  try {
    response = await fetch(`${url}/jwt/token?${baseJSONQueryString}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      },
      method: "GET"
    })
  } catch (e) {
    dispatch(handleFetchError(e.message))
    return false
  }

  if(response.status === 403){
    return false
  }

  let json_response = await response.json()

  localStorage.setItem('jwt', json_response.token)

  return true
}

export const get = async (path, queryString, dispatch) => {
  try{
    return await doAPICall('GET', path, null, queryString)
  }
  catch (e) {
    dispatch && dispatch(handleFetchError(e.message))
    return null
  }
}

export const post = async (path, body, queryString, dispatch) => {
  try{
    return doAPICall('POST', path, body, queryString)
  }
  catch (e) {
    dispatch && dispatch(handleFetchError(e.message))
    return null
  }
}

export const patch = async (path, body, queryString, dispatch) => {
  try{
    return doAPICall('PATCH', path, body, queryString)
  }
  catch (e) {
    dispatch && dispatch(handleFetchError(e.message))
    return null
  }
}

export const deleteById = async (path, id, dispatch) => {
  try{
    return doAPICall('DELETE', `${path}${id}`)
  }
  catch (e) {
    dispatch && dispatch(handleFetchError(e.message))
    return null
  }
}

export const uploadFile = async (path, file, fileName) => {
  const token = getToken()
  const url = getUrl(path)

  const abort = new AbortController()
  const abortId = setTimeout(() => abort.abort(), 8000000)

  let response = await fetch(url, {
    headers: {
      "Content-type": "application/octet-stream",
      "Content-disposition": `file; filename="${fileName}"`,
      "Authorization": `Bearer ${token}`
    },
    method: 'POST',
    body: file,
    signal: abort.signal
  })

  clearTimeout(abortId)

  if(response.status < 200 || response.status > 299){
    response.message ? alert(response.message) : alert('Upload failed')
    return null
  }

  let json_response = await response.json()

  if(parseInt(json_response.error) === 1){
    alert('Error uploading file: ' + json_response.message)
    return null
  }

  return json_response
}