import {connect} from 'react-redux'
import {appDictionary, getOpenModal, getOpenModalData, dismissModal} from "../../../../app"
import {MeetingDetail} from "./meeting-details";
import {MEETING_DETAILS} from "../../../../constants/modals";

const mapStateToProps = (state) => ({
  isOpen: getOpenModal(state, MEETING_DETAILS),
  meeting: getOpenModalData(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(dismissModal()),
})

export const MeetingDetailConnected = connect(mapStateToProps, mapDispatchToProps)(MeetingDetail)