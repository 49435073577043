import styles from "./styles.module.scss";
import {Logo, LanguageSelect} from "../../../../components";
import {IonButton, IonInput, IonItem} from "@ionic/react"
import React, {useState} from "react";

export const AccessCodeTemplate = ({
                                     accessText, buttonText,
                                     accessCodeText, accessAction,
                                     selectedLanguage, selectLanguage,
                                     dictionary}) => {

  const [code, setCode] = useState(null)

  return (
    <div className={styles.access_box_outer}>

      <div className={styles.header}>
        <Logo />
      </div>

      <div className={styles.access_container}>

        <p>{accessText}</p>
        <IonItem className={styles.access_code}>
          <IonInput placeholder={accessCodeText} type="tel" inputMode='numeric' value={code} onIonChange={(e) => {
            setCode(e.target.value)
          }}> </IonInput>
        </IonItem>

        <IonButton expand="block" color="primary" onClick={() => {accessAction(code)}}>
          {buttonText}
        </IonButton>

        {
          selectedLanguage && selectLanguage &&
          <div>
            <LanguageSelect
              selectedLanguage={selectedLanguage}
              selectLanguage={selectLanguage}
              dictionary={dictionary} />
          </div>
        }

      </div>

    </div>
  )
}