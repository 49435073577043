import React, {useEffect, useState, useMemo} from "react"
import {ConfirmationBox, DefaultTemplate, ImageGallery} from '../../components'
import {SkeletonText} from './components'
import {IonAvatar, IonButton, IonItem, IonLabel, IonList, IonListHeader, IonCard, IonCardContent} from "@ionic/react"
import styles from './styles.module.scss'
import {Capacitor} from "@capacitor/core"
import {uploadProfileVideo_Capacitor, uploadProfileVideo_Native, userCanEditProfile} from './logic'

export const Profile = ({
                          profile,
                          downloadingProfile,
                          startUp,
                          saveProfileVideo,
                          removeProfileVideo,
                          selectedJourney,
                          supporters,
                          avatar,
                          router,
                          setBusyIndicator,
                          showAlertMessage,
                          user,
                          dictionary,
                        }) => {

  const [profileVideo, setProfileVideo] = useState()
  const [removingVideo, setRemovingVideo] = useState(false)

  const useNativeFileUpload = Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'web'

  const uploadVideo_Native = async (vid) => {
    await uploadProfileVideo_Native(selectedJourney?.id, vid, saveProfileVideo, dictionary)
  }

  const uploadVideo_Capacitor = async () => {
   await uploadProfileVideo_Capacitor(selectedJourney?.id, saveProfileVideo, setBusyIndicator, showAlertMessage, dictionary)
  }

  const canEditProfile = useMemo(() => {
    return userCanEditProfile(selectedJourney, user)
  }, [selectedJourney, user])

  useEffect(() => {
    startUp(selectedJourney?.id)
  }, [selectedJourney, startUp])

  return (
    <DefaultTemplate pageTitle={dictionary.profile}
                     username={selectedJourney?.display_name}
                     refreshFunction={() => startUp(selectedJourney?.id)}
                     dictionary={dictionary}>

      <SkeletonText isVisible={downloadingProfile} />

      {
        !downloadingProfile &&
        <>

          <div className={styles.avatar_background} />
          <div className={styles.avatar_container}>
            {
              avatar &&
              <IonAvatar className={styles.avatar}>
                <img src={avatar} className={styles.avatar_img} alt='' />
              </IonAvatar>
            }

            {
              profile?.video &&
              <IonButton className={styles.profile_video} onClick={() =>
                setProfileVideo([{
                  'type':'video',
                  'image': profile?.thumbnail,
                  'video': profile?.video}])}>{dictionary.profile_video}</IonButton>
            }

            {
              canEditProfile &&
              <div className={styles.video_buttons}>
                {
                  useNativeFileUpload
                    ?
                    <IonButton outline={true} size='small'>
                      <label htmlFor="file-upload">
                        <input onChange={uploadVideo_Native} type="file" id="file-upload" accept="video/*"/>
                        <IonLabel>{dictionary.upload_video}</IonLabel>
                      </label>
                    </IonButton>
                    :
                    <IonButton outline={true} size='small' onClick={uploadVideo_Capacitor}>
                      <IonLabel>{dictionary.upload_video}</IonLabel>
                    </IonButton>
                }

                {
                  profile?.video &&
                  <IonButton outline={true} size='small' onClick={() => setRemovingVideo(true)}>
                    {/*<IonIcon icon={trashOutline}/>*/}
                    <IonLabel>{dictionary.remove_video}</IonLabel>
                  </IonButton>
                }
              </div>
            }

          </div>
          <IonCard className={styles.avatar_card}>
            <IonCardContent className={styles.avatar_content}>
              <div className={styles.questions} dangerouslySetInnerHTML={{__html: profile?.profile_content}} />
            </IonCardContent>
          </IonCard>

          <IonCard className={styles.avatar_card}>
            <IonList>
              <IonListHeader>{dictionary.support_circle}</IonListHeader>
              {
                supporters?.map((supporter, index) => {
                  return (
                    <IonItem key={index}>
                      <IonAvatar slot="start">
                        <img src={supporter.user_picture} alt='' />
                      </IonAvatar>
                      <IonLabel>
                        {supporter.field_last_name}
                        <p color="secondary">{supporter.field_user_type}</p>
                      </IonLabel>
                    </IonItem>
                  )
                })
              }
            </IonList>
          </IonCard>
        </>
      }

      <ImageGallery
        selectedMedia={profileVideo}
        clearSelectedMedia={setProfileVideo}
        parent={router}
        title={dictionary.profile_video} />

      <ConfirmationBox
        isOpen={Boolean(removingVideo)}
        message={dictionary.remove_profile_video}
        handler={() => removeProfileVideo(selectedJourney?.id, dictionary)}
        onDismiss={() => setRemovingVideo(false)}
      />

    </DefaultTemplate>
  );
}