import {Camera, CameraResultType, CameraSource} from '@capacitor/camera'
import VideoSnapshot from 'video-snapshot'
import {FilePicker} from "@capawesome/capacitor-file-picker";
import {convertBase64ToBlob} from "../helpers/file";

export const takePicture = async () => {

  // alert(Capacitor.isPluginAvailable('Camera'))

  //CameraResultType.Base64
    //CameraResultType.Uri
    //CameraResultType.dataUrl

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    // const imageUrl = image.webPath;

    // Can be set to the src of an image now
    // imageElement.src = imageUrl;

    // alert(JSON.stringify(image))
    // alert(image.format)

    return await Camera.getPhoto({
      quality: 25,
      allowEditing: false,
      saveToGallery: true,
      source: CameraSource.Prompt,
      resultType: CameraResultType.Base64
    })
      .catch(e => {
        console.log({e})
        // TODO log error
      })
}

export const getVideoWithPreview = async () => {

  const { files } = await FilePicker.pickVideos({
    types: ['video/mp4'],
    multiple: false,
    readData: true
  })

  if (!files?.length){
    return null
  }

  const selectedVideo = files[0]

  if (!videoIsValid(selectedVideo)) {
    return null
  }

  let blob = {}
  try{
    blob = convertBase64ToBlob(selectedVideo.data, 'video/mp4')
  }
  catch (e) {
    return {video: null, image: null, error: `An error occurred converting the selected video: ${e.message}`}
  }

  let videoPreview = []
  try{
    videoPreview = await getVideoPreview(blob)
  }
  catch (e) {
    return {video: null, image: null, error: `An error occurred generating the preview for the selected video: ${e.message}`}
  }

  return {video: blob, image: videoPreview}
}

export const getVideoPreview = async (vid) => {
  try{
    const snapShot = new VideoSnapshot(vid)
    return await snapShot.takeSnapshot()
  }
  catch (e) {
    alert(e?.message)
  }
}

export const videoIsValid = (video) => {

  if (video.duration > 15) {
    alert('The maximum video length you can upload is 15 seconds')
    return false
  }

  return true
}