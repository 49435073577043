import {connect} from 'react-redux'
import {Feed} from './feed'
import {avatars} from "../../state"
import {appDictionary, openModal} from "../../../app"
import {userId} from "../../../user-management"
import {selectMedia, selectedMedia, deletePost} from "../../story"

const mapStateToProps = (state) => ({
  avatars: avatars(state),
  userId: userId(state),
  selectedMedia: selectedMedia(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData)),
  clearSelectedMedia: () => dispatch(selectMedia(null)),
  deletePost: async (post, postSaveActions) => {
    await dispatch(deletePost(post))
    if (postSaveActions) {
      postSaveActions(post)
    }
  },
})

export const FeedConnected = connect(mapStateToProps, mapDispatchToProps)(Feed)