import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({path, component: Component, authed, router}) => {

  return (
    <Route path={path}
           render={(props) =>
             authed
               ?
               <Component {...props} router={router} />
               :
               <Redirect from={path} to='/login' exact />
           }
    />
  )
}