import React from "react";
import {ModalTemplate} from "../"
import {siteUrl} from "../../app/settings"
import styles from './styles.module.scss'


import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar, Zoom, EffectCreative } from 'swiper';

import 'swiper/swiper.min.css'
import '@ionic/react/css/ionic-swiper.css'
// import 'swiper/modules/pagination/pagination.min.css'
// import 'swiper/modules/scrollbar/scrollbar.min.css'
// import 'swiper/modules/zoom/zoom.min.css'

export const ImageGallery = ({
                               selectedMedia,
                               clearSelectedMedia,
                               parent,
                               title = 'Gallery',
                             }) => {

  const getImageUrl = (uri) => {
    if (uri.includes('base64')) return uri
    if (uri.includes('/sites/')) return siteUrl() + uri

    return siteUrl() + uri
  }

  return (
    <ModalTemplate isOpen={Boolean(selectedMedia)}
                   title={title}
                   closeMethod={()=>{clearSelectedMedia()}}
                   parent={parent}>

      <div className={styles.image_gallery_container}>
        <div className={styles.image_gallery_swiper}>
          <Swiper modules={[Pagination, Scrollbar, Zoom, EffectCreative]}
                  pagination={{clickable: true}}
                  scrollbar={true}
                  zoom={true}
          >
            {
              selectedMedia?.map((media, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="swiper-zoom-container">
                      {
                        media.type === 'video'
                        ?
                          <video poster={getImageUrl(media.image)} controls>
                            <source
                              src={getImageUrl(media.video)}
                              type='video/mp4' />
                          </video>
                        :
                          <img src={getImageUrl(media.image)} alt="???" />
                      }
                    </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </div>
      </div>

    </ModalTemplate>
  )
}