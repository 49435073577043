import React, {useCallback, useEffect} from "react"
import {ModalTemplate,} from "../../../../../components"
import {IonItem, IonLabel, IonList, IonIcon} from "@ionic/react"
import {
  checkmarkCircleOutline, closeCircleOutline
} from "ionicons/icons"
import styles from './styles.module.scss'

export const GoalList = ({
                           parent,
                           post,
                           downloadGoalList,
                           goalList,
                           clearGoalList,
                           dictionary,
                           close,
                         }) => {

  useEffect(() => {
    if (post) {
      downloadGoalList(post.id)
    }
  }, [post, downloadGoalList])

  const closeMe = useCallback(() => {
    clearGoalList()
    close()
  }, [clearGoalList, close])

  return (
    <ModalTemplate isOpen={Boolean(post)}
                   title={dictionary.goals}
                   closeMethod={closeMe}
                   parent={parent}>

      <IonList lines='inset'>
        {goalList?.map((goal, index) => {
          return (
            <IonItem key={index}>
              <IonLabel>
                <div className={styles.goal_item}>
                  {goal.goal}

                  {parseInt(goal.complete) === 1
                    ? <IonIcon icon={checkmarkCircleOutline} className={styles.complete} />
                    : <IonIcon icon={closeCircleOutline} className={styles.incomplete} />
                  }
                </div>
              </IonLabel>
            </IonItem>
          )
        })}
      </IonList>

    </ModalTemplate>
  )
}
