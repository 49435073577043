import {createSelector} from 'reselect'

const allPosts = (state) =>
  state?.story?.posts

export const hasMorePosts = (state) =>
  state?.story?.hasMorePosts

export const storyFilter = (state) =>
  state?.story?.storyFilter

export const downloadingReplies = (state) =>
  state?.story?.downloadingReplies

export const replies = (state) =>
  state?.story?.replies

export const reportedStatus = (state) =>
  state?.story?.reportedStatus

export const selectedMedia = (state) =>
  state?.story?.selectedMedia

export const storyPosts = createSelector(
  storyFilter,
  allPosts,
  (filter, posts) => {
    if(!filter) {
      return posts
    }

    if (!filter.goals || filter.goals.length === 0) {
      return []
    }

    return posts.filter((post) => {
      return post.goal_id_list
        ?.split(",")
        ?.some(g => filter.goals.includes((g)))
    })
  }
)

const selectedPostId = (state) =>
  state?.story?.selectedPostId

export const selectedPost = createSelector(
  allPosts,
  selectedPostId,
  (posts, selectedPostId) => {
    if (selectedPostId === 0){
      return {
            images: [],
            videos: [],
            post_active: true
      }
    }
    else{
      return (!posts || !selectedPostId) ? null : posts.find(p => parseInt(p.id) === parseInt(selectedPostId))
    }
  }
)

export const likeList = (state) =>
  state?.story?.likeList

export const goalList = (state) =>
  state?.story?.goalList

