import {connect} from 'react-redux'
import {openModal, getOpenModal, getOpenModalData, appDictionary} from "../../../../app"
import {dismissModal} from "../../../../app";
import {GoalProgress} from "./goal-progress"
import {GOAL_RATINGS} from '../../../../constants/modals'
import {meetings, showMeetingDetails} from '../../../meetings'
import {userId} from "../../../../user-management"

const mapStateToProps = (state) => ({
  isOpen: getOpenModal(state, GOAL_RATINGS),
  goal: getOpenModalData(state),
  meetings: meetings(state),
  userId: userId(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(dismissModal()),
  openMeetingDetails: async (meeting) => dispatch(showMeetingDetails(meeting)),
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData)),
})

export const GoalProgressConnected = connect(mapStateToProps, mapDispatchToProps)(GoalProgress)