import {
  IonContent, IonHeader, IonPage, IonToolbar, IonButtons,
  IonMenuButton, IonGrid, IonRow, IonCol, IonRefresher,
  IonRefresherContent, IonFabButton, IonIcon, IonFab
} from "@ionic/react";
import React, {useCallback, useEffect, useState} from "react"
import {useHistory} from 'react-router-dom'
import{ToolbarActionButton} from '../../toolbar-action-button'
import styles from './styles.module.scss'
import './page-styles.css'
import classnames from 'classnames'
import {add} from "ionicons/icons"
import {App} from "@capacitor/app"
import {ConfirmationBox} from "../../message-boxes";

export const DefaultTemplate = ({children,
                                  pageTitle,
                                  username,
                                  actionButtonIcon,
                                  actionButtonOnClick,
                                  refreshFunction,
                                  fabButtonAction,
                                  persistFabButton,
                                  dictionary,
                                }) => {

  const history = useHistory()
  const [appExitMessage, setAppExitMessage] = useState(false)

  const backHandler = useCallback((ev) => {
    ev.detail.register(10, () => {
      ev.preventDefault()

      if(history?.location?.pathname === '/journey/story'){
        setAppExitMessage(true)
      }
      else{
        history.goBack()
      }
    });
  }, [history])

  useEffect(() =>{
    document.addEventListener('ionBackButton', backHandler)
  }, [backHandler])

  const doRefresh = (event) => {
    refreshFunction().then(
      () =>{
        event.detail.complete();
      }
    )
  }

  const [headerVisible, setHeaderVisible] = useState(true)
  const [currentScrollPos, setCurrentScrollPos] = useState(0)

  let headerClass = classnames({
    'header_hidden': headerVisible === false,
    'header_visible': headerVisible === true
  })

  let fabClass = classnames({
    'fab_visible': persistFabButton ? currentScrollPos > 10 : headerVisible === false,
    'fab_hidden': persistFabButton ? currentScrollPos <= 10 : headerVisible === true
  })

  return (
    <IonPage className={headerClass}>
      <IonHeader className={styles.header_toggle}>
        <IonToolbar color="secondary">
          <IonButtons slot="start" className={styles.left_buttons}>
            <IonMenuButton />
          </IonButtons>

          <IonGrid className={styles.header_grid}>
            <IonRow>
              <IonCol class="ion-text-center">
                <div className={styles.page_header}>
                  <div className={styles.page_title}>{pageTitle}</div>
                  <div className={styles.page_sub_title}>{username ? username : null}</div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonButtons className={styles.right_buttons} slot="end">
            {
              actionButtonIcon && actionButtonOnClick
                ?
                <ToolbarActionButton icon={actionButtonIcon} onClickFunction={actionButtonOnClick} />
                :
                <> </>
            }
          </IonButtons>

        </IonToolbar>
      </IonHeader>

      <IonContent id="main-content" fullscreen
                  scrollEvents={true}
                  onIonScroll={async (ev) => {
                    const elem = document.getElementById("main-content");
                    await elem.getScrollElement()
                    const scrollPosition = ev.detail.scrollTop;

                    if(scrollPosition < 100){
                      setHeaderVisible(true)
                    }
                    else{
                      scrollPosition < currentScrollPos ? setHeaderVisible(true) : setHeaderVisible(false)
                    }

                    setCurrentScrollPos(scrollPosition)
                  }}
                  >
        {refreshFunction &&
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        }

        {fabButtonAction &&
          <IonFab vertical="bottom" horizontal="end" slot="fixed" className={fabClass}>
            <IonFabButton onClick={fabButtonAction}>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        }

        {children}
      </IonContent>

      <ConfirmationBox
        isOpen={Boolean(appExitMessage)}
        message={dictionary.confirm_close}
        header="Exit"
        handler={() => App.exitApp()}
        onDismiss={() => setAppExitMessage(false)}
      />

    </IonPage>

  )
}