import React from "react";
import styles from "./styles.module.scss";
import { IonCard, IonCardContent, IonCardHeader, IonIcon } from "@ionic/react";
import { calendarOutline } from 'ionicons/icons';

export const MeetingCards = ({ meetings, openMeetingDetails, dictionary }) => {
  return (
    <>
      {meetings?.map((meeting) => (
        <IonCard key={meeting.id} onClick={() => openMeetingDetails(meeting)} className={styles.meeting_card}>
          <IonCardHeader>
            
            {
                +meeting?.review_meeting === 1 && <span className={styles.review_meeting}>{dictionary.review}</span>
            }
            <h4 className={styles.meeting_title}>{meeting.meeting_title}</h4>
          </IonCardHeader>
          <IonCardContent>
            <div className={styles.date_wrapper}>
              <IonIcon icon={calendarOutline} />
              <span className={styles.date}>{meeting.meeting_date_easyread}</span>
            </div>
          </IonCardContent>
        </IonCard>
      ))}
    </>
  );
};
