import { DefaultTemplate } from "../../components"
import React, {useCallback, useEffect} from "react"
import {addCircleOutline} from "ionicons/icons"
import {ActionEdit, ActionList} from './modals'
import {ACTION_EDIT} from '../../constants/modals'
import {userCanAddActions} from '../logic'

export const UserActions = ({
                              getActions,
                              selectedJourney,
                              userId,
                              openModal,
                              router,
                              journeyPermissions,
                              dictionary,
                            }) => {

  useEffect(() => {
    getActions(selectedJourney.id)
  }, [getActions, selectedJourney])

  const canAdd = useCallback(() => {
    return userCanAddActions(selectedJourney, journeyPermissions, userId)
  }, [selectedJourney, journeyPermissions, userId])

  return(
      <DefaultTemplate pageTitle={dictionary.actions}
                       username={selectedJourney?.display_name}
                       actionButtonIcon={canAdd() ? addCircleOutline : null}
                       actionButtonOnClick={canAdd() ? ()=>{ openModal(ACTION_EDIT, {profile_id: selectedJourney.id}) } : null}
                       refreshFunction={() => getActions(selectedJourney.id)}
                       fabButtonAction={canAdd() ? ()=>{ openModal(ACTION_EDIT, {profile_id: selectedJourney.id}) } : null}
                       dictionary={dictionary}>

        <ActionList />
        <ActionEdit parent={router} />
      </DefaultTemplate>
  )
}