import {connect} from 'react-redux'
import {Menu} from './side-menu'
import {
  journeys,
  ownJourney,
  supportedJourneys,
  setJourney,
  avatars
} from '../../user-journey'
import {userDetails} from '../../user-management'
import {appDictionary} from "../../app"

const mapStateToProps = (state) => ({
  userDetails: userDetails(state),
  journeys: journeys(state),
  supportedJourneys: supportedJourneys(state),
  ownJourney: ownJourney(state),
  avatars: avatars(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  selectJourney: (journey, userId) => dispatch(setJourney(journey, userId))
})

export const MenuConnected = connect(mapStateToProps, mapDispatchToProps)(Menu)