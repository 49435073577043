import { createSelector } from 'reselect'

export const downloadingGoals = (state) =>
    state?.goals?.downloadingGoals

export const goals = (state) =>
  state?.goals?.goals

export const activeGoals = (state) =>
  state?.goals?.goals?.filter(g => !parseInt(g.complete))

export const showCompleteGoals = (state) =>
  state?.goals?.showCompleteGoals

export const visibleGoals = createSelector(
  goals,
  showCompleteGoals,
  (goals, showCompleteGoals) =>
    showCompleteGoals
    ?
    goals
    :
    goals?.filter(g => +g.complete !== 1)
)
