import {
  getVideoWithPreview,
  getVideoPreview,
  videoIsValid,
} from "../../utils/functions/camera"
import {userIsMentor} from '../logic'

export const uploadProfileVideo_Native = async (profileId, vid, saveProfileVideo, dictionary) => {

  if (!vid.target?.files?.length){
    return false
  }

  const selectedVideo = Array.from(vid.target.files)[0]
  const videoPreview = await getVideoPreview(vid.target.files[0])

  const video = document.createElement('video')
  video.preload = 'metadata'
  video.onloadedmetadata = () => {
    window.URL.revokeObjectURL(vid.src)


    if (!videoIsValid(video)) {
      // setBusyIndicator(false)
      return
    }

    saveProfileVideo(profileId, {video: selectedVideo, image: videoPreview}, dictionary)
  }
  video.src = URL.createObjectURL(selectedVideo)
}

export const uploadProfileVideo_Capacitor = async (profileId, saveProfileVideo, setBusyIndicator, showAlertMessage, dictionary) => {

  setBusyIndicator(true)
  let video = null
  try {
    video = await getVideoWithPreview()
  }
  catch (e) {
    setBusyIndicator(false)
  }

  if (video?.error){
    showAlertMessage(dictionary.problem_capturing_video)
    //TODO log error
    return
  }

  if (!video){
    return
  }

  await saveProfileVideo(profileId, video, dictionary)
}

export const userCanEditProfile = (journey, user) => {

  // Admins call edit all user profiles
  if (user?.isAdmin){
    return true
  }

  // Mentors of the supported user can edit the profile
  if (userIsMentor(journey, user)) {
    return true
  }

  // The supported user themselves can only edit their profile if they have permission to do so
  return parseInt(journey.individual_edit_profile) === 1;
}