import {
  IonCard, IonCardContent,
  IonInput, IonCheckbox, IonTextarea,
  IonItem, IonLabel,
  IonSelect, IonSelectOption,
} from "@ionic/react";
import { Datepicker } from '@mobiscroll/react'
import "@mobiscroll/react/dist/css/mobiscroll.min.css"
import {ModalTemplate, ValidationMessage} from "../../../../components"
import React, {useEffect, useMemo, useState} from "react";
import styles from './styles.module.scss'
import {formatLongDate} from "../../../../utils/helpers/date"
// import {distanceBetweenPoints} from "chart.js/helpers"

export const ActionEdit = ({
                             isOpen,
                             close,
                             selectedAction,
                             saveAction,
                             goals,
                             userId,
                             downloadGoals,
                             selectedJourney,
                             viewingOwnJourney,
                             parent,
                             dictionary,
                           }) => {

  const [actionText, setActionText] = useState(null)
  const [dueDate, setDueDate] = useState(null)
  const [goalId, setGoalId] = useState(null)
  const [reminder, setReminder] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [validationMessage, setValidationMessage] = useState(null)

  useEffect(() => {
    if(selectedAction){
      setActionText(selectedAction.action)
      setDueDate(selectedAction.due_date)
      setGoalId(selectedAction.goal_id)
      setReminder(selectedAction.reminder_time)
      setCompleted(Boolean(selectedAction.complete))
    }
  }, [selectedAction])

  useEffect(() => {
    if(isOpen && !goals?.length){
      downloadGoals(selectedJourney.id)
    }
  }, [isOpen, downloadGoals, selectedAction, goals, selectedJourney])

  const canEditAction = useMemo(() => {
    return !selectedAction?.id || parseInt(selectedAction?.created_by_id) === parseInt(userId)
  }, [selectedAction, userId])

  const onSave = () => {
    if (!isValid()){
      setValidationMessage(`${dictionary.add_action_details}<br><br><b>"{dictionary.tip}</b>:${dictionary.adding_due_date}`)
      return false
    }

    saveAction({
      ...selectedAction,
      action: actionText,
      due_date: dueDate,
      goal_id: goalId,
      reminder_time: reminder,
      complete: completed
    })
    close()
  }

  const isValid = () => {
    return Boolean(actionText)
  }

  const getGoalTitle = (goalId) => {
    return goals?.find(g => parseInt(g.id) === parseInt(goalId))?.title
  }
  
  const dueDateChange = (ev) => {
    setDueDate(ev.value)
  }

  return (
    <ModalTemplate isOpen={isOpen}
                   title={dictionary.action_details}
                   closeMethod={close}
                   saveMethod={canEditAction && onSave}
                   parent={parent}>

      <IonCard>
        {
          canEditAction
          ?
            <IonCardContent className={styles.cardpadless}>
              <IonItem lines='none'>
                <IonTextarea placeholder={dictionary.describe_action}
                             value={actionText}
                             rows={4}
                             onIonChange={(e) => {
                               setActionText(e.target.value)
                             }} />
              </IonItem>

              <IonItem lines='none' className={styles.date_picker}>
              <IonLabel position="fixed">{dictionary.due_date}</IonLabel>
                <Datepicker
                  controls={['datetime']}
                  setText={dictionary.ok}
                  cancelText={dictionary.cancel}
                  touchUi={true}
                  inputComponent="input"
                  inputProps={ { placeholder: dictionary.none } }
                  value={dueDate}
                  onChange={dueDateChange}
                  dateFormat='DDD D MMM YYYY'

                />
              </IonItem>
              

              <IonItem lines='none'>
              <IonLabel position="fixed">{dictionary.goal}</IonLabel>
                <IonSelect value={goalId}
                           placeholder={dictionary.none}
                           okText={dictionary.ok}
                           cancelText={dictionary.cancel}
                           onIonChange={e => {
                             setGoalId(e.detail.value)
                           }}>
                  {
                    goals?.map((goal, index) => {
                      return (
                          <IonSelectOption key={index} value={goal.id}>{goal.title}</IonSelectOption>
                      )
                    })
                  }
                </IonSelect>
              </IonItem>
            </IonCardContent>
          :
            <IonCardContent className={styles.cardpadless}>
              <IonItem lines='none'>
                <IonTextarea readonly rows={4} value={actionText} />
              </IonItem>

              <IonItem lines='none'>
              <IonLabel position="fixed">{dictionary.due_date}</IonLabel>
                <IonInput readonly value={formatLongDate(dueDate)}> </IonInput>
              </IonItem>

              <IonItem lines='none'>
              <IonLabel position="fixed">{dictionary.goal}</IonLabel>
                <IonInput readonly value={getGoalTitle(goalId)}> </IonInput>
              </IonItem>

              <IonItem lines="none" className={styles.can_not_edit_text}>
                <p>
                  {dictionary.details_added_by_mentor}
                  {dictionary.please_contact_pre} {selectedAction?.created_by} {dictionary.please_contact_post}
                </p>
              </IonItem>
            </IonCardContent>
          }
      </IonCard>

      {
        viewingOwnJourney &&
          <IonCard>
            <IonCardContent className={styles.cardpadless}>
              <IonItem lines='none'>
              <IonLabel position="fixed">{dictionary.reminder}</IonLabel>
                <IonSelect placeholder={dictionary.none}
                           okText={dictionary.ok}
                           cancelText={dictionary.cancel}
                           value={reminder}
                           onIonChange={e => {
                             setReminder(e.detail.value)
                           }}>
                  <IonSelectOption value={null}>{dictionary.none}</IonSelectOption>
                  <IonSelectOption value={"15"}>{dictionary.fifteen_minutes_before}</IonSelectOption>
                  <IonSelectOption value={"60"}>{dictionary.one_hour_before}</IonSelectOption>
                  <IonSelectOption value={"1440"}>{dictionary.one_day_before}</IonSelectOption>
                </IonSelect>
              </IonItem>

            </IonCardContent>
          </IonCard>
      }
      {
        viewingOwnJourney &&
          <IonCard>
            <IonCardContent className={styles.cardpadless}>
              <IonItem lines='none'>
                <IonLabel>{dictionary.complete}</IonLabel>
                <IonCheckbox color="success"
                             checked={completed}
                             onIonChange={e => {
                               setCompleted(e.detail.checked)
                             }}/>
              </IonItem>

              {validationMessage &&
                <ValidationMessage validationMessage={validationMessage} clearMessage={setValidationMessage}/>}

            </IonCardContent>
          </IonCard>
      }
    </ModalTemplate>
  )
}