import {connect} from 'react-redux'
import {Post} from './post'
import {downloadPostMedia, toggleLike, setPosts} from '../../../story'
import {setMultiFeedPosts} from '../../../multi-feed'
import {updateGroupActionProps, textToSpeechEnabled, appDictionary} from "../../../../app"
import {userDetails, userId} from '../../../../user-management'
import {journeys, setJourney, usersJourneyPermissions, viewingOwnJourney, } from '../../../state'

const mapStateToProps = (state) => ({
  userDetails: userDetails(state),
  textToSpeechEnabled: textToSpeechEnabled(state),
  journeys: journeys(state),
  userId: userId(state),
  journeyPermissions: usersJourneyPermissions(state),
  viewingOwnJourney: viewingOwnJourney(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  toggleLike: async (like, post, reactionId, userId, postSaveActions) => {
    const likedPost = await dispatch(toggleLike(like, post, reactionId, userId))
    if (likedPost && postSaveActions) {
      postSaveActions(likedPost)
    }
  },
  selectPost: (post) => dispatch(updateGroupActionProps('edit_post', {post: post})),
  fetchMedia: (post) => dispatch(downloadPostMedia(post)),
  selectJourney: async (journey, userId) => {
    await dispatch(setPosts([]))
    await dispatch(setMultiFeedPosts([]))
    await dispatch(setJourney(journey, userId))
  }
})

export const PostConnected = connect(mapStateToProps, mapDispatchToProps)(Post)