import {
  SET_MEETINGS,
  SET_MEETING_IMAGES,
  SET_MEETING_LINKS,
  RESET_STATE,
} from './action-types'

const initialState = {}

export const meetingReducer = (state = initialState, action) => {
  switch(action.type){

    case SET_MEETINGS:
      return { ...state,
        meetings: action.payload && action.payload.length > 0 ? [...action.payload] : [],
      }

    case SET_MEETING_IMAGES:
      return {
        ...state,
        meetings: state?.meetings?.map((m) => m.id === action.payload.meetingId ? {...m, images: action.payload.images} : m)
      }

    case SET_MEETING_LINKS:
      return {
        ...state,
        meetings: state?.meetings?.map((m) => m.id === action.payload.meetingId ? {...m, links: action.payload.links} : m)
      }

    case RESET_STATE:
      return {
        ...initialState
      }

    default:
      return state
  }
}