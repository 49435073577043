import {downloadProfile, downloadSupporters, uploadProfile, uploadProfileVideo} from '../../../utils/api/journey'
import { setDownloadingProfile, setProfile, setSupporters, resetState } from './actions'
import {setBusyIndicator, showAlertMessage, showToastMessage} from "../../../app"

export const getProfile = (profileId) => {
  return async (dispatch) => {
    dispatch(setDownloadingProfile(true))

    const profile = await downloadProfile(profileId, dispatch)
    if (profile?.length){
      dispatch(setProfile(profile[0]))
    }

    dispatch(setDownloadingProfile(false))
  }
}

export const getSupporters = (profileId) => {
  return async (dispatch) => {
    const sups = await downloadSupporters(profileId, dispatch)
    dispatch(setSupporters(sups))
  }
}

export const removeProfileVideo = (profileId, dictionary) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const success = await uploadProfile(profileId, null, dispatch)

    if (!success){
      dispatch(showAlertMessage(dictionary.problem_removing_video))
      return
    }

    dispatch(showToastMessage(dictionary.video_removed))
    dispatch(getProfile(profileId))

    dispatch(setBusyIndicator(false))

  }
}

export const saveProfileVideo = (profileId, video, dictionary) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    // console.log({profileId})
    // console.log({video})

    await uploadProfileVideo(profileId, video, dispatch)

    if (!video.video_fid){
      dispatch(showAlertMessage(dictionary.problem_saving_video))
      return
    }

    const success = await uploadProfile(profileId, video, dispatch)

    if (!success){
      dispatch(showAlertMessage(dictionary.problem_saving_profile))
      return
    }

    dispatch(showToastMessage(dictionary.video_saved))
    dispatch(getProfile(profileId))

    dispatch(setBusyIndicator(false))
  }
}

export const clearProfile = () => {
  return (dispatch) => dispatch(resetState())
}