import { connect } from 'react-redux'
import { About } from './about'
import {appDictionary, versionNumber} from '../../app'

const mapStateToProps = (state) => ({
  versionNumber: versionNumber(state),
  dictionary: appDictionary(state),
})

// const mapDispatchToProps = (dispatch) => ({
// })

export const AboutConnected = connect(mapStateToProps, null)(About)