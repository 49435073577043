import {IonAlert} from "@ionic/react";
import React from "react";

export const ValidationMessage = ({validationMessage, clearMessage}) => {
  return(
    <IonAlert
      isOpen={Boolean(validationMessage)}
      header={'Alert'}
      message={validationMessage}
      buttons={['OK']}
      onDidDismiss={() => {
        clearMessage()
      }}
    />
  )
}