import {get} from "./api";

export const getAccess = async (accessCode, dispatch) => {
  const organisation = await get('/organisation_access', `code=${accessCode}`, dispatch)

  if(organisation?.length === 1){
    localStorage.setItem('organisation_details', JSON.stringify(organisation[0]))
  }

  return organisation
}

export const GetUidFromPin = async (pin) => {

  return await get('/group_action_verification', `pin=${pin}`)
}

export const TfaCodeRequired = async () => {
  const response = await get('/email-tfa/code-required')
  return response?.required
}

export const validateTfaCode = async (code) => {
  const response = await get('/email-tfa/validate-code', `code=${code}`)
  return response?.success
}