import {
  SET_BUSY,
  SET_DOWNLOADING,
  SET_NETWORK_STATUS,
  SET_ORGANISATION_SELECTED,
  SET_VALID_LOGIN_TOKEN,
  SET_APP_INITIALISED,
  SET_OPEN_MODAL,
  CLOSE_MODAL,
  SET_GROUP_ACTION,
  SET_ALERT_MESSAGE,
  SET_VERSION_NUMBER,
  SET_TOAST_MESSAGE,
  SET_ORGANISATION_SETTINGS,
  SET_PUSH_ACTION,
  SET_TFA_CODE_REQUIRED,
  SET_APP_LANGUAGE,
} from './action-types'

const initialState = {
  networkStatus: 'ONLINE',
  appInitialised: false,
  modals: [],
  tfaCodeRequired: false
}

export function appReducer(state = initialState, action){
  switch (action.type) {

    case SET_BUSY:
      return { ...state,
        appBusy: action.payload
      }

    case SET_DOWNLOADING:
      return { ...state,
        appDownloading: action.payload
      }

    case SET_NETWORK_STATUS:
      return {
        ...state,
        networkStatus: action.payload
      }

    case SET_VERSION_NUMBER:
      return {
        ...state,
        versionNumber: action.payload
      }

    case SET_ORGANISATION_SELECTED:
      return {
        ...state,
        organisationSelected: action.payload
      }

    case SET_VALID_LOGIN_TOKEN:
      return {
        ...state,
        validLoginToken: action.payload
      }

    case SET_APP_INITIALISED:
      return {
        ...state,
        appInitialised: action.payload
      }

    case SET_OPEN_MODAL:
      return {
        ...state,
        modals: state.modals.concat({modalName: action.payload.modal, modalData: action.payload.modalData})
      }

    case CLOSE_MODAL:
      return {
        ...state,
        modals: state?.modals?.length > 1 ? state.modals.slice(0, state.modals.length - 1) : []
      }

    case SET_GROUP_ACTION:
      return {
        ...state,
        groupAction: action.payload
      }

    case SET_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: action.payload
      }

    case SET_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: action.payload
      }

    case SET_ORGANISATION_SETTINGS:
      return {
        ...state,
        organisationSettings: action.payload
      }

    case SET_PUSH_ACTION:
      return {
        ...state,
        pushAction: action.payload
      }

    case SET_TFA_CODE_REQUIRED:
      return {
        ...state,
        tfaCodeRequired: action.payload
      }

    case SET_APP_LANGUAGE:
      return {
        ...state,
        ...action.payload
      }

    default:
      // alert(JSON.stringify(state))
      return state
  }
}