import {connect}  from 'react-redux'
import {NetworkStatus} from './network-status'
import {updateNetworkStatus} from '../actions'
import {appDictionary, networkStatus} from '../selectors'
import {initialiseApp} from "../logic";

const mapStateToProps = (state) => ({
  networkStatus: networkStatus(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateNetworkStatus: (online) => {
    online ? dispatch(updateNetworkStatus('ONLINE')) : dispatch(updateNetworkStatus('OFFLINE'))
  },
  retry: async () => {
    const online = await dispatch(updateNetworkStatus('ONLINE'))
    if(online){
      dispatch(initialiseApp())
    }
  }
})

export const NetworkStatusConnected = connect(mapStateToProps, mapDispatchToProps)(NetworkStatus)