import {
  SET_POSTS,
  SET_STORY_FILTER,
  UPDATE_POST,
  SET_REPORTED_STATUS,
  SET_DOWNLOADING_REPLIES,
  SET_REPLIES,
  SET_SELECT_POST_ID,
  UPDATE_REPLY,
  SET_SELECTED_MEDIA,
  SET_LIKE_LIST,
  SET_GOAL_LIST,
  RESET_STATE,
} from "./action-types";
import {storyPaginationSize} from "../../../constants/constants";
import {mergeArray} from '../../../utils/helpers/array'

const initialState = {}

export const storyReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_SELECTED_MEDIA:
      return {
        ...state,
        selectedMedia: action.payload
      }

    case SET_POSTS:
      return { ...state,
        posts: (action.payload.concat) ? state.posts.concat(action.payload.posts) : action.payload.posts,
        hasMorePosts: action.payload.posts?.length === storyPaginationSize
      }

    case SET_SELECT_POST_ID:
      return {
        ...state,
        selectedPostId: action.payload
      }

    case UPDATE_POST:
      return {
        ...state,
        posts: mergeArray(state?.posts, action.payload, 'id', true),
      }

    case UPDATE_REPLY:
      return {
        ...state,
        replies: mergeArray(state?.replies, action.payload),
      }

    case SET_STORY_FILTER:
      return { ...state,
        storyFilter: action.payload
      }

    case SET_REPORTED_STATUS:
      return {
        ...state,
        reportedStatus: action.payload
      }

    case SET_DOWNLOADING_REPLIES:
      return {...state,
        downloadingReplies: action.payload
      }

    case SET_REPLIES:
      return { ...state,
        replies: action.payload
      }

    case SET_LIKE_LIST:
      return {
        ...state,
        likeList: action.payload
      }

    case SET_GOAL_LIST:
      return {
        ...state,
        goalList: action.payload
      }

    case RESET_STATE:
      return {
        ...initialState
      }

    default:
      return state
  }
}