import {IonAvatar, IonItem, IonLabel, IonList, IonSkeletonText} from "@ionic/react";

export const SkeletonText = ({ isVisible }) => {
  if (!isVisible) return null

  return (
      <>
        <IonList lines='none'>
          <IonItem>
            <IonAvatar slot="start">
              <IonSkeletonText animated />
            </IonAvatar>
            <IonLabel>
              <h3>
                <IonSkeletonText animated style={{ width: '50%' }} />
              </h3>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <IonSkeletonText animated />
            </IonAvatar>
            <IonLabel>
              <h3>
                <IonSkeletonText animated style={{ width: '50%' }} />
              </h3>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonAvatar slot="start">
              <IonSkeletonText animated />
            </IonAvatar>
            <IonLabel>
              <h3>
                <IonSkeletonText animated style={{ width: '50%' }} />
              </h3>
            </IonLabel>
          </IonItem>

        </IonList>
      </>
  )
}