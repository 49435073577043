export const mergeAvatars = (currentAvatars, newAvatars) => {

  let mergedAvatars = currentAvatars?.map(avatar => {
    return newAvatars.find(a => parseInt(a.uid) === parseInt(avatar.uid)) ?? avatar
  })

  const avatarsToAdd = newAvatars?.filter(avatar => {
    return !mergedAvatars?.find(a => parseInt(a.uid) === parseInt(avatar.uid))
  })

  return mergedAvatars?.concat(avatarsToAdd)

}
