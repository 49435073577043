import {IonCard, IonCardContent} from "@ionic/react";

export const DeletedItem = ({text}) => {
  return (
    <IonCard>
      <IonCardContent>
        <p>{text ?? "This item has been deleted"}</p>
      </IonCardContent>
    </IonCard>
  )
}