export const SET_POSTS = 'SET_POSTS'
export const UPDATE_POST = 'UPDATE_POST'
export const REPORT_POST = 'REPORT_POST'
export const SET_REPORTED_STATUS = 'SET_REPORTED_STATUS'
export const SET_STORY_FILTER = 'SET_STORY_FILTER'
export const SET_DOWNLOADING_REPLIES = 'SET_DOWNLOADING_REPLIES'
export const SET_REPLIES = 'SET_REPLIES'
export const UPDATE_REPLY = 'UPDATE_REPLY'
export const SET_SELECT_POST_ID = 'SET_SELECT_POST_ID'
export const SET_SELECTED_MEDIA = 'SET_SELECTED_MEDIA'
export const SET_LIKE_LIST = 'SET_LIKE_LIST'
export const SET_GOAL_LIST = 'SET_GOAL_LIST'
export const RESET_STATE = 'RESET_STATE'

