import {IonCard, IonList, IonIcon, IonItem, IonLabel} from "@ionic/react";
import {Browser} from '@capacitor/browser'
import {ImageGallery, ModalTemplate} from "../../../../components";
import React, {useState} from "react";
import styles from "./styles.module.scss";
import {RatingDial} from "../../../goal/components";
import {peopleOutline, createOutline, calendarOutline, imagesOutline, linkOutline} from 'ionicons/icons'

export const MeetingDetail = ({isOpen,
                                close,
                                meeting,
                                parent,
                                dictionary,
                              }) => {

  const [selectedImages, setSelectedImages] = useState(null)

  return (
    <ModalTemplate isOpen={isOpen}
                   title={+meeting?.review_meeting === 1 ? dictionary.review_meeting : dictionary.meeting}
                   closeMethod={()=>{close()}}
                   parent={parent}>

      <p className={styles.title}>
        {meeting?.meeting_title}
      </p>

      {
        +meeting?.review_meeting === 1 && <p className={styles.sub_title}>Review Meeting</p>
      }

      <div className={`${styles.item_container} ${styles.item_border} ${styles.item_date}`}>
        <IonIcon icon={calendarOutline} />
        <div>{meeting?.meeting_date_easyread}</div>
      </div>

      <div className={styles.item_container}>
        <IonIcon icon={createOutline} />
        {
          Boolean(meeting?.meeting_notes)
            ?
            <div>{meeting?.meeting_notes}</div>
            :
            <div className={styles.no_content}>{dictionary.no_meeting_notes}</div>
        }
      </div>

      <div className={`${styles.item_container} ${styles.item_border}`}>
        <IonIcon icon={peopleOutline} />
        {
          Boolean(meeting?.meeting_attendees)
          ?
          <div>{meeting?.meeting_attendees}</div>
          :
          <div className={styles.no_content}>{dictionary.no_attendees}</div>
        }
      </div>

      <div className={styles.item_container}>
        <IonIcon icon={imagesOutline} />
        {
          meeting?.images?.length
          ?
          <div onClick={() => setSelectedImages(meeting?.images?.map(i => {return {image: i.image}}))}>
            {
              meeting?.images?.map(image => {
                return (
                  <img
                    className={styles.thumbnail}
                    src={image.image}
                    alt=""
                  />
                )
              })
            }
          </div>
          :
          <div className={styles.no_content}>
            {dictionary.no_images_added}
          </div>
        }
      </div>

      <div className={`${styles.item_links} ${styles.item_border}`}>
        {
          meeting?.links?.length
          ?
          <IonCard>
            <IonList>
              {
                meeting?.links?.map(link => {
                  return (
                    <IonItem className={styles.links} detail='true'
                          onClick={()=> Browser.open({ url: link.link,  windowName: '_self' })}>
                            <IonIcon slot='start' icon={linkOutline} /> 
                            <IonLabel>{link.title}</IonLabel>
                    </IonItem>
                  )
                })
              }
            </IonList>
          </IonCard>
          :
          <div className={styles.item_container}>
            <IonIcon icon={linkOutline} />
            <div className={styles.no_content}>
              {dictionary.no_links_added}
            </div>
          </div>
        }
      </div>
      
      {
      meeting?.ratings?.length
      ?
      <div>
        <IonList className={styles.ratings} lines="none" inset='true'>
          {
            meeting?.ratings?.map((rating, index) => {
              return (
                <div className={styles.rating} key={index}>
                  <div className={styles.rating_dial}>
                    <RatingDial rating={rating.rating ? rating.rating : 0}/>
                  </div>
                  <div className={styles.rating_notes}>
                    <h6>{rating.goal}</h6>
                    <p>{rating.notes}</p>
                  </div>
                </div>
              )
            })
          }
        </IonList>
      </div>
      :
      <div className={styles.item_container}>
            <IonIcon icon={linkOutline} />
            <div className={styles.no_content}>
                No ratings
            </div>
          </div>
      }

      <ImageGallery
        selectedMedia={selectedImages}
        clearSelectedMedia={setSelectedImages}
        parent={parent}
        title='Meeting images'
      />

    </ModalTemplate>
  )
}