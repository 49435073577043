import React, {useCallback, useEffect} from "react"
import {ModalTemplate,} from "../../../../../components"
import {IonAvatar, IonItem, IonLabel, IonList} from "@ionic/react";

export const LikeList = ({post, close, parent, downloadLikeList, clearLikeList, likeList, dictionary}) => {

  useEffect(() => {
    if (post) {
      downloadLikeList(post)
    }
  }, [post, downloadLikeList])

  const closeMe = useCallback(() => {
    clearLikeList()
    close()
  }, [clearLikeList, close])

  return (
    <ModalTemplate isOpen={Boolean(post)}
                   title={dictionary.likes}
                   closeMethod={closeMe}
                   parent={parent}>


      <IonList lines='inset'>
        {likeList?.map((elem, index) => {
          return (
            <IonItem key={index}>
              <IonAvatar slot="start">
                <img src={elem.avatar} alt="" />
              </IonAvatar>
              <IonLabel>
                {elem.likedByName}
              </IonLabel>
            </IonItem>
          )
        })}
      </IonList>

    </ModalTemplate>
  )

}
