import {connect} from 'react-redux'
import {UserJourney} from './user-journey'
import {selectedJourney, usersJourneyPermissions, viewingOwnJourney} from './state'
import {appDictionary} from "../app"

const mapStateToProps = (state) => ({
  selectedJourney: selectedJourney(state),
  journeyPermissions: usersJourneyPermissions(state),
  viewingOwnJourney: viewingOwnJourney(state),
  dictionary: appDictionary(state),
})

export const UserJourneyConnected = connect(mapStateToProps, null)(UserJourney)