import {
  SET_LOGGEDIN,
  SET_LOGIN_DETAILS,
  LOGIN_FAILURE,
  SET_LOGIN_MESSAGE,
  CLEAR_USER_DATA,
  SET_USER_DETAILS,
} from './action-types'
import { profilePic } from '../icons'

export default function userManagementReducer(state = {}, action){
  switch (action.type) {

    case SET_LOGGEDIN:
      return { ...state,
        isLoggedIn: action.payload.loggedIn,
        userInitialised: true
      }

    case SET_LOGIN_DETAILS:
      return { ...state,
        csrfToken: action.payload.csrf_token,
        logoutToken: action.payload.logout_token
      }

    case LOGIN_FAILURE:
      return { ...state,
        loginFailureMessage: action.payload
      }

    case SET_LOGIN_MESSAGE:
      return { ...state,
        loginFailureMessage: action.payload
      }

    case SET_USER_DETAILS:
      if(!action.payload.user_picture) {
        action.payload.user_picture = profilePic
      }

      return { ...state,
        userDetails: action.payload
      }

    case CLEAR_USER_DATA:
      return { ...state,
        isLoggedIn: false,
        cmsRoles: null,
        csrfToken: null,
        logoutToken: null,
        userDetails: null,
      }

    default:
      // alert(JSON.stringify(state))
      return state
  }
}