import * as actionTypes from './action-types'
import {mergeArray} from "../../../utils/helpers/array"

const initialState = {
  posts: []
}

export function multiFeedReducer(state = initialState, action){
  switch (action.type) {

    case actionTypes.SET_MULTI_FEED_POSTS:
      return {
        ...state,
        posts: action.payload
      }

    case actionTypes.SET_SELECTED_MULTI_FEED_POSTS_ID:
      return {
        ...state,
        selectedMultiFeedPostId: action.payload
      }

    case actionTypes.UPDATE_MULTI_FEED_POST:
      return {
        ...state,
        posts: mergeArray(state?.posts, action.payload, 'id', true),
      }

    case actionTypes.UPDATE_MULTI_FEED_REPLY:
      return {
        ...state,
        replies: mergeArray(state?.replies, action.payload),
      }

    case actionTypes.RESET_STATE:
      return {
        ...initialState
      }

    default:
      return state
  }
}