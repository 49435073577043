import {IonItem, IonLabel, IonSelect, IonSelectOption} from "@ionic/react"
import React from "react"

export const LanguageSelect = ({ selectedLanguage, selectLanguage, dictionary }) => {

  return (
      <IonItem>
        <IonLabel position="fixed">{dictionary.language}</IonLabel>
        <IonSelect value={selectedLanguage} okText={dictionary.ok} cancelText={dictionary.cancel} onIonChange={(e) => selectLanguage(e.detail.value)}>
          <IonSelectOption value="english">English</IonSelectOption>
          <IonSelectOption value="welsh">Welsh</IonSelectOption>
        </IonSelect>
      </IonItem>
  )

}