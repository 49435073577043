import {connect}  from 'react-redux'
import {ActionList} from './action-list'
import {
  deleteActionAndRefresh,
  markAsComplete,
  toggleShowCompleteActions,
  userActions,
  downloadingActions,
  showCompleteActions
} from '../../state'
import {selectedJourney, viewingOwnJourney} from '../../../state'
import {openModal, getOpenModalData, appDictionary} from "../../../../app"
import {userDetails} from '../../../../user-management'

const mapStateToProps = (state) => ({
  selectedJourney: selectedJourney(state),
  userDetails: userDetails(state),
  actions: userActions(state, getOpenModalData(state)),
  goal: getOpenModalData(state),
  showComplete: showCompleteActions(state),
  downloadingActions: downloadingActions(state),
  viewingOwnJourney: viewingOwnJourney(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  markAsComplete: (action, complete) => dispatch(markAsComplete(action, complete)),
  toggleShowCompleteActions: (showComplete) => dispatch(toggleShowCompleteActions(showComplete)),
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData)),
  deleteAction: (action) => dispatch(deleteActionAndRefresh(action)),
})

export const ActionListConnected = connect(mapStateToProps, mapDispatchToProps)(ActionList)