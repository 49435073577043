import React, {useEffect, useMemo} from 'react'
import {filterCircleOutline} from 'ionicons/icons'
import {DefaultTemplate, NoContent} from "../../components"
import {NewPostButton, SkeletonTextStory} from './components'
import {Feed} from "../components/feed"
import {StoryFilter} from './modals'
import {STORY_FILTER} from "../../constants/modals"

export const Story = ({ selectedJourney,
                        addPost,
                        selectPost,
                        selectedPost,
                        savePost,
                        downloadPosts,
                        downloadingPosts,
                        posts,
                        openModal,
                        router,
                        deletePost,
                        dictionary,
                        journeyPermissions,
                        viewingOwnJourney,
                      }) => {

  useEffect(() => {
    downloadPosts(selectedJourney?.id)
  }, [downloadPosts, selectedJourney])

  const canUseFilter = useMemo(() => {
    return (viewingOwnJourney || parseInt(journeyPermissions?.view_goals) === 1)
  }, [viewingOwnJourney, journeyPermissions])

  return (
    <DefaultTemplate pageTitle={dictionary?.story}
                     username={selectedJourney?.display_name}
                     actionButtonIcon={canUseFilter && filterCircleOutline}
                     actionButtonOnClick={() => { openModal(STORY_FILTER) }}
                     refreshFunction={() => downloadPosts(selectedJourney?.id)}
                     fabButtonAction={addPost}
                     persistFabButton={true}
                     dictionary={dictionary}>

      <NewPostButton />

      <SkeletonTextStory
        isVisible={downloadingPosts}
      />

      <NoContent
        isVisible={!downloadingPosts && !posts?.length}
        text={dictionary.empty_story}
      />

      {
        !downloadingPosts &&
        <Feed posts={posts}
              selectPost={selectPost}
              selectedPost={selectedPost}
              savePost={savePost}
              deletePost={deletePost}
              router={router}
        />
      }

      {
        canUseFilter &&
        <StoryFilter parent={router} />
      }

    </DefaultTemplate>
  )
}