import {IonActionSheet} from "@ionic/react"
import React, {useMemo} from "react"
import {canEditPost} from "../../../story/logic"

export const PostOptions = ({
                              selectedPost,
                              openModal,
                              userId,
                              selectPost,
                              setPostForOptions,
                              setPostForDeletion,
                              dictionary,
                        }) => {

  const getButtons = useMemo(() => {
    let buttons = []

    if(canEditPost(selectedPost?.posted_by_id, userId)){
      buttons.push(
        {
          text: dictionary.edit,
          role: 'destructive',
          handler: () => {selectPost(selectedPost)}
        },
        {
          text: dictionary.delete,
          role: 'destructive',
          handler: () => setPostForDeletion(selectedPost)
        }
      )
    }
    else{
      buttons.push(
        {
          text: dictionary.report,
          role: 'destructive',
          handler: () => openModal('REPORT_POST', selectedPost)
        }
      )
    }

    buttons.push(
      {
        text: dictionary.cancel,
        role: 'cancel'
      }
    )

    return buttons
  }, [openModal, selectPost, selectedPost, userId, setPostForDeletion, dictionary])

  return (
    <IonActionSheet
      isOpen={Boolean(selectedPost)}
      onDidDismiss={() => setPostForOptions(null)}
      buttons={getButtons}>
    </IonActionSheet>
  )
}