import React, {useCallback, useEffect, useState} from "react"
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import {MeetingDetail} from './modals/meeting-details'
import {MeetingCalendar, MeetingCards, SkeletonText} from './components'
import styles from "./components/meeting-calendar/styles.module.scss"
import {DefaultTemplate, NoContent} from "../../components"
import {calendarOutline, listOutline} from "ionicons/icons"

export const Meetings = ({
                           selectedJourney,
                           downloadingMeetings,
                           downloadMeetings,
                           meetings,
                           openMeetingDetails,
                           useCalendarView = false,
                           router,
                           dictionary,
                         }) => {

  const [selectedView, setSelectedView] = useState('calendar')

  useEffect(() => {
    downloadMeetings(selectedJourney.id)
  }, [downloadMeetings, selectedJourney])

  const switchView = useCallback(() => {
    selectedView === 'calendar' ? setSelectedView('list') : setSelectedView('calendar')
  }, [selectedView])

  const getIcon = useCallback(() => {
    if (!useCalendarView) return null
    return selectedView === 'calendar' ? listOutline : calendarOutline
  }, [selectedView, useCalendarView])

  return (
      <DefaultTemplate pageTitle={dictionary.meetings}
                       username={selectedJourney.display_name}
                       refreshFunction={() => downloadMeetings(selectedJourney.id)}
                       actionButtonIcon={getIcon()}
                       actionButtonOnClick={() => switchView()}
                       dictionary={dictionary}>

        <SkeletonText
          isVisible={downloadingMeetings}
          styles={styles} />

        <NoContent
          isVisible={!downloadingMeetings && !meetings?.length}
          text={dictionary.no_meetings} />

        {
          useCalendarView
          ?
          <MeetingCalendar
            meetings={meetings}
            openMeetingDetails={openMeetingDetails}
            downloadingMeetings={downloadingMeetings}
            selectedJourney={selectedJourney}
            downloadMeetings={downloadMeetings}
            selectedView={selectedView}
            dictionary={dictionary}/>
          :
          <MeetingCards
            meetings={meetings}
            openMeetingDetails={openMeetingDetails}
            downloadingMeetings={downloadingMeetings}
            selectedJourney={selectedJourney}
            downloadMeetings={downloadMeetings}
            dictionary={dictionary}/>
        }
        <MeetingDetail parent={router} />

      </DefaultTemplate>
  );
}