import React, {useEffect, useState} from "react";
import {
  IonList,
  IonItem,
  IonToggle,
  IonLabel,
  IonCheckbox,
} from "@ionic/react";
import {ModalTemplate, NoContent} from "../../../../components";
import styles from './styles.module.scss'

export const StoryFilter = ({ isOpen,
                              close,
                              selectedJourney,
                              downloadGoals,
                              goals,
                              storyFilter,
                              setFilter,
                              parent,
                              dictionary,
                            }) => {

  const [goalFilterEnabled, setGoalFilterEnabled] = useState(!!storyFilter)
  const [selectedGoals, setSelectedGoals] = useState([])

  useEffect(() => {
    if(isOpen) downloadGoals(selectedJourney.id)

    if(storyFilter && storyFilter.goals) setSelectedGoals(storyFilter.goals)
  }, [isOpen, downloadGoals, selectedJourney?.id, storyFilter])

  const toggleFilter = (enabled) => {
    setGoalFilterEnabled(enabled)
    if(!enabled) setSelectedGoals([])
  }

  const applyFilter = () => {
    goalFilterEnabled ? setFilter({goals: selectedGoals}) : setFilter(null)
    close()
  }

  return (
    <ModalTemplate isOpen={isOpen}
                   title={dictionary.story_filters}
                   closeMethod={() => close()}
                   saveMethod={applyFilter}
                   parent={parent}>

      <NoContent
        isVisible={!goals?.length}
        text={dictionary.no_filter_goals}
      />

      {
       goals?.length &&
        <IonList lines="full">
          <div className={styles.goals_modal_content}>
            <div className={styles.goals_header}>
              <h1>Goals</h1>

              <div className={styles.goal_filter_enable}>
                <IonLabel className={styles.goal_filter_label}>{dictionary.enable_goal_filter}</IonLabel>
                <IonToggle checked={goalFilterEnabled} onIonChange={(e) => toggleFilter(e.detail.checked) } />
              </div>
            </div>
            <p>{dictionary.goal_filter_text}</p>
          </div>
          {
            goals && goals.map((goal, index) => {
              return (
                <IonItem key={index} disabled={!goalFilterEnabled}>
                  <IonLabel>{goal.title}</IonLabel>
                  <IonCheckbox checked={selectedGoals.includes(goal.id)} onClick={()=>
                    selectedGoals.includes(goal.id) ? setSelectedGoals(selectedGoals.filter(item => item !== goal.id)) : setSelectedGoals([...selectedGoals, goal.id])}>
                  </IonCheckbox>
                </IonItem>
              )
            })
          }
        </IonList>
      }

    </ModalTemplate>
  )
}