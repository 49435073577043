import React from "react";
import {Redirect} from "react-router-dom";
import { TabBar} from '../components'

export const UserJourney = ({
                              journeyPermissions,
                              selectedJourney,
                              viewingOwnJourney,
                              dictionary,
                            }) => {

  return (
    <>
      {
        selectedJourney
        ?
          <TabBar permissions={journeyPermissions} viewingOwnJourney={viewingOwnJourney} dictionary={dictionary} />
        :
          <Redirect from="/" to="/user/supporting" exact />
      }
    </>
  )
}