import {connect} from 'react-redux'
import {MultiFeed} from './multi-feed'
import {selectedMultiFeedPost, downloadMultiFeedPosts, refreshMultiFeedPost, multiFeedPosts} from "./state"
import {appDictionary, updateGroupActionProps} from "../../app"
import {appDownloading} from "../../app"

const mapStateToProps = (state) => ({
  posts: multiFeedPosts(state),
  selectedPost: selectedMultiFeedPost(state),
  appDownloading: appDownloading(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadPosts: () => dispatch(downloadMultiFeedPosts()),
  selectPost: (post) => dispatch(updateGroupActionProps('edit_multi_feed_post', {post: post})),
  postSaveActions: (post) => dispatch(refreshMultiFeedPost(post)),
})

export const MultiFeedConnected = connect(mapStateToProps, mapDispatchToProps)(MultiFeed)