import {connect} from 'react-redux'
import {ReportPost} from "./report-post";
import {getOpenModal, getOpenModalData, dismissModal, appDictionary} from "../../../app"
import {reportPost, reportedStatus, setReportedStatus} from '../../story'

const mapStateToProps = (state) => ({
  isOpen: getOpenModal(state, 'REPORT_POST'),
  post: getOpenModalData(state),
  reportedStatus: reportedStatus(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(dismissModal(null, null)),
  reportPost: (post, reportText) => dispatch(reportPost(post, reportText)),
  dismissAlert: (reported) => {
    dispatch(setReportedStatus(null))
    if(reported){
      dispatch(dismissModal(null, null))
    }
  }
})

export const ReportPostConnected = connect(mapStateToProps, mapDispatchToProps)(ReportPost)