import React, {useMemo} from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from 'chart.js'
import { Line  } from 'react-chartjs-2'
import {getGradient} from './logic'

export const RatingGraph = ({ ratings, dictionary }) => {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
  );

  const options = {
    responsive: true,
    scales: {
      yScale: {
        position: 'left',
        max: 10
      },
    }
  };

  const labels = useMemo(() => {
    let labels = ratings?.slice(0).reverse().map(r => "")

    for (let x = 0; x < labels?.length; x ++) {
      labels = [""].concat(labels)

      if (labels?.length >= 6) break
    }

    return labels

  }, [ratings])

  const ratingsData = useMemo(() => {
    return [0].concat(ratings?.slice(0).reverse().map(r => r?.rating))
  }, [ratings])

  const data = {
    labels,
    datasets: [
      {
        label: dictionary?.rating,
        data: ratingsData,
        borderColor: function(context) {
          const chart = context.chart;
          const {ctx, chartArea} = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea);
        },
        backgroundColor: 'rgba(255, 255, 255, 1)',
        lineTension: 0.5,
        yAxisID: 'yScale',
      }
    ],
  }


  return (
    <Line options={options} data={data} />
  )
}