import {profilePic} from "../../icons";

export const findAvatar = (avatars, userId) => {
  const avatar = avatars?.find((avatar) => parseInt(avatar.uid) === parseInt(userId))

  return avatar?.user_picture ?? profilePic
}

export const canEditPost = (postedById, userId) => {
  return parseInt(postedById) === parseInt(userId)
}