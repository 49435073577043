import {
  updateAction,
  setSelectedAction,
  setActionComplete,
  setActions,
  setDownloadingActions,
  setShowCompleteActions,
  resetState,
} from './actions'
import {downloadActions, toggleComplete, uploadAction} from "../../../utils/api/journey";
import {setBusyIndicator} from '../../../app'

export const selectAction = (action) => {
  return (dispatch) => {
    dispatch(setSelectedAction(action))
  }
}

export const saveAction = (action) => {
  return (dispatch) => {
    dispatch(setBusyIndicator(true))

    return uploadAction(action, dispatch).then(
      (success) => {
        if(success){
          dispatch(updateAction(action))
        }

        dispatch(setBusyIndicator(false))
      }
    )
  }
}

export const getActions = (profileId) => {
  return async (dispatch) => {
    dispatch(setDownloadingActions(true))

    const actions = await downloadActions(profileId, dispatch)

    dispatch(setActions(actions))
    dispatch(setDownloadingActions(false))

    return actions
  }
}

export const toggleShowCompleteActions = (showComplete) => {
  return (dispatch) => {
    dispatch(setShowCompleteActions(showComplete))
  }
}

export const markAsComplete = (actionId, complete) => {
  return (dispatch) => {
    dispatch(setBusyIndicator(true))

    return toggleComplete(actionId, complete, dispatch).then(
      (success) => {
        if(success){
          dispatch(setActionComplete(actionId, complete))
        }
        dispatch(setBusyIndicator(false))
      }
    )
  }
}

export const deleteActionAndRefresh = (action) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    action.deleted = true

    if (await uploadAction(action, dispatch)){
      dispatch(updateAction(action))
    }

    dispatch(setBusyIndicator(false))
  }
}

export const clearActions = () => {
  return (dispatch) => dispatch(resetState())
}
