import {downloadDeviceTokens, uploadToken, deleteToken, deleteCurrentToken} from '../../utils/api/push-notifications'
import {PushNotifications} from "@capacitor/push-notifications"
import {Capacitor} from "@capacitor/core"
import {ACTION_EDIT} from "../../constants/modals"
import {downloadAction, downloadPost} from "../../utils/api/journey"

export const listenForNotifications = (setPushAction) => {
  PushNotifications.addListener('pushNotificationActionPerformed',
    async (notification) => {

      const data = notification?.notification?.data

      if (!data) return

      const notification_type = data?.notification_type
      const pushData = {
        dataId1: data?.data_id_1,
        dataId2: data?.data_id_2
      }
      setPushAction(notification_type, pushData)
    }
  )
}

export const handleNewPost = (journeys, loadJourney, journeyId, userId) => {
  const journey = journeys?.find((j) => parseInt(j.id) === parseInt(journeyId))
  if (journey) {
    loadJourney(journey, userId)
  }
}

export const handleNewReply = async (journeys, loadJourney, journeyId, userId, openModal, parentPostId) => {
  const journey = journeys.find((j) => parseInt(j.id) === parseInt(journeyId))
  if(journey){
    loadJourney(journey, userId)
  }
  const parentPost = await downloadPost(parentPostId)
  openModal('POST_REPLIES', parentPost)
}

export const handleReminder = async (loadJourney, ownJourney, userId, actionId, openModal, history) => {
  loadJourney(ownJourney, userId)
  const action = await downloadAction(ownJourney.id, actionId)
  openModal(ACTION_EDIT, action)
  history.push('/journey/actions')
}

export const registerDevice = async (userId) => {
  if (Capacitor.getPlatform() === 'web') {
    userId && await syncDeviceToken(userId, 'web_test_5', 'web')
    return
  }

  if (!userId) {
    await disallowPushNotifications()
    return
  }

  PushNotifications.addListener('registration',
    (token) => {
      syncDeviceToken(userId, token.value, Capacitor.getPlatform())
    }
  )

  const perm = JSON.parse(localStorage.getItem('push_permission_requested'))
    ? await PushNotifications.checkPermissions()
    : await PushNotifications.requestPermissions()

  localStorage.setItem('push_permission_requested', JSON.stringify(true))

  if (perm?.receive === "granted") {
    await PushNotifications.register()
  }
  else{
    await disallowPushNotifications()
  }
}

const disallowPushNotifications = async () => {
  await PushNotifications.removeAllListeners()
  await PushNotifications.unregister()
  await deleteCurrentToken()
}

const syncDeviceToken = async (uid, deviceToken, platform) => {
  if(!uid){
    return
  }

  let currentToken = localStorage.getItem('device_token')
  currentToken = currentToken ? JSON.parse(currentToken) : null

  // If the token hasn't changed then no action is required
  if(currentToken?.token === deviceToken && parseInt(currentToken?.uid) === parseInt(uid)) {
    return
  }

  // Update local storage immediately so that duplicate tokens aren't uploaded due
  // any asynchronous calls to this function
  localStorage.setItem('device_token', JSON.stringify({"id": -1, "uid": uid, "token": deviceToken}))

  // We know the token has changed so delete the current one
  if (currentToken?.id) {
    await deleteToken(currentToken.id)
  }

  // Although there isn't a token stored locally this device may already
  // be registered but the app may have been uninstalled
  const serverTokens = await downloadDeviceTokens(uid)
  const existingToken = serverTokens.find(t => t.token === deviceToken)

  if (existingToken) {
    localStorage.setItem('device_token', JSON.stringify({"id": existingToken.id, "uid": uid, "token": deviceToken}))
    return
  }

  // No token stored locally or on the server so upload the new token
  const newToken = await uploadToken(uid, deviceToken, platform)

  if(newToken){
    localStorage.setItem('device_token', JSON.stringify({"id": newToken.id[0].value, "uid": uid, "token": deviceToken}))
  }
  else {
    localStorage.setItem('device_token', null)
  }
}



