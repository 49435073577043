import { createSelector } from 'reselect'

// The uid of the logged-in user
const userId = (state) =>
  state?.userManagement?.userDetails?.uid

export const journeys = (state) =>
    state?.journey?.journeys

const currentJourney = (state) =>
    state?.journey?.selectedJourney

const selectedJourneyPermissions = (state) =>
  state?.journey?.journeyPermissions

export const usersJourneyPermissions = createSelector(
  userId,
  selectedJourneyPermissions,
  (userId, perms) => {

    if (!perms?.length) {
      return {}
    }

    const permissions = perms[0]

    if (permissions?.uid !== userId){
      return {}
    }

    return perms[0]
  }
)

export const ownJourney = (state) => {
  const userId = state?.userManagement?.userDetails?.uid
  const journeys = state?.journey?.journeys

  if(!userId || !journeys || !journeys.length) {
    return null
  }
  return journeys.find((j) => +j.uid === +userId)
}

export const viewingOwnJourney = createSelector(
  currentJourney,
  userId,
  (currentJourney, userId) => parseInt(currentJourney?.uid) === parseInt(userId)
)

export const avatars = (state) =>
  state?.journey?.avatars

export const supportedJourneys = createSelector(
  journeys,
  userId,
  (journeys, userId) => journeys?.filter((j) => parseInt(j.uid) !== parseInt(userId))
)

export const selectedJourney = createSelector(
  currentJourney,
  ownJourney,
  journeys,
  (currentJourney, ownJourney, journeys) => {

    if (currentJourney) return currentJourney

    if (ownJourney) return ownJourney

    if (journeys?.length === 1) return journeys[0]

    return null
  }
)

export const selectedJourneyAvatar = createSelector(
  selectedJourney,
  avatars,
  (selectedJourney, avatars) => avatars?.find(a => parseInt(a.uid) === parseInt(selectedJourney?.uid))?.user_picture
)
