import { createSelector } from 'reselect'

export const appBusy = (state) =>
    state?.app?.appBusy

export const appDownloading = (state) =>
  state?.app?.appDownloading

export const networkStatus = (state) =>
    state?.app?.networkStatus

export const versionNumber = (state) =>
  state?.app?.versionNumber

export const organisationSelected = (state) =>
    state?.app?.organisationSelected

export const validLoginToken = (state) =>
    state?.app?.validLoginToken

export const appInitialised = (state) =>
    state?.app?.appInitialised

export const isOnline = (state) =>
    state?.app?.networkStatus === 'ONLINE'

const openModals = (state) =>
  state?.app?.modals

export const getOpenModal = (state, modalName) => {
  const openModals = state?.app?.modals

  if(!openModals || openModals.length === 0){
    return false
  }

  return !!openModals.find(modal => modal.modalName === modalName)
}

export const getOpenModalData = createSelector(
  openModals,
  (modals) => modals?.length ? modals[modals.length - 1]?.modalData : null
)

export const openModalCount = createSelector(
  openModals,
  (modals) => modals?.length
)

export const groupActionArgs = state =>
  state?.app?.groupAction?.args

export const groupActionAction = state =>
  state?.app?.groupAction?.action

export const groupActionUserId = state =>
  state?.app?.groupAction?.uid

export const alertMessage = state =>
  state?.app?.alertMessage

export const toastMessage = state =>
  state?.app?.toastMessage

export const textToSpeechEnabled = state =>
  state?.app?.organisationSettings?.textToSpeechEnabled ?? false

export const pushAction = state =>
  state?.app?.pushAction

export const tfaCodeRequired = state =>
  state?.app?.tfaCodeRequired

export const appDictionary = state =>
  state?.app?.dictionary

export const selectedLanguage = state =>
  state?.app?.selectedLanguage