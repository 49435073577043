import {connect} from 'react-redux'
import {Profile} from './profile'
import {
  getProfile,
  getSupporters,
  removeProfileVideo,
  saveProfileVideo,
  downloadingProfile,
  selectedProfile,
  supporters,
} from './state'
import {selectedJourney, selectedJourneyAvatar} from '../state'
import {appDictionary, setBusyIndicator, showAlertMessage} from "../../app"
import {userDetails} from "../../user-management";

const mapStateToProps = (state) => ({
  profile: selectedProfile(state),
  selectedJourney: selectedJourney(state),
  downloadingProfile: downloadingProfile(state),
  supporters: supporters(state),
  avatar: selectedJourneyAvatar(state),
  user: userDetails(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  startUp: async (profileId) => {
    Promise.all([
      dispatch(getProfile(profileId)),
      dispatch(getSupporters(profileId))
    ])
  },
  saveProfileVideo: (profileId, video, dictionary) => dispatch(saveProfileVideo(profileId, video, dictionary)),
  removeProfileVideo: (profileId, dictionary) => dispatch(removeProfileVideo(profileId, dictionary)),
  setBusyIndicator: (busy) => dispatch(setBusyIndicator(busy)),
  showAlertMessage: (msg) =>dispatch(showAlertMessage(msg))
})

export const ProfileConnected = connect(mapStateToProps, mapDispatchToProps)(Profile)