import {ModalTemplate} from "../../../../components";
import {ActionList} from '../../../user-actions'
import React from "react";
import styles from './styles.module.scss'

export const GoalActions = ({
                              isOpen,
                              close,
                              goal,
                              parent,
                              dictionary,
                            }) => {
  return (
    
      <ModalTemplate isOpen={isOpen}
                    title={dictionary.goal_actions}
                    closeMethod={()=>{close()}}
                    parent={parent}>
        <div className={styles.goal_action_modal}>
          <ActionList goal={goal} />
        </div>
      </ModalTemplate>
    
  )
}