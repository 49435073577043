export const SET_BUSY = 'SET_BUSY'
export const SET_DOWNLOADING = 'SET_DOWNLOADING'
export const SET_NETWORK_STATUS = 'SET_NETWORK_STATUS'
export const SET_ORGANISATION_SELECTED = 'SET_ORGANISATION_SELECTED'
export const SET_VALID_LOGIN_TOKEN = 'SET_VALID_LOGIN_TOKEN'
export const SET_APP_INITIALISED = 'SET_APP_INITIALISED'
export const SET_OPEN_MODAL = 'SET_OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const SET_GROUP_ACTION = 'SET_GROUP_ACTION'
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE'
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE'
export const SET_PUSH_ACTION = 'SET_PUSH_ACTION'
export const SET_VERSION_NUMBER = 'SET_VERSION_NUMBER'
export const SET_ORGANISATION_SETTINGS = 'SET_ORGANISATION_SETTINGS'
export const SET_TFA_CODE_REQUIRED = 'SET_TFA_CODE_REQUIRED'
export const SET_APP_LANGUAGE = 'SET_APP_LANGUAGE'

export const setVersionNumber = (versionNumber) => ({
  type: SET_VERSION_NUMBER,
  payload: versionNumber
})

export const setBusy = (busy) =>({
  type: SET_BUSY,
  payload: busy
})

export const setDownloading = (downloading) =>({
  type: SET_DOWNLOADING,
  payload: downloading
})

export const setNetworkStatus = (status) => ({
  type: SET_NETWORK_STATUS,
  payload: status
})

export const setOrganisationSelected = (loaded) => ({
  type: SET_ORGANISATION_SELECTED,
  payload: loaded
})

export const setValidLoginToken = (valid) => ({
  type: SET_VALID_LOGIN_TOKEN,
  payload: valid
})

export const setAppInitialised = (initialised) => ({
  type: SET_APP_INITIALISED,
  payload: initialised
})

export const setOpenModal = (modal, modalData) => ({
  type: SET_OPEN_MODAL,
  payload: { modal: modal, modalData: modalData }
})

export const closeModal = () => ({
  type: CLOSE_MODAL
})

export const setGroupAction = (action, args, uid) => ({
  type: SET_GROUP_ACTION,
  payload: {
    action: action,
    args: args,
    uid: uid
  }
})

export const setAlertMessage = (msg) => ({
  type: SET_ALERT_MESSAGE,
  payload: msg
})

export const setToastMessage = (message, colour='success') => ({
  type: SET_TOAST_MESSAGE,
  payload: {
    message,
    colour
  }
})

export const setPushAction = (action, data) => ({
  type: SET_PUSH_ACTION,
  payload: {
    action,
    data
  }
})

export const setOrganisationSettings = (settings) => ({
  type: SET_ORGANISATION_SETTINGS,
  payload: settings
})

export const setTfaCodeRequired = (required) => ({
  type: SET_TFA_CODE_REQUIRED,
  payload: required
})

export const setAppLanguage = (languageSettings) => ({
  type: SET_APP_LANGUAGE,
  payload: languageSettings
})
