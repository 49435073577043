import styles from './styles.module.scss'
import React from "react"

export const RatingBar = ({rating}) => {

  let percent = rating.rating * 10

  return (
    <div className={styles.rating_bar_wrapper}>

      <div className={styles.rating_bar_details}>
        <b><span className={styles.rating_bar_score}><span className={styles.rating_bar_score_amount}>{rating.rating}</span><span className={styles.rating_bar_score_total}>/10</span></span></b>
        <span className={styles.rating_bar_title}>{rating.title}</span>
      </div>

      <div className={styles.progress_div}>
        <div style={{width: `${percent}%`, backgroundColor: (rating.rating > 8) ? '#a8c70e' : (rating.rating > 3) ? '#f08105' : '#de2d2c'}} className={styles.progress} />
      </div>
    </div>
  )
}