import {IonInput, IonItem, IonTextarea, IonCard, IonCardContent} from "@ionic/react";
import {ModalTemplate, ValidationMessage} from "../../../../components"
import React, {useEffect, useMemo, useState} from "react";
import styles from './styles.module.scss'

export const GoalEdit = ({
                           isOpen,
                           close,
                           goal,
                           saveGoal,
                           parent,
                           dictionary,
                         }) => {

  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)
  const [validationMessage, setValidationMessage] = useState(null)

  useEffect(() => {
    if (goal){
      setTitle(goal.title)
      setDescription(goal.description)
    }
  }, [goal])

  const onSave = () => {
    if (!isValid){
      setValidationMessage(dictionary.add_goal_title)
      return false
    }

    saveGoal({
      ...goal,
      title: title,
      description: description
    })

    close()
  }

  const isValid = useMemo(() => {
    return Boolean(title) && Boolean(description)
  }, [title, description])

  return (
    <ModalTemplate isOpen={isOpen}
                   title={goal?.id ? dictionary.edit_goal : dictionary.add_goal}
                   closeMethod={()=>{close()}}
                   saveMethod={onSave}
                   parent={parent}>
        <IonCard>
          <IonCardContent className={styles.cardpadless}>
            <IonItem lines='none'>
              <IonInput
                placeholder={dictionary.title}
                value={title}
                onIonChange={(e) => {
                  setTitle(e.target.value)
                }}
              />
            </IonItem>
            <IonItem lines='none'>
              <IonTextarea
                placeholder={dictionary.goal_description}
                rows='10'
                value={description}
                onIonChange={(e) => {
                  setDescription(e.target.value)
                }}
              />
            </IonItem>

          </IonCardContent>
        </IonCard>

      {validationMessage && <ValidationMessage validationMessage={validationMessage} clearMessage={setValidationMessage} /> }

    </ModalTemplate>
  )
}