import { get, post, deleteById } from './api'

export const downloadDeviceTokens = async (userId) => {
  return await get("/device_token/user", `user_id=${userId}`)
}

export const deleteCurrentToken = async () => {
  const currentToken = JSON.parse(localStorage.getItem('device_token'))

  if(!currentToken){
    return true
  }

  localStorage.removeItem('device_token')

  return await deleteById("/device_token/", currentToken.id)
}

export const uploadSavedToken = async () => {
  const currentToken = JSON.parse(localStorage.getItem('device_token'))

  if(!currentToken){
    return true
  }

}

export const deleteToken = async (tokenId) => {
  return await deleteById("/device_token/", tokenId)
}

export const uploadToken = async (userId, token, platform) => {

  const upload = {
    "token":[{"value": token}],
    "uid":[{"target_id": userId}],
    "platform":[{"value": platform}]
  }

  let response = await post("/entity/device_token/", upload)

  if(response.message){
    alert(response.message)
    return null
  }

  return response
}