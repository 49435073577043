import {connect}  from 'react-redux'
import {ActionEdit } from './action-edit'
import {saveAction} from '../../state'
import {userId} from '../../../../user-management'
import {selectedJourney, viewingOwnJourney} from '../../../state'
import {appDictionary, getOpenModal, getOpenModalData} from "../../../../app"
import {ACTION_EDIT} from '../../../../constants/modals'
import {dismissModal} from "../../../../app";
import {goals, downloadGoals} from '../../../goal'

const mapStateToProps = (state) => ({
  goals: goals(state),
  userId: userId(state),
  selectedAction: getOpenModalData(state),
  selectedJourney: selectedJourney(state),
  isOpen: getOpenModal(state, ACTION_EDIT),
  viewingOwnJourney: viewingOwnJourney(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(dismissModal()),
  saveAction: (action) => dispatch(saveAction(action)),
  downloadGoals: (profileId) => dispatch(downloadGoals(profileId)),
})

export const ActionEditConnected = connect(mapStateToProps, mapDispatchToProps)(ActionEdit)