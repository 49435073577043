import {ModalTemplate, ValidationMessage} from "../../../components";
import {
  IonAlert,
  IonCard,
  IonCardContent,
  IonTextarea,
} from "@ionic/react";
import React, {useMemo, useState} from "react";
import styles from './styles.module.scss'
import * as constants from '../../constants'

export const ReportPost = ({ isOpen,
                             close,
                             post,
                             reportPost,
                             reportedStatus,
                             dismissAlert,
                             parent,
                             dictionary,
                           }) => {

  const [reportText, setReportText] = useState(null)
  const [validationMessage, setValidationMessage] = useState(null)

  const {REPORTED_STATUS_REPORTED, REPORTED_STATUS_FAILED} = constants

  const onSave = () => {
    if (!isValid){
      setValidationMessage(dictionary.report_post_info_request)
      return false
    }

    reportPost(post, reportText)
  }

  const isValid = useMemo(() => {
    return Boolean(reportText)
  }, [reportText])

  return (
    <ModalTemplate isOpen={isOpen}
                   title={dictionary.report_post_title}
                   closeMethod={ () => {close() }}
                   saveMethod={onSave}
                   parent={parent}>

      <IonCard>
        <IonCardContent>
          <p>{post && post.post_text}</p>


        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardContent>
          <p>{dictionary.report_post_placeholder}</p>
          <IonTextarea placeholder={''}
                       value={reportText}
                       spellcheck={true}
                       autocapitalize='sentences'
                       className={styles.post_text}
                       onIonChange={
                         (e)=>{
                           setReportText(e.target.value)
                         }
                       }
          />

        </IonCardContent>
      </IonCard>

      <IonAlert
        isOpen={reportedStatus === REPORTED_STATUS_REPORTED}
        onDidDismiss={() => {
          setReportText(null)
          setValidationMessage(null)
          dismissAlert(true)
        }}
        header={dictionary.alert}
        message={dictionary.report_post_thanks}
        buttons={[dictionary.ok]}
      />

      <IonAlert
        isOpen={reportedStatus === REPORTED_STATUS_FAILED}
        onDidDismiss={() => {
          dismissAlert(false)
        }}
        header={dictionary.alert}
        message={dictionary.report_post_problem}
        buttons={['OK']}
      />

      {validationMessage && <ValidationMessage validationMessage={validationMessage} clearMessage={setValidationMessage} /> }

    </ModalTemplate>
  )
}