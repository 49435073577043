import {downloadDetails, uploadDetails, login as userLogin, logout, uploadProfilePicture} from "../utils/api/user"
import {
  setLoginDetails,
  setUserDetails,
  clearUserData,
  setLoginMessageSuccess,
} from './action-creators'
import {setValidLoginToken} from '../app'
import {deleteCurrentToken} from "../utils/api/push-notifications";
import {
  clearJourneys,
  setAvatars,
  clearGoals,
  clearMeetings,
  clearStory,
  clearActions,
  clearProfile,
  clearMultiFeed,
} from "../user-journey"
import {initialiseApp} from "../app/logic";
import {setBusyIndicator} from "../app";
import {takePicture} from "../utils/functions/camera";
import {setTfaCodeRequired, setToastMessage} from "../app/action-types"

export const resetApp = () => {
  return dispatch => {
    localStorage.clear()
    dispatch(initialiseApp())
  }
}

export const doLogin = (username, password, dictionary) => {
  return async dispatch => {
    dispatch(setBusyIndicator(true))

    if (!username || !password){
      dispatch(setLoginMessage(dictionary.enter_credentials))
      dispatch(setBusyIndicator(false))
      return false
    }

    let loginResult = await userLogin(username, password, dispatch)
    loginResult.success ? await dispatch(initialiseApp()) : dispatch(setLoginMessage(loginResult.data.message))

    dispatch(setBusyIndicator(false))

    return loginResult.success
  };
}

export function setLoginMessage(message) {
  return dispatch =>{
    dispatch(setLoginMessageSuccess(message))
  }
}

export const loggedIn = () => {
  // alert(localStorage.getItem('isLoggedIn'))
  return localStorage.getItem('isLoggedIn')
}

export const downloadUserDetails = (showBusyIndicator = false) => {
  return async (dispatch) => {
    if (showBusyIndicator) dispatch(setBusyIndicator(true))

    let userDetails = await downloadDetails(dispatch)

    if(userDetails !== null){
      dispatch(setUserDetails(userDetails))
      // localStorage.setItem('userDetails', JSON.stringify(response[0]))
    }

    if (showBusyIndicator) dispatch(setBusyIndicator(false))
  }
}

export const updateUserDetails = (userDetails, dictionary) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const success = await uploadDetails(userDetails)
    dispatch(downloadUserDetails())

    success
      ? dispatch(setToastMessage(dictionary.preferences_saved))
      : dispatch(setToastMessage(dictionary.preferences_not_saved, 'danger'))

    dispatch(setBusyIndicator(false))
  }
}

export const loadUser = () => {
  return (dispatch) => {
    const loginDetails = localStorage.getItem('loginDetails')

    if(loginDetails){
      dispatch(setLoginDetails(JSON.parse(loginDetails)))
    }

  }
}

export function doLogout() {
  return async (dispatch) => {

    // This must be done before the user is logged out because
    // anonymous users don't have permission to delete tokens
    await deleteCurrentToken()

    const logoutSuccess = await logout()

    if(!logoutSuccess){
      return
    }

    dispatch(setTfaCodeRequired(false))
    dispatch(clearUserData())
    dispatch(clearStory())
    dispatch(clearGoals())
    dispatch(clearMeetings())
    dispatch(clearActions())
    dispatch(clearProfile())
    dispatch(clearMultiFeed())
    dispatch(clearJourneys())
    dispatch(setValidLoginToken(false))

    localStorage.setItem('selectedJourney', JSON.stringify({}))
    localStorage.setItem('avatars', JSON.stringify([]))
    localStorage.setItem('goals', JSON.stringify([]))
    localStorage.setItem('meetings', JSON.stringify([]))
    localStorage.setItem('actions', JSON.stringify([]))

  };
}

export const changeProfilePic = (uid) => {
  return async (dispatch) => {
    const encodedImage = await takePicture()

    if (!encodedImage){
      return false
    }

    dispatch(setBusyIndicator(true))
    const succeeded = await uploadProfilePicture(encodedImage, dispatch)
    dispatch(setBusyIndicator(false))

    if (!succeeded){
      return false
    }

    const now = Date.now()

    const profileAvatar = {
      uid: uid,
      user_picture: `data:image/${encodedImage.format};base64,${encodedImage.base64String}`,
      download_date: now,
      checked_date: now
    }

    dispatch(setAvatars([profileAvatar]))

    return true
  }
}


