import {setDownloadingIndicator, updateGroupActionProps} from "../../../app"
import {getMultiFeed} from '../../../utils/api/journey'
import {downloadPostAvatars} from "../../index"
import {
  dispatchMultiFeedPosts,
  dispatchSelectedMultiFeedPostId,
  dispatchMultiFeedReply,
  dispatchMultiFeedPost,
  resetState,
} from './actions'

export const downloadMultiFeedPosts = () => {
  return async (dispatch) => {
    dispatch(setDownloadingIndicator(true))

    const posts = await getMultiFeed()

    await dispatch(setMultiFeedPosts(posts))

    dispatch(downloadPostAvatars(posts))

    dispatch(setDownloadingIndicator(false))
  }
}

export const setMultiFeedPosts = (posts) => {
  return (dispatch) => {
    dispatch(dispatchMultiFeedPosts(posts))
  }
}

export const selectMultiFeedPost = (postId) => {
  return async (dispatch) => {
    dispatch(dispatchSelectedMultiFeedPostId(postId))
    if (postId === null){
      dispatch(updateGroupActionProps(null, null, null))
    }
  }
}

export const refreshMultiFeedPost = (post) => {
  return (dispatch) =>
    parseInt(post?.is_reply) === 1 ? dispatch(dispatchMultiFeedReply(post)) : dispatch(dispatchMultiFeedPost(post))
}

export const clearMultiFeed = () => {
  return (dispatch) => dispatch(resetState())
}

