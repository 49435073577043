import {connect} from 'react-redux'
import {GoalList} from "./goal-list"
import {downloadGoalList, goalList, clearGoalList} from '../../../../story'
import {appDictionary} from "../../../../../app"

const mapStateToProps = (state) => ({
  goalList: goalList(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadGoalList: (post) => dispatch(downloadGoalList(post)),
  clearGoalList: () => dispatch(clearGoalList()),
})

export const GoalListConnected = connect(mapStateToProps, mapDispatchToProps)(GoalList)

