import React, {useRef} from 'react';
import { Route } from 'react-router-dom'
import { IonRouterOutlet} from '@ionic/react'
import { IonTabs, IonTabBar, IonTabButton, IonIcon } from '@ionic/react';
import { Story, UserProfile, Goal, UserActions, Meetings } from '../../user-journey'
import {
  readerOutline, podiumOutline, calendarNumberOutline, checkboxOutline, personOutline
} from 'ionicons/icons'
import styles from './styles.module.scss'

export const TabBar = ({permissions, viewingOwnJourney, dictionary}) => {

  const tabRouter = useRef(null)

  return(
      <IonTabs>
        <IonRouterOutlet ref={tabRouter}>
          <Route exact={true} path='/journey/story' render={() => <Story router={tabRouter.current} />} />
          <Route exact={true} path='/journey/goals' render={() => <Goal router={tabRouter.current} />} />
          <Route exact={true} path='/journey/actions' render={() => <UserActions router={tabRouter.current} />} />
          <Route exact={true} path='/journey/meetings' render={() => <Meetings router={tabRouter.current} />} />
          <Route exact={true} path='/journey/profile' render={() => <UserProfile router={tabRouter.current} />} />
        </IonRouterOutlet>
        
        <IonTabBar slot="bottom" className={styles.tab_bar}>
            <IonTabButton tab="story" href="/journey/story" className={styles.tab_button}>
              <IonIcon icon={readerOutline} /> {dictionary.story}
            </IonTabButton>

            { (viewingOwnJourney || parseInt(permissions?.view_goals) === 1) &&
              <IonTabButton tab="goals" href="/journey/goals" className={styles.tab_button}>
                <IonIcon icon={podiumOutline} /> {dictionary.goals}
              </IonTabButton>
            }

            { (viewingOwnJourney || parseInt(permissions?.view_actions) === 1) &&
              <IonTabButton tab="actions" href="/journey/actions" className={styles.tab_button}>
                <IonIcon icon={checkboxOutline} /> {dictionary.actions}
              </IonTabButton>
            }

            {(viewingOwnJourney || parseInt(permissions?.view_meetings) === 1) &&
              <IonTabButton tab="meetings" href="/journey/meetings" className={styles.tab_button}>
                <IonIcon icon={calendarNumberOutline} /> {dictionary.meetings}
              </IonTabButton>
            }

            <IonTabButton tab="profile" href="/journey/profile" className={styles.tab_button}>
              <IonIcon icon={personOutline} /> {dictionary.profile}
            </IonTabButton>
        </IonTabBar>
      </IonTabs>
  )
}