import {
  IonInput,
  IonLabel,
  IonModal,
} from "@ionic/react";
import React, {useEffect, useState, useRef} from "react";
import styles from './goup-action.module.scss'

export const GroupAction = ({
                              skipPinCheck,
                              checkPin,
                              action,
                              cancelAction,
                              args,
                              groupAccountUid,
                              isGroupAccount,
                            }) => {

  const [isOpen, setIsOpen] = useState(false)
  const inputRef = useRef(null);

  useEffect(() => {
    if (!isGroupAccount){
      if (action) skipPinCheck(action, args)
    }
    else{
      setIsOpen(Boolean(action))
    }
  }, [action, args, isGroupAccount, skipPinCheck])

  const focusElement = async(ref) => {
    if (ref) {
      const el = await ref.getInputElement();
      el.focus();
    }
  }

  return (
    <>
      <IonModal isOpen={isOpen}
                cssClass={styles.group_action}
                backdropDismiss={true}
                onDidDismiss={()=>{if(!groupAccountUid) cancelAction()}}
                onDidPresent={() => focusElement(inputRef.current)}
                initialBreakpoint={0.25}
                breakpoints={[0, 0.25]}
                animated={true}>

        <IonLabel>
          Please enter your PIN to continue
          <IonInput
            type="tel"
            maxlength="4"
            ref={inputRef}
            className={styles.pin}
            onIonChange={(e) => {
              if (e.target.value?.length === 4){
                checkPin(e.target.value, action, args)
                e.target.value = null
              }
            }}
          />
        </IonLabel>
      </IonModal>
    </>
  )
}