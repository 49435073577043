import {useEffect} from "react"
import {useHistory} from "react-router-dom"
import {
  listenForNotifications,
  registerDevice,
  handleNewPost,
  handleNewReply,
  handleReminder
} from './logic'

export const PushNotificationHandler = ({
                                          userId,
                                          setPushAction,
                                          pushAction,
                                          journeys,
                                          loadJourney,
                                          ownJourney,
                                          openModal,
                                        }) => {
  const history = useHistory()

  useEffect(() => {
    listenForNotifications(setPushAction)
  }, [setPushAction])

  useEffect(() => {
    registerDevice(userId)
  }, [userId])

  useEffect(() => {

    const action = pushAction?.action
    const data = pushAction?.data

    switch (action) {
      case 'story_post':
        handleNewPost(journeys, loadJourney, data?.dataId1, userId)
        break;

      case 'story_reply':
        handleNewReply(journeys, loadJourney, data?.dataId1, userId, openModal, data?.dataId2)
        break;

      case 'action_reminder':
        handleReminder(loadJourney, ownJourney, userId, data?.dataId1, openModal, history)
        break;

      default:
        console.log('Unknown alert type')
    }
  }, [pushAction, journeys, userId, loadJourney, openModal, ownJourney, history])

  return <></>
}