import {ACTION_EDIT} from "../../../../constants/modals";
import {IonActionSheet} from "@ionic/react";
import React, {useMemo} from "react";
import cloneDeep from 'lodash/cloneDeep'

export const Options = ({
                          selectedAction,
                          setSelectedAction,
                          openModal,
                          setActionForDeletion,
                          markAsComplete,
                          userId,
                          viewingOwnJourney,
                          dictionary,
                        }) => {

  const canDeleteAction = useMemo(() => {
    return !selectedAction?.id || parseInt(selectedAction?.created_by_id) === parseInt(userId)
  }, [selectedAction, userId])

  const getButtons = useMemo(() => {
    let buttons = [
      {
        text: dictionary.open,
        role: 'destructive',
        handler: () => { openModal(ACTION_EDIT, cloneDeep(selectedAction)) }
      }
    ]

    viewingOwnJourney && buttons.push(
      {
        text:  selectedAction?.complete ? dictionary.mark_incomplete : dictionary.mark_complete,
        role: 'destructive',
        handler: () => {markAsComplete(selectedAction.id, selectedAction.complete !== true)}
      }
    )

    canDeleteAction && buttons.push(
      {
        text: dictionary.delete,
        role: 'destructive',
        handler: () => {setActionForDeletion(selectedAction)}
      }
    )

    buttons.push(
      {
        text: dictionary.cancel,
        role: 'cancel',
      }
    )

    return buttons
  }, [selectedAction, canDeleteAction, markAsComplete, openModal, setActionForDeletion, viewingOwnJourney, dictionary])

  return (
    <IonActionSheet
      isOpen={Boolean(selectedAction)}
      onDidDismiss={() => setSelectedAction(null)}
      buttons={getButtons}
    >
    </IonActionSheet>
  )
}