import {setBusyIndicator} from "../../../app";
import {downloadGoals as doDownload, uploadGoal, downloadGoalRatings} from '../../../utils/api/journey'
import {setDownloadingGoals, setGoals, setSaveGoal, setShowGoalActions, updateGoal, resetState} from "./actions"

export const downloadGoals = (profileId) => {
  return async (dispatch) => {
    dispatch(setDownloadingGoals(true))

    const goals = await doDownload(profileId, dispatch)
    const ratings = await downloadGoalRatings(profileId, dispatch)

    goals?.forEach((goal) => {
      const selectedRatings = ratings.filter(r => parseInt(r.goal_reference) === parseInt(goal.id))

      goal.rating = selectedRatings?.length ? parseInt(selectedRatings[0]?.rating) : 0
      goal.ratings = selectedRatings
    })

    dispatch(setGoals(goals))
    dispatch(setDownloadingGoals(false))
  }
}

export const saveGoal = (goal) => {
  return (dispatch) => {
    dispatch(setBusyIndicator(true))

    return uploadGoal(goal, dispatch).then(
      (success) =>{
        if(success){
          dispatch(setSaveGoal())
          dispatch(downloadGoals(goal.profile_id))
        }

        dispatch(setBusyIndicator(false))
      }
    )
  }
}

export const toggleGoalActionsModal = (goal, visible) => {
  return (dispatch) => {
    dispatch(setShowGoalActions(goal, visible))
  }
}

export const deleteGoalAndRefresh = (goal) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    goal.deleted = true

    if (await uploadGoal(goal, dispatch)){
      dispatch(updateGoal(goal))
    }

    dispatch(setBusyIndicator(false))
  }
}

export const clearGoals = () => {
  return (dispatch) => dispatch(resetState())
}

