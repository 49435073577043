import {DefaultTemplate, LanguageSelect} from "../../components";
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {
  IonAvatar,
  IonButton,
  IonIcon, IonToggle, IonLabel, IonItem, IonList
} from "@ionic/react"
import styles from './styles.module.scss'
import {createOutline, lockClosedOutline, logOutOutline} from "ionicons/icons";

export const UserAccount = ({
                              userDetails,
                              logout,
                              changeProfilePic,
                              avatars,
                              selectedLanguage,
                              selectLanguage,
                              dictionary,
                              saveUserDetails,
                              downloadUserDetails,
                            }) => {

  const [isDirty, setIsDirty] = useState(false)
  const [allowNotifications, setAllowNotifications] = useState(false)

  // useEffect(() => {
  //   downloadUserDetails()
  // }, [downloadUserDetails])

  useEffect(() =>{
    if (userDetails) {
      setAllowNotifications(!!parseInt(userDetails?.alerts_meeting))
    }
  }, [userDetails])

  const avatar = useMemo(() => {
    if (!userDetails?.uid) {
      return null
    }
    return avatars?.find(a => parseInt(a.uid) === parseInt(userDetails.uid))?.user_picture
  }, [avatars, userDetails])

  const updateMeetingAlertPreference = useCallback((allow) => {
    setAllowNotifications(allow)
    setIsDirty(!!parseInt(userDetails?.alerts_meeting) !== allow)
  }, [setAllowNotifications, setIsDirty, userDetails?.alerts_meeting])

  const save =  useCallback(async () => {
    await saveUserDetails({...userDetails, alerts_meeting: allowNotifications}, dictionary)
    setIsDirty(false)
  }, [saveUserDetails, userDetails, allowNotifications, dictionary])

  return (
      <DefaultTemplate
        pageTitle={dictionary.account}
        dictionary={dictionary}
        refreshFunction={() => downloadUserDetails()}>

        <div className={styles.pic_container}>

          <div className={styles.avatar_container}>
            <IonAvatar className={styles.avatar}>
              <img src={avatar} alt="" />
            </IonAvatar>
          </div>

          <div className={styles.edit_avatar}>
            <IonIcon
              icon={createOutline}
              onClick={()=>changeProfilePic(userDetails?.uid)}/>
          </div>

        </div>

        <div className={styles.account_details}>
          <h1>{userDetails?.display_name}</h1>
        </div>

        <div className={styles.settings_container}>
          <IonList lines='full' inset='false'>
            <ion-list-header>
                <ion-label>{dictionary.preferences}</ion-label>
                <IonButton
              disabled={!isDirty}
              expand="block"
              onClick={() => { save() }}>{dictionary.save}</IonButton>
            </ion-list-header>

            <LanguageSelect selectedLanguage={selectedLanguage} selectLanguage={selectLanguage} dictionary={dictionary} />

            <IonItem>
              <IonLabel>Receive meeting reminders</IonLabel>
              <IonToggle color="primary"
                         checked={allowNotifications}
                         onIonChange={(e) => updateMeetingAlertPreference(e.detail.checked)} />
            </IonItem>
            <IonItem button onClick={() => { alert('!') }}>
              {dictionary.change_password}
              <IonIcon icon={lockClosedOutline} slot='end' />
            </IonItem>
            <IonItem button onClick={() => { logout() }}>
              {dictionary.log_out}
              <IonIcon icon={logOutOutline} slot='end' />
            </IonItem>
          </IonList>

        </div>

      </DefaultTemplate>
  );
}