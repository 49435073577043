import {Redirect, Route} from 'react-router-dom'
import React, {useEffect, useMemo, useRef} from 'react'
import {IonAlert, IonApp, IonLoading, IonRouterOutlet, IonToast } from '@ionic/react'
import {IonReactRouter} from '@ionic/react-router'
import {UserJourney} from '../../user-journey'
import './styles.scss'
import {OrganisationAccessCode, TfaAccessCode} from '../components/access-codes'
import {Menu} from '../../components'
import {UserAccount, Supporting, Login} from '../../user-management'
import {NetworkStatus} from '../network-status'
import {PushNotificationHandler} from "../push-notification-handler";
import {GroupAction} from '../../components/group-action'
import { PrivateRoute } from "../routes";
import { getStartPage } from '../logic'
import {About} from '../../pages'
import {MultiFeed} from '../../user-journey/multi-feed'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';

export const App = ({ startup,
                      appBusy,
                      selectedJourney,
                      journeys,
                      organisationSelected,
                      validLoginToken,
                      appInitialised,
                      alertMessage,
                      toastMessage,
                      closeAlert,
                      clearToast,
                      dictionary,
                    }) => {

  const mainRouter = useRef(null)

  useEffect(() =>{
    (async () => await startup())()
  }, [startup])

  const startPage = useMemo(() => {
    return getStartPage(
      organisationSelected,
      validLoginToken,
      journeys,
      selectedJourney
    )
  }, [organisationSelected, validLoginToken, journeys, selectedJourney])

  return (
    <IonApp>
      <>
        <NetworkStatus />
        <IonLoading isOpen={appBusy} />
        {
          !appInitialised
          ?
            <></>
          :
            <IonReactRouter>
              <Menu/>
              <GroupAction />
              <PushNotificationHandler />
              <TfaAccessCode />
              <IonAlert
                isOpen={Boolean(alertMessage)}
                header={dictionary.alert}
                message={alertMessage}
                buttons={[dictionary.ok]}
                onDidDismiss={() => {
                  closeAlert()
                }}
                animated={true}
              />

              <IonToast
                isOpen={Boolean(toastMessage?.message)}
                message={toastMessage?.message}
                duration='2000'
                onDidDismiss={() => {clearToast()}}
                position='bottom'
                color={toastMessage?.colour}
                buttons={[dictionary.ok]}
              />

              <IonRouterOutlet ref={mainRouter} id="main">
                <PrivateRoute authed={validLoginToken}
                              path='/user/account'
                              component={UserAccount} />

                <PrivateRoute authed={validLoginToken}
                              path='/user/supporting'
                              component={Supporting} />

                <PrivateRoute authed={validLoginToken}
                              path='/journey'
                              component={UserJourney} />

                <PrivateRoute authed={validLoginToken}
                              path='/multi-feed'
                              component={MultiFeed}
                              router={mainRouter.current}/>

                <PrivateRoute authed={validLoginToken}
                              path='/app/about'
                              component={About} />

                <Route path='/access'
                       render={() =>
                         organisationSelected
                           ?
                           <Redirect from="/access" to={startPage} exact/>
                           :
                           <OrganisationAccessCode />
                       }
                />

                <Route path='/login'
                       render={() =>
                         validLoginToken
                           ?
                           <Redirect from="/login" to={startPage} exact/>
                           :
                           organisationSelected ? <Login/> : <Redirect from="/login" to='/access' exact/>
                       }
                />

                <Redirect from="/" to={startPage} exact />

              </IonRouterOutlet>

            </IonReactRouter>
        }
      </>
    </IonApp>
  )
}

