import {
  SET_ACTIONS,
  SET_SELECTED_ACTION,
  SET_SHOW_COMPLETE_ACTIONS,
  SET_DOWNLOADING_ACTIONS,
  SET_ACTION_COMPLETE,
  UPDATE_ACTION,
  RESET_STATE,
} from './action-types'

import {mergeArray} from '../../../utils/helpers/array'

const initialState = {}

export const userActionReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_DOWNLOADING_ACTIONS:
      return { ...state,
        downloadingActions: action.payload
      }

    case SET_ACTIONS:
      return { ...state,
        actions: action.payload && action.payload.length > 0 ? [...action.payload] : [],
      }

    case SET_SELECTED_ACTION:
      return {...state,
        selectedAction: action.payload
      }

    case SET_ACTION_COMPLETE:

      // let actions = state.actions
      let completedAction = state.actions.find((a) => parseInt(a.id) === parseInt(action.payload.actionId))

      if(completedAction){
        completedAction.complete = action.payload.complete
      }

      return { ...state,
        actions: [...state.actions]
      }

    case SET_SHOW_COMPLETE_ACTIONS:
      return { ...state,
        showCompleteActions: action.payload
      }

    case UPDATE_ACTION:
      return { ...state,
        actions: mergeArray(state?.actions, action.payload)
      }

    case RESET_STATE:
      return {
        ...initialState
      }

    default:
      return state
  }
}