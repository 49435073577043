import {connect} from 'react-redux'
import {PostEdit} from './post-edit'
import {downloadGoals, activeGoals} from '../../goal'
import {viewingOwnJourney, selectedJourney, selectedJourneyAvatar, usersJourneyPermissions} from '../../state'
import {refreshPostMedia, savePost} from "../../story"
import {appDictionary, groupActionUserId} from "../../../app"
import {setBusyIndicator} from "../../../app"

const mapStateToProps = (state) => ({
  selectedJourney: selectedJourney(state),
  goals: activeGoals(state),
  viewingOwnJourney: viewingOwnJourney(state),
  groupActionUserId: groupActionUserId(state),
  avatar: selectedJourneyAvatar(state),
  dictionary: appDictionary(state),
  journeyPermissions: usersJourneyPermissions(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadGoals: (profileId) => dispatch(downloadGoals(profileId)),
  refreshMedia: (post) => dispatch(refreshPostMedia(post)),
  setBusyIndicator: (busy) => dispatch(setBusyIndicator(busy)),
  savePost: async (post, postSaveActions) => {
    const savedPost = await dispatch(savePost(post))
    if (savedPost && postSaveActions) {
      postSaveActions(savedPost)
    }
  },
})

export const PostEditConnected = connect(mapStateToProps, mapDispatchToProps)(PostEdit)