import {IonAlert} from "@ionic/react";
import React from "react";

export const ConfirmationBox = ({
                                  isOpen,
                                  message,
                                  handler,
                                  onDismiss,
                                  header,
                                  dictionary,
                                }) =>{
  return (
    <IonAlert
      isOpen={isOpen}
      header={header ?? dictionary.confirm}
      message={message}
      onDidDismiss={onDismiss}
      buttons={[
        {
          text: dictionary.no,
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: dictionary.yes,
          handler: handler
        }
      ]}
    />
  )
}