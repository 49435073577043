import {connect} from 'react-redux'
import {appDictionary, getOpenModal, getOpenModalData} from "../../../../app"
import {dismissModal} from "../../../../app";
import {GOAL_ACTIONS} from '../../../../constants/modals'
import {GoalActions} from "./goal-actions";

const mapStateToProps = (state) => ({
  isOpen: getOpenModal(state, GOAL_ACTIONS),
  goal: getOpenModalData(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(dismissModal()),
})

export const GoalActionsConnected = connect(mapStateToProps, mapDispatchToProps)(GoalActions)