import {IonActionSheet} from "@ionic/react";
import React, {useMemo} from "react";
import {
  ACTION_EDIT,
  GOAL_ACTIONS,
  GOAL_EDIT,
  GOAL_RATINGS,
} from "../../../../constants/modals";
import cloneDeep from 'lodash/cloneDeep'
import {userCanAddActions, userCanViewActions} from '../../../logic'

export const Options = ({
                          journey,
                          userId,
                          selectedGoal,
                          setSelectedGoal,
                          openModal,
                          setGoalForDeletion,
                          journeyPermissions,
                          dictionary,
                        }) => {

  const getButtons = useMemo(() => {
    let buttons = []

    if(parseInt(selectedGoal?.created_by_id) === parseInt(userId)){
      buttons.push(
        {
          text: dictionary.open,
          role: 'destructive',
          handler: () => {openModal(GOAL_EDIT, cloneDeep(selectedGoal))}
        }
      )
      buttons.push(
        {
          text: dictionary.delete,
          role: 'destructive',
          handler: () => {setGoalForDeletion(selectedGoal)}
        }
      )
    }

    buttons.push(
      {
        text: dictionary.view_progress,
        role: 'destructive',
        handler: () => {openModal(GOAL_RATINGS, selectedGoal)}
      },
      {
        text: dictionary.cancel,
        role: 'cancel'
      }
    )

    if(userCanViewActions(journey, journeyPermissions, userId)){
      buttons.push(
        {
          text: dictionary.view_actions,
          role: 'destructive',
          handler: () => {openModal(GOAL_ACTIONS, selectedGoal)}
        }
      )
    }

    if(userCanAddActions(journey, journeyPermissions, userId)){
      buttons.push(
        {
          text: dictionary.add_action,
          role: 'destructive',
          handler: () => {openModal(ACTION_EDIT, {profile_id: journey.id, goal_id: selectedGoal.id})}
        }
      )
    }
    return buttons
  }, [journey, openModal, selectedGoal, setGoalForDeletion, userId, journeyPermissions, dictionary])

  return (
    <IonActionSheet
      isOpen={Boolean(selectedGoal)}
      onDidDismiss={() => setSelectedGoal(null)}
      buttons={getButtons}
    >
    </IonActionSheet>
  )
}