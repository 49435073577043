import {
  IonItem,
  IonList,
  IonLabel,
  IonToggle,
  IonListHeader,
} from "@ionic/react";
import styles from "./styles.module.scss";
import React, {useMemo, useRef, useState} from "react";
import {ConfirmationBox, DeletedItem, NoContent} from "../../../../components"
import { SkeletonText } from './skeleton-text'
import {ActionItem} from "./action-item";
import {Options} from './options'

export const ActionList = ({
                             userDetails,
                             downloadingActions,
                             actions,
                             markAsComplete,
                             goal,
                             toggleShowCompleteActions,
                             showComplete,
                             openModal,
                             deleteAction,
                             viewingOwnJourney,
                             dictionary,
                           }) => {

  const [selectedAction, setSelectedAction] = useState(null)
  const [actionForDeletion, setActionForDeletion] = useState(null)

  const slider = useRef(null)

  const actionListTitle = useMemo(() => {
    if (!actions?.length){
      return null
    }
    else{
      return goal?.title ?? dictionary.all_actions
    }
  }, [actions, goal, dictionary.all_actions])

  return (
    <>
      <SkeletonText isVisible={downloadingActions} />

      {
        !downloadingActions &&
        <>
          <IonItem>
            <IonLabel>{dictionary.show_completed_actions}</IonLabel>
            <IonToggle slot="end" checked={showComplete} onIonChange={e => toggleShowCompleteActions(e.detail.checked)} />
          </IonItem>

          <NoContent text={!actions?.length ? "You do not have any outstanding actions" : null}/>

          <IonList ref={slider} lines='full'>
            <IonListHeader className={styles.list_header}>{actionListTitle}</IonListHeader>
            {
              actions?.map((action, index) => {
                return(
                  action.deleted
                  ?
                  <DeletedItem text="This action has been deleted" />
                  :
                  <ActionItem
                    key={index}
                    action={action}
                    openModal={openModal}
                    enabled={Boolean(viewingOwnJourney)}
                    setSelectedAction={setSelectedAction}
                    markAsComplete={markAsComplete}
                    slider={slider}
                    userId={userDetails.uid}
                  />
                )
              })
            }
          </IonList>

          <ConfirmationBox
            isOpen={Boolean(actionForDeletion)}
            message={dictionary.action_delete_confirm}
            handler={() => deleteAction(actionForDeletion)}
            onDismiss={() => setActionForDeletion(null)}
          />

          <Options
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            openModal={openModal}
            setActionForDeletion={setActionForDeletion}
            markAsComplete={markAsComplete}
            userId={userDetails.uid}
            viewingOwnJourney={viewingOwnJourney}
            dictionary={dictionary}
          />
        </>
      }
    </>
  )
}