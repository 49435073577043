import {connect} from 'react-redux'
import {NewPostButton} from './new-post-button'
import {selectedJourney, selectedJourneyAvatar} from '../../../state'
import {viewingOwnJourney} from '../../../state'
import {appDictionary, updateGroupActionProps} from "../../../../app"

const mapStateToProps = (state) => ({
  selectedJourney: selectedJourney(state),
  viewingOwnJourney: viewingOwnJourney(state),
  avatar: selectedJourneyAvatar(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  addPost: () => dispatch(updateGroupActionProps('add_post', {})),
})


export const NewPostButtonConnected = connect(mapStateToProps, mapDispatchToProps)(NewPostButton)