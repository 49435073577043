import {
  SET_ACTION_COMPLETE,
  SET_ACTIONS,
  SET_DOWNLOADING_ACTIONS, SET_SELECTED_ACTION,
  SET_SHOW_COMPLETE_ACTIONS,
  UPDATE_ACTION,
  RESET_STATE,
} from "./action-types"

export const setActions = (actions) => ({
  type: SET_ACTIONS,
  payload: actions
})

export const setActionComplete = (actionId, complete) => ({
  type: SET_ACTION_COMPLETE,
  payload: { "actionId": actionId, "complete": complete }
})

export const setShowCompleteActions = (showComplete) => ({
  type: SET_SHOW_COMPLETE_ACTIONS,
  payload: showComplete
})

export const setDownloadingActions = (downloading) => ({
  type: SET_DOWNLOADING_ACTIONS,
  payload: downloading
})

export const updateAction = (action) => ({
  type: UPDATE_ACTION,
  payload: action
})

export const setSelectedAction = (action) => ({
  type: SET_SELECTED_ACTION,
  payload: action
})

export const resetState = () => ({
  type: RESET_STATE
})