import styles from './styles.module.scss'
import {IonIcon, IonThumbnail} from "@ionic/react";
import React from "react";
import {chatboxEllipsesOutline} from 'ionicons/icons'


export const NewPostButton = ({
                                selectedJourney,
                                viewingOwnJourney,
                                addPost,
                                avatar,
                                dictionary,
                              }) =>{

  return(
    <div className={styles.new_post_button} onClick={addPost}>

      <div className={styles.new_post_label}>
        <IonIcon icon={chatboxEllipsesOutline}/>
        <span>{viewingOwnJourney ? dictionary.add_to_own_story : `${dictionary.add_to_other_prefix} ${selectedJourney?.display_name}${dictionary.add_to_other_suffix}`}</span>
      </div>

      {
        avatar &&
        <IonThumbnail slot="end">
          <img src={avatar} alt={selectedJourney?.display_name} />
        </IonThumbnail>
      }

    </div>
  )
}