import * as actionTypes from "./action-types"

export const setDownloadingJourneys = (downloading) => ({
  type: actionTypes.DOWNLOADING_JOURNEYS,
  payload: downloading
})

export const setJourneys = (journeys) => ({
  type: actionTypes.SET_JOURNEYS,
  payload: journeys
});

export const setSelectedJourney = (selectedStory) => ({
  type: actionTypes.SET_SELECTED_JOURNEY,
  payload: selectedStory
})

export const resetState = () => ({
  type: actionTypes.RESET_STATE
})

export const dispatchAvatars = (avatars) => ({
  type: actionTypes.SET_AVATARS,
  payload: avatars
})

export const setJourneyPermissions = (perms) => ({
  type: actionTypes.SET_JOURNEY_PERMISSIONS,
  payload: perms
})