import {userCanAddGoals, userCanAddActions} from '../logic'

export const canAddGoals = (journey, journeyPermissions, userId) => {
  return userCanAddGoals(journey, journeyPermissions, userId)
}

export const canAddActions = (journey, journeyPermissions, userId) => {
  return userCanAddActions(journey, journeyPermissions, userId)
}

export const filterActions = (actions, goalId) => {
  return actions?.filter(a => parseInt(a.goal_id) === parseInt(goalId))
}