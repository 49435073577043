import {
  setBusyIndicator,
  showAlertMessage,
  updateGroupActionProps
} from '../../app'
import {saveReply, selectPost} from '../../user-journey/story'
import {selectMultiFeedPost} from '../../user-journey/multi-feed'
import {GetUidFromPin} from "../../utils/api/app"

export const checkPin = async (dispatch, pin, action, args) =>{
  dispatch(setBusyIndicator(true))
  const uid = await GetUidFromPin(pin)
  dispatch(setBusyIndicator(false))

  if(uid){
    dispatch(updateGroupActionProps(null, null, uid))
    await doAction(dispatch, action, args, uid)

  }
  else{
    dispatch(showAlertMessage("Invalid PIN entered"))
  }
}

export const doAction = async (dispatch, action, args, uid) => {
  switch (action) {
    case 'add_post':
      dispatch(selectPost(0))
      break

    case 'edit_post':
      if (parseInt(uid) === parseInt(args.post?.posted_by_id)){
        dispatch(showAlertMessage("Only the person who added this post can edit it"))
      }
      else{
        dispatch(selectPost(args?.post?.id))
      }
      break

    case 'edit_multi_feed_post':
      console.log(args)
      if (parseInt(uid) === parseInt(args.post?.posted_by_id)){
        dispatch(showAlertMessage("Only the person who added this post can edit it"))
      }
      else{
        dispatch(selectMultiFeedPost(args?.post?.id))
      }
      break

    case 'reply_post':
      const parentPost = await dispatch(saveReply({...args.reply, groupLoginAuthorId: uid} , args.postId))
      if (parentPost && args.postSaveActions){
        args.postSaveActions(parentPost)
      }
      break

    default:
      break;
  }

}