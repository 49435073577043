import React from "react";
import {AccessCodeTemplate} from "../access-code-template";

export const OrganisationAccessCode = ({ checkAccessCode, dictionary, selectedLanguage, selectLanguage }) => {

  return (
    <AccessCodeTemplate
      accessText={dictionary?.access_text}
      accessCodeText={dictionary.access_code}
      buttonText={dictionary.get_access}
      accessAction={checkAccessCode}
      selectedLanguage={selectedLanguage}
      selectLanguage={selectLanguage}
      dictionary={dictionary}/>
  )
}