import {connect} from 'react-redux'
import {Story} from './story'
import {savePost, downloadPosts, refreshPost, storyPosts, selectedPost} from './state'
import {selectedJourney, usersJourneyPermissions, viewingOwnJourney} from '../state'
import {appDictionary, openModal, updateGroupActionProps, appDownloading} from "../../app"

const mapStateToProps = (state) => ({
  selectedJourney: selectedJourney(state),
  selectedPost: selectedPost(state),
  downloadingPosts: appDownloading(state),
  posts: storyPosts(state),
  journeyPermissions: usersJourneyPermissions(state),
  viewingOwnJourney: viewingOwnJourney(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadPosts: (profileId, getNextPage) => dispatch(downloadPosts(profileId, getNextPage)),
  savePost: (selectedPost) => dispatch(savePost(selectedPost)),
  selectPost: (post) => dispatch(updateGroupActionProps('edit_post', {post: post})),
  addPost: () => dispatch(updateGroupActionProps('add_post', {})),
  postSaveActions: (post) => dispatch(refreshPost(post)),
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData)),
})

export const StoryConnected = connect(mapStateToProps, mapDispatchToProps)(Story)