import {DefaultTemplate} from "../../components"
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/react";

export const About = ({versionNumber, dictionary}) => {

  return (
    <DefaultTemplate pageTitle={dictionary.about} dictionary={dictionary}>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{dictionary.version_details}</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            {dictionary.version_number}: <b>{versionNumber}</b>
          </IonCardContent>
        </IonCard>

    </DefaultTemplate>
  )
}