import {
  IonItem,
  IonSkeletonText,
} from '@ionic/react';
import React from "react";
import styles from "./styles.module.scss";

export const SkeletonText= ({isVisible}) => {
  if (!isVisible) return null

  return (
    <>
      <IonItem className={styles.action_item}>

        <IonSkeletonText animated style={{ width : '50px', height: '50px', marginRight: '10px', borderRadius: '50px', marginTop: '10px', marginBottom: '10px' }} />

        <div className={styles.details}>
          <IonSkeletonText animated style={{ width : '100%' }} />
          <IonSkeletonText animated style={{ width : '50%' }} />
        </div>

      </IonItem>

      <IonItem className={styles.action_item}>

        <IonSkeletonText animated style={{ width : '50px', height: '50px', marginRight: '10px', borderRadius: '50px', marginTop: '10px', marginBottom: '10px'  }} />

        <div className={styles.details}>
          <IonSkeletonText animated style={{ width : '100%' }} />
          <IonSkeletonText animated style={{ width : '50%' }} />
        </div>

      </IonItem>
    </>
  )
}