import {
  SET_PROFILE,
  DOWNLOADING_PROFILE,
  SET_SUPPORTERS,
  RESET_STATE,
} from './action-types'

const initialState = {}

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {

    case DOWNLOADING_PROFILE:
      return { ...state,
        downloadingProfile: action.payload
      }

    case SET_PROFILE:
      return { ...state,
        profile: action.payload
      }

    case SET_SUPPORTERS:
      return {
        ...state,
        supporters: action.payload
      }

    case RESET_STATE:
      return {
        ...initialState
      }

    default:
      return state
  }
}