import {connect} from 'react-redux'
import {getOpenModal, getOpenModalData, dismissModal, appDictionary} from "../../../../app"
import {GoalEdit} from "./goal-edit"
import {saveGoal} from "../../state"
import {GOAL_EDIT} from '../../../../constants/modals'

const mapStateToProps = (state) => ({
  isOpen: getOpenModal(state, GOAL_EDIT),
  goal: getOpenModalData(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(dismissModal()),
  saveGoal: (goal) => dispatch(saveGoal(goal)),
})

export const GoalEditConnected = connect(mapStateToProps, mapDispatchToProps)(GoalEdit)