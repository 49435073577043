import {
  DOWNLOADING_PROFILE,
  SET_PROFILE,
  SET_SUPPORTERS,
  RESET_STATE,
} from "./action-types"

export const setDownloadingProfile = (downloading) => ({
  type: DOWNLOADING_PROFILE,
  payload: downloading
})

export const setProfile = (profile) => ({
  type: SET_PROFILE,
  payload: profile
});

export const setSupporters = (supporters) => ({
  type: SET_SUPPORTERS,
  payload: supporters
})

export const resetState = () => ({
  type: RESET_STATE
})