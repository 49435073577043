import {connect} from 'react-redux'
import {PushNotificationHandler} from './push-notification-handler'
import {userId} from '../../user-management'
import {journeys, downloadPosts, setJourney, ownJourney, userActions, getActions} from '../../user-journey'
import {openModal} from "../actions"
import {setPushAction} from '../action-types'
import {appInitialised, pushAction} from "../selectors"

const mapStateToProps = (state) => ({
  userId: userId(state),
  journeys: journeys(state),
  ownJourney: ownJourney(state),
  actions: userActions(state),
  appInitialised: appInitialised(state),
  pushAction: pushAction(state),
})

const mapDispatchToProps = (dispatch) => ({
  // syncDeviceToken: (uid, deviceToken, platform) => syncDeviceToken(uid, deviceToken, platform),
  loadJourney: (journey, userId) => {
    dispatch(setJourney(journey, userId))
    dispatch(downloadPosts(journey.id))
  },
  getActions: (profileId, goalId) => dispatch(getActions(profileId, goalId)),
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData)),
  setPushAction: (action, data) => dispatch(setPushAction(action, data)),
})

export const PushNotificationHandlerConnected = connect(mapStateToProps, mapDispatchToProps)(PushNotificationHandler)