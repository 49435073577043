import {connect}  from 'react-redux'
import {UserActions} from './user-actions'
import {getActions, selectedAction} from './state'
import {appDictionary, openModal} from "../../app"
import {selectedJourney, usersJourneyPermissions} from "../state";
import {userId} from "../../user-management"

const mapStateToProps = (state) => ({
  userId: userId(state),
  selectedJourney: selectedJourney(state),
  selectedAction: selectedAction(state),
  journeyPermissions: usersJourneyPermissions(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  getActions: (profileId) => dispatch(getActions(profileId)),
  openModal: (modal, modalData) => dispatch(openModal(modal, modalData))
})

export const UserActionsConnected = connect(mapStateToProps, mapDispatchToProps)(UserActions)