import {connect} from 'react-redux'
import {Meetings} from './meetings'
import {
  downloadMeetings, showMeetingDetails, meetings, calendarViewEnabled
} from './state'
import {selectedJourney} from "../state"
import {userId} from "../../user-management"
import {appDictionary} from "../../app"

const mapStateToProps = (state) => ({
  selectedJourney: selectedJourney(state),
  meetings: meetings(state),
  userId: userId(state),
  useCalendarView: calendarViewEnabled(state),
  downloadingMeetings: state.journey.downloadingMeetings,
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadMeetings: (profileId) => dispatch(downloadMeetings(profileId)),
  openMeetingDetails: async (meeting) => dispatch(showMeetingDetails(meeting))
})

export const MeetingsConnected = connect(mapStateToProps, mapDispatchToProps)(Meetings)