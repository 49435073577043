import {
  DOWNLOADING_GOALS,
  SAVE_GOAL,
  SET_GOALS,
  SET_SHOW_GOAL_ACTIONS,
  SET_SHOW_COMPLETE_GOALS,
  UPDATE_GOAL,
  RESET_STATE,
} from "./action-types";

const initialState = {}

export const goalReducer = (state = initialState, action) => {
  switch (action.type) {

    case DOWNLOADING_GOALS:
      return { ...state,
        downloadingGoals: action.payload
      }

    case SET_GOALS:
      return { ...state,
        goals: action.payload && action.payload.length ? [...action.payload] : [],
      }

    case SAVE_GOAL:
      return{ ...state,
        addingGoal: false,
        editingGoal: false,
        selectedGoal: null
      }

    case SET_SHOW_GOAL_ACTIONS:
      return { ...state,
        selectedGoal: action.payload.goal,
        goalActionsModalVisible: action.payload.visible
      }

    case SET_SHOW_COMPLETE_GOALS:
      return {
        ...state,
        showCompleteGoals: action.payload
      }

    case UPDATE_GOAL:
      return {
        ...state,
        goals: state?.goals?.map(goal => parseInt(goal.id) === parseInt(action.payload.id) ? action.payload : goal)
      }

    case RESET_STATE:
      return {
        ...initialState
      }

    default:
      return state
  }
}