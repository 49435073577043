import {connect} from 'react-redux'
import {Supporting} from './supporting'
import {downloadJourneys, setJourney} from "../../user-journey"
import {supportedJourneys} from '../../user-journey'
import {userId} from '../selectors'
import {appDictionary} from "../../app"

const mapStateToProps = (state) => ({
  supportedJourneys: supportedJourneys(state),
  userId: userId(state),
  downloadingJourneys: state.journey.downloadingJourneys,
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadJourneys: (userId) => dispatch(downloadJourneys(userId)),
  selectJourney: (journey, userId) => dispatch(setJourney(journey, userId))
})

export const SupportingConnected = connect(mapStateToProps, mapDispatchToProps)(Supporting)