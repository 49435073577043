import React from 'react'
import {Network} from "@capacitor/network";
import styles from './styles.module.scss'

export const NetworkStatus = ({ updateNetworkStatus, networkStatus, dictionary }) => {

  Network.addListener('networkStatusChange', status => {
    updateNetworkStatus(status.connected)
  });

  return (
    <>
      {
        networkStatus === 'OFFLINE' &&
        <div className={styles.overlay}>
          <div>
            <h1>{dictionary.offline}</h1>
          </div>
        </div>
      }
    </>
  )
}