import {
  IonImg
} from '@ionic/react'
import React from "react";
import styles from './styles.module.scss'
import {siteUrl} from "../../../../app/settings"

export const MediaBox = ({
                           post,
                           onClick,
                         }) => {

  const getImageUrl = (uri) => {
    return siteUrl() + uri
  }

  return(
    <div className={styles.media_box_container}>

      <IonImg src={getImageUrl(post.cover_picture)} onClick={() => onClick(post)} />

      {post.media_count > 1 &&
        <div className={styles.media_count} onClick={() => onClick(post)}>
          <h1>+{post.media_count - 1}</h1>
        </div>
      }
    </div>
  )

}