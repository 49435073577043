import {createSelector} from "reselect";

export const multiFeedPosts = (state) =>
  state?.multiFeed?.posts

const selectedMultiFeedPostId = (state) =>
  state?.multiFeed?.selectedMultiFeedPostId

export const selectedMultiFeedPost = createSelector(
  multiFeedPosts,
  selectedMultiFeedPostId,
  (posts, selectedPostId) => {
    if (selectedPostId === 0){
      return {
        images: [],
        videos: [],
        post_active: true
      }
    }
    else{
      return (!posts || !selectedPostId) ? null : posts.find(p => parseInt(p.id) === parseInt(selectedPostId))
    }
  }
)