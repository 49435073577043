import {
  IonSkeletonText,
  IonCard,
  IonCardContent,
} from '@ionic/react';
import React from "react";

export const SkeletonTextStory = ({ isVisible }) => {
  if (!isVisible) return null

  return (
    <>
      <IonCard>
        <IonCardContent>
          <IonSkeletonText animated style={{ width: '30%'}} />
          <IonSkeletonText animated style={{ width: '80%'}} />
          <IonSkeletonText animated style={{ width: '100%', height: '150px'}} />
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardContent>
          <IonSkeletonText animated style={{ width: '30%'}} />
          <IonSkeletonText animated style={{ width: '80%'}} />
          <IonSkeletonText animated style={{ width: '100%', height: '150px'}} />
        </IonCardContent>
      </IonCard>
    </>
  )
}