import React from "react";
import {ModalTemplate} from '../../../../components'
import {AccessCodeTemplate} from '../access-code-template'
import styles from './styles.module.scss'

export const TfaAccessCode = ({ isOpen, checkTfaCode, parent, logout, dictionary }) => {

  return (
    <ModalTemplate isOpen={isOpen}
                   title={dictionary.tfa}
                   parent={parent}
                   allowClose={false}>

      <AccessCodeTemplate
        accessText={dictionary.tfa_required}
        buttonText={dictionary.authenticate}
        accessAction={checkTfaCode}
        accessCodeText={dictionary.acces_code}
        dictionary={dictionary} />

        <div className={styles.links} onClick={() => { logout() }}>
          Return to Login
        </div>

    </ModalTemplate>

  )
}