import {connect} from 'react-redux'
import {Replies} from './replies'
import {downloadingReplies, replies, toggleLike, clearReplies, downloadReplies} from '../../../../story'
import {selectedJourney, avatars} from '../../../../state'
import {appDictionary, getOpenModal, getOpenModalData, textToSpeechEnabled} from "../../../../../app"
import {dismissModal, updateGroupActionProps} from "../../../../../app";
import {userDetails} from '../../../../../user-management'

const mapStateToProps = (state) => ({
  selectedJourney: selectedJourney(state),
  downloadingReplies: downloadingReplies(state),
  replies: replies(state),
  avatars: avatars(state),
  isOpen: getOpenModal(state, 'POST_REPLIES'),
  parentPost: getOpenModalData(state),
  userDetails: userDetails(state),
  textToSpeechEnabled: textToSpeechEnabled(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadReplies: (profileId, replyToId) => dispatch(downloadReplies(profileId, replyToId)),
  toggleLike: (like, post, reactionId, userId) => dispatch(toggleLike(like, post, reactionId, userId)),
  saveReply: (reply, postId, postSaveActions) => dispatch(updateGroupActionProps('reply_post', {reply: reply, postId: postId, postSaveActions: postSaveActions})),
  clearReplies: () => dispatch(clearReplies()),
  close: () => dispatch(dismissModal(null, null))
})

export const RepliesConnected = connect(mapStateToProps, mapDispatchToProps)(Replies)