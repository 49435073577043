export const userActions = (state, goal) => {
  const showComplete = state?.userActions?.showCompleteActions

  return state?.userActions?.actions?.filter((action) => {
    if (goal && parseInt(action.goal_id) !== parseInt(goal.id)) {
      return false
    }
    return !(!showComplete && action.complete);
  })
}

export const downloadingActions = (state) =>
    state?.userActions?.downloadingActions

export const selectedAction = (state) =>
    state?.userActions?.selectedAction

export const showCompleteActions = (state) =>
    state?.userActions?.showCompleteActions
