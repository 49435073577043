import {
  IonLabel,
  IonTextarea,
  IonThumbnail,
  IonChip,
  IonIcon,
  IonCard,
  IonCardContent,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonList
} from "@ionic/react"
import React, {useEffect, useState, useMemo} from "react"
import {ModalTemplate, ValidationMessage} from "../../../components"
import styles from './styles.module.scss'
import {closeCircleOutline, imagesOutline} from 'ionicons/icons'
import {siteUrl} from "../../../app/settings"
import {Capacitor} from "@capacitor/core"
import {addPhoto, addVideoNative, addVideoCapacitor, removeMedia} from './logic'

export const PostEdit = ({
                           selectPost,
                           selectedPost,
                           selectedJourney,
                           downloadGoals,
                           goals,
                           savePost,
                           viewingOwnJourney,
                           parent,
                           groupActionUserId,
                           avatar,
                           refreshMedia,
                           setBusyIndicator,
                           postSaveActions,
                           dictionary,
                           journeyPermissions,
                         }) => {

  const [mediaRefreshed, setMediaRefreshed] = useState(null)
  const [goalsRefreshed, setGoalsRefreshed] = useState(null)
  const [postText, setPostText] = useState(null)
  const [postGoals, setPostGoals] = useState([])
  const [postMedia, setPostMedia] = useState([])
  const [validationMessage, setValidationMessage] = useState(null)

  useEffect(() => {
    if (selectedPost && selectedPost.id && !mediaRefreshed){
      setMediaRefreshed(true)
      refreshMedia(selectedPost)
    }
  }, [selectedPost, mediaRefreshed, refreshMedia])

  useEffect(() => {
    if (selectedPost && !goalsRefreshed){
      setGoalsRefreshed(true)
      downloadGoals(selectedJourney.id)
    }
  }, [selectedPost, downloadGoals, selectedJourney?.id, goalsRefreshed])

  useEffect(() => {
    if (selectedPost){
      setPostText(selectedPost.post_text)
      setPostGoals(selectedPost?.goal_id_list?.split(",") ?? [])
      setPostMedia(selectedPost.media ?? [])
    }
  }, [selectedPost])

  useEffect(() => {
    setPostMedia(selectedPost?.media ?? [])
  }, [selectedPost?.media])

  const videoUploadEnabled = Capacitor.getPlatform() !== 'web'
  const useNativeFileUpload = Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'web'

  const addVideo = async (vid) => {
    await addVideoNative(vid, postMedia, setPostMedia, setBusyIndicator)
  }

  const onSave = () => {
    if (!isValid){
      setValidationMessage(dictionary.before_post_save)
      return false
    }

    savePost({
      ...selectedPost,
      profile_id: selectedJourney.id,
      post_text: postText,
      goal_ids: postGoals,
      groupLoginAuthorId: groupActionUserId,
      media: postMedia
    }, postSaveActions)
    selectPost(null)
  }

  const isValid = useMemo(() => {
    return Boolean(postText) || postMedia?.length > 0
  }, [postText, postMedia])

  const getImageUrl = (uri) => {
    return siteUrl() + uri
  }

  const closeMe = () => {
    setMediaRefreshed(false)
    setGoalsRefreshed(false)
    setPostGoals(null)

    selectPost(null)
  }

  return (
    <ModalTemplate isOpen={Boolean(selectedPost)}
                   title={selectedPost?.id ? dictionary.edit_post : dictionary.create_post}
                   closeMethod={closeMe}
                   saveMethod={onSave}
                   parent={parent}>
      <IonCard>
        <IonCardContent>

          <div className={styles.user_bar}>
            <IonThumbnail slot="end">
              <img src={avatar} alt='user' />
            </IonThumbnail>
            <h6>
              {selectedJourney?.display_name}
            </h6>
          </div>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardContent >
          <IonTextarea placeholder={viewingOwnJourney ? dictionary.add_to_own_story : `${dictionary.add_to_other_prefix} ${selectedJourney?.display_name}${dictionary.add_to_other_suffix}` }
                       rows={10}
                       spellcheck={true}
                       autocapitalize='sentences'
                       value={postText}
                       onIonChange={(e)=>{
                         setPostText(e.target.value)
                       }} />
          </IonCardContent>
      </IonCard>

      {
        (viewingOwnJourney || parseInt(journeyPermissions?.view_goals) === 1) &&
        <IonCard>
          <IonCardContent className={styles.cardpadless}>
            <IonItem lines='none'>
              <IonLabel position="fixed">{dictionary.select_goals}</IonLabel>
              <IonSelect value={postGoals}
                         multiple={true}
                         selectedText=' '
                         onIonChange={e => {
                           setPostGoals(e.detail.value)
                         }}>
                {
                  goals?.map((goal, index) => {
                    return (
                      <IonSelectOption
                        key={index}
                        value={goal.id}>{goal.title}
                      </IonSelectOption>
                    )
                  })
                }
              </IonSelect>
            </IonItem>

            {
              postGoals?.length
                ?
                <IonList>
                  {
                    postGoals?.map((goal) => {
                      return <IonItem lines='none' key={goal}>{goals?.find(g => g.id === goal)?.title}</IonItem>
                    })
                  }
                </IonList>
                :
                <IonItem lines='none'>none selected</IonItem>
            }
          </IonCardContent>
        </IonCard>
      }

      <IonCard>
        <IonCardContent className={styles.cardpadless}>
          <IonItem lines='none'>
          <IonLabel position="fixed">{dictionary.media}</IonLabel>
            <div className={styles.options}>

              <IonChip outline={true} onClick={() => addPhoto(setPostMedia, postMedia)} className={styles.chip}>
                <IonIcon className={styles.post_image_icon} icon={imagesOutline} />
                <IonLabel>{dictionary.photo}</IonLabel>
              </IonChip>

              {
                videoUploadEnabled
                ?
                  useNativeFileUpload
                  ?
                    <IonChip outline={true} className={styles.chip}>
                      <label htmlFor="file-upload" className={styles.video_label}>
                        <input className={styles.video_input} type="file" onChange={addVideo} id="file-upload" accept="video/*" />
                        <IonIcon className={styles.post_image_icon} icon={imagesOutline} />
                        <IonLabel>Video</IonLabel>
                      </label>
                    </IonChip>
                  :
                    <IonChip outline={true} onClick={async () => await addVideoCapacitor(setPostMedia, postMedia, setBusyIndicator)} className={styles.chip}>
                      <IonIcon className={styles.post_image_icon} icon={imagesOutline} />
                      <IonLabel>{dictionary.video}</IonLabel>
                    </IonChip>
                :
                  <></>
              }
            </div>
          </IonItem>

          <div className={styles.media_container}>
            {
              postMedia?.map((media, index) => {
                return(
                  <IonCard key={index}>
                    <IonCardContent className={styles.media_card}>
                      <div>
                        <IonIcon slot="start" icon={closeCircleOutline} onClick={() => { removeMedia(index, setPostMedia, postMedia) }} />
                        {
                          media.type === 'image'
                          ?
                            <img src={(media.image_fid) ? getImageUrl(media.image) : `data:image/png;base64, ${media.image.base64String}`} alt='' />
                          :
                            <img src={media.paragraph_id ? getImageUrl(media.image) : media.image} alt='' />
                        }
                      </div>
                    </IonCardContent>
                  </IonCard>
                )
              })
            }
          </div>

        </IonCardContent>
      </IonCard>
      {validationMessage && <ValidationMessage validationMessage={validationMessage} clearMessage={setValidationMessage} /> }

    </ModalTemplate>
  )
}