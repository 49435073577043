import { connect } from 'react-redux'
import { UserAccount } from './user-account'
import {changeProfilePic, doLogout, updateUserDetails, downloadUserDetails} from '../actions'
import {userDetails,} from '../selectors'
import {avatars,} from '../../user-journey'
import {appDictionary, selectedLanguage, selectLanguage} from '../../app'

const mapStateToProps = (state) => ({
  userDetails: userDetails(state),
  avatars: avatars(state),
  selectedLanguage: selectedLanguage(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(doLogout()),
  changeProfilePic: (uid) => dispatch(changeProfilePic(uid)),
  selectLanguage: (language) => dispatch(selectLanguage(language)),
  saveUserDetails: (userDetails, dictionary) => dispatch(updateUserDetails(userDetails, dictionary)),
  downloadUserDetails: () => dispatch(downloadUserDetails(true))
})

export const UserAccountConnected = connect(mapStateToProps, mapDispatchToProps)(UserAccount)