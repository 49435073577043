import {getVideoPreview, getVideoWithPreview, takePicture, videoIsValid} from "../../../utils/functions/camera"

export const addPhoto = async (setPhoto, postMedia) => {
  const capture = await takePicture()

  if(!capture) {
    return
  }

  setPhoto(postMedia.concat({
    image_fid: 0,
    type: 'image',
    image: capture
  }))
}

export const addVideoNative = async (vid, postMedia, setPostMedia, setBusyIndicator) => {

  if (!vid.target?.files?.length){
    return
  }
  const selectedVideo = Array.from(vid.target.files)[0]
  const videoPreview = await getVideoPreview(vid.target.files[0])

  const video = document.createElement('video')
  video.preload = 'metadata'
  video.onloadedmetadata = () => {
    window.URL.revokeObjectURL(vid.src)

    if (!videoIsValid(video)) {
      setBusyIndicator(false)
      return
    }

    setPostMedia(postMedia.concat({
      video_fid: 0,
      image_fid: 0,
      type: 'video',
      video: selectedVideo,
      image: videoPreview
    }))
  }
  video.src = URL.createObjectURL(selectedVideo)
}

export const addVideoCapacitor = async (setVideo, postMedia, setBusyIndicator) => {

  // console.log('Adding capacitor video')

  setBusyIndicator(true)
  let video = null
  try {
    video = await getVideoWithPreview()
  }
  catch (e) {
    setBusyIndicator(false)
  }

  if (!video) {
    setBusyIndicator(false)
    return
  }

  setVideo(postMedia.concat({
    video_fid: 0,
    image_fid: 0,
    type: 'video',
    video: video.video,
    image: video.image
  }))

  setBusyIndicator(false)
}

export const removeMedia = (index, setPostMedia, postMedia) => {
  postMedia.splice(index, 1)
  setPostMedia([...postMedia])
}