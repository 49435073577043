import {
  setMeetings,
  setMeetingImages,
  setMeetingLinks,
  resetState,
} from "./actions"

import {
  downloadMeetingImages,
  downloadMeetingLinks,
  downloadMeetingRatings,
  downloadMeetings as doDownload
} from "../../../utils/api/journey";
import {openModal, setBusyIndicator} from "../../../app";
import {MEETING_DETAILS} from "../../../constants/modals";

export const downloadMeetings = (profileId) => {
  return (dispatch) => {
    dispatch(setBusyIndicator(true))

    return doDownload(profileId, dispatch).then(
      (meetings) => {
        dispatch(setMeetings(meetings))
        dispatch(setBusyIndicator(false))

        dispatch(downloadRatings(meetings))
      }
    )
  }
}

export const showMeetingDetails = (meeting) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const r = await Promise.all([
      dispatch(downloadImages(meeting.id)),
      dispatch(downloadLinks(meeting.id))
    ])
    meeting.images = r[0]
    meeting.links = r[1]

    dispatch(setBusyIndicator(false))

    dispatch(openModal(MEETING_DETAILS, meeting))
  }
}

export const downloadImages = (meetingId) => {
  return async (dispatch) => {
    const images = await downloadMeetingImages(meetingId, dispatch)

    dispatch(setMeetingImages(meetingId, images))

    return images
  }
}

export const downloadLinks = (meetingId) => {
  return async (dispatch) => {
    const links = await downloadMeetingLinks(meetingId, dispatch)

    dispatch(setMeetingLinks(meetingId, links))

    return links
  }
}

export const downloadRatings = (meetings) => {
  return (dispatch) => {

    if(!meetings){
      return false
    }

    meetings.forEach((meeting) =>{
      return downloadMeetingRatings(meeting.id, dispatch).then(
        (ratings) => {

          let m = meetings.find((g) => +g.id === +meeting.id)
          if(m){
            m.rating =  ratings && ratings.length > 0 ? parseInt(ratings[0].rating) : 0
            m.ratings = ratings && ratings.length > 0 ? ratings : []
          }
          dispatch(setMeetings(meetings))
        }
      )
    })

    // dispatch(setGoalRatings(ratings))
  }
}

export const clearMeetings = () => {
  return (dispatch) => dispatch(resetState())
}