import {IonItem, IonLabel, IonToggle, IonCard, IonCardContent} from "@ionic/react";
import {addCircleOutline} from 'ionicons/icons'
import {ActionEdit} from '../user-actions'
import React, { useEffect, useState } from "react";
import {DefaultTemplate, NoContent, ConfirmationBox, DeletedItem} from '../../components'
import {GoalEdit, GoalProgress, GoalActions} from './modals'
import {GoalItem, SkeletonText, Options} from "./components";
import {canAddGoals, filterActions} from './logic'
import styles from './styles.module.scss'
import {GOAL_EDIT} from "../../constants/modals"

export const Goal = ({
                       downloadGoals,
                       downloadActions,
                       selectedJourney,
                       goals,
                       actions,
                       userId,
                       downloadingGoals,
                       openModal,
                       router,
                       toggleCompletedGoals,
                       showComplete,
                       deleteGoal,
                       journeyPermissions,
                       dictionary,
                     }) => {

  const [selectedGoal, setSelectedGoal] = useState(null)
  const [goalForDeletion, setGoalForDeletion] = useState(null)

  useEffect(() => {
    downloadGoals(selectedJourney.id)
    downloadActions(selectedJourney.id)

  }, [downloadGoals, downloadActions, selectedJourney])

  return (
    <DefaultTemplate pageTitle={dictionary.goals}
                     username={selectedJourney.display_name}
                     actionButtonIcon={canAddGoals(selectedJourney, journeyPermissions, userId) ? addCircleOutline : null}
                     actionButtonOnClick={canAddGoals(selectedJourney, journeyPermissions, userId) ? ()=>{ openModal(GOAL_EDIT, {profile_id: selectedJourney.id}) } : null}
                     refreshFunction={() => downloadGoals(selectedJourney.id)}
                     fabButtonAction={canAddGoals(selectedJourney, journeyPermissions, userId) ? ()=>{ openModal(GOAL_EDIT, {profile_id: selectedJourney.id}) } : null}
                     dictionary={dictionary}>

      <SkeletonText
        isVisible={downloadingGoals}
        styles={styles}
      />

      <NoContent
        isVisible={!downloadingGoals && !goals?.length}
        text={dictionary.no_goals}
      />

      {
        !downloadingGoals && goals?.length > 0 &&
        <>
          <IonItem>
            <IonLabel>{dictionary.show_completed_goals}</IonLabel>
            <IonToggle slot="end" checked={showComplete} onIonChange={e => toggleCompletedGoals(e.detail.checked)} />
          </IonItem>

          {
            goals.map((goal) => {
              return (
                goal.deleted
                  ?
                  <DeletedItem
                    text={dictionary.deleted_goal}
                  />
                  :
                  <IonCard key={goal.id}>
                    <IonCardContent className={styles.goal_card}>
                      <GoalItem
                        goal={goal}
                        openModal={openModal}
                        setSelectedGoal={setSelectedGoal}
                        userId={userId}
                        goalActions={filterActions(actions, goal.id)}
                      />
                    </IonCardContent>
                  </IonCard>

              )
            })
          }
        </>
      }

      <GoalEdit parent={router} />
      <GoalProgress parent={router} />
      <GoalActions parent={router} />
      <ActionEdit parent={router}/>

      <ConfirmationBox
        isOpen={Boolean(goalForDeletion)}
        message={dictionary.confirm_delete_goal}
        handler={() => deleteGoal(goalForDeletion)}
        onDismiss={() => setGoalForDeletion(null)}
      />

      <Options
        journey={selectedJourney}
        userId={userId}
        selectedGoal={selectedGoal}
        setSelectedGoal={setSelectedGoal}
        openModal={openModal}
        setGoalForDeletion={setGoalForDeletion}
        journeyPermissions={journeyPermissions}
        dictionary={dictionary}
      />

    </DefaultTemplate>
  )
}