import {uploadFile} from './api'
import {v4 as uuidv4} from 'uuid'
import {handleFetchError} from "../../app"

// export const uploadImages = async (images, uploadUrl, dispatch) => {
//
//   for (const image of images){
//     if(image.fid === 0){
//       const uid = uuidv4()
//       let response = await uploadFile(url, concertBase64ToBlob(base64Image), `${uid}.${base64Image.format}`)
//
//       image.fid = await uploadImage(uploadUrl, image.base64, dispatch)
//     }
//   }
// }

export const uploadImage = async (url, base64Image, dispatch) => {
  try{
    const uid = uuidv4()
    let response = await uploadFile(url, concertBase64ToBlob(base64Image.base64String, `image/${base64Image.format}`), `${uid}.${base64Image.format}`)

    return (response) ? response.fid[0].value : null
  }
  catch (e) {
    dispatch && dispatch(handleFetchError(e.message))
  }
}

export const uploadVideo = async (url, vid, dispatch) => {
  try{
    const uid = uuidv4()
    let response = await uploadFile(url, vid, uid + '.mp4')

    return (response) ? response.fid[0].value : null
  }
  catch (e) {
    dispatch && dispatch(handleFetchError(e.message))
  }
}

export const uploadVideoCover = async (url, img, dispatch) => {
  try{
    const uid = uuidv4()
    let response = await uploadFile(url, concertBase64ToBlob(img.replace('data:image/png;base64,', ''), `image/jpg`), `${uid}.jpg`)

    return (response) ? response.fid[0].value : null
  }
  catch (e) {
    dispatch && dispatch(handleFetchError(e.message))
  }
}

const concertBase64ToBlob = (base64Data, mediaType) => {

  const rawData = atob(base64Data);
  const bytes = new Array(rawData.length);
  for (let x = 0; x < rawData.length; x++) {
    bytes[x] = rawData.charCodeAt(x);
  }
  const arr = new Uint8Array(bytes);

  return new Blob([arr], {type: mediaType});
}


