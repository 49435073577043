import {connect} from 'react-redux'
import {GroupAction} from "./group-action"
import {groupActionArgs, groupActionAction, groupActionUserId} from "../../app/selectors";
import {doAction, checkPin} from './logic'
import {isGroupAccount} from '../../user-management'
import {showAlertMessage, updateGroupActionProps} from '../../app'

const mapStateToProps = (state) => ({
  action: groupActionAction(state),
  groupAccountUid: groupActionUserId(state),
  args: groupActionArgs(state),
  isGroupAccount: isGroupAccount(state),
})

const mapDispatchToProps = (dispatch) => ({
  cancelAction: () => dispatch(updateGroupActionProps(null, null, null)),
  checkPin: (pin, action, args) => checkPin(dispatch, pin, action, args),
  skipPinCheck: (action, args) => {
    doAction(dispatch, action, args)
    dispatch(updateGroupActionProps(null, null, null))
  },
  showAlert: (msg) => dispatch(showAlertMessage(msg))
})

export const GroupActionConnected = connect(mapStateToProps, mapDispatchToProps)(GroupAction)