import {
  IonModal,
  IonContent,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonTitle} from "@ionic/react";
import {close, checkmark} from 'ionicons/icons'
import styles from './styles.module.scss'
import React, {useCallback, useEffect, useRef} from "react"

export const ModalTemplate = ({
                                isOpen,
                                children,
                                title,
                                saveMethod,
                                closeMethod,
                                parent,
                                allowClose = true,
                              }) => {

  const modal = useRef(null)

  const backHandler = useCallback(() => {
    document.removeEventListener('ionBackButton', backHandler)
    closeMethod()
  }, [closeMethod])

  useEffect(() =>{
    if (isOpen){
      document.addEventListener('ionBackButton', backHandler)
    }
  }, [isOpen, backHandler])

  return (
    <IonModal isOpen={isOpen}
              cssClass='modal'
              backdropDismiss={allowClose}
              showBackdrop={true}
              swipeToClose={allowClose}
              presentingElement={parent ?? undefined}
              onDidDismiss={closeMethod}
              ref={modal}>
    <IonContent className={styles.modal_content}>

      <IonHeader className={styles.modal_header} color="primary">
        <IonToolbar color="secondary">
          {allowClose &&
            <IonButtons slot="start">
              <IonIcon icon={close} onClick={() => modal.current?.dismiss()}/>
            </IonButtons>
          }

          <IonTitle size="medium">{title}</IonTitle>

          <IonButtons slot="end">
            { saveMethod && <IonIcon icon={checkmark} onClick={saveMethod} /> }
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div className={styles.modal_content_wrapper}>
        {children}
      </div>

    </IonContent>
    </IonModal>
  )
}