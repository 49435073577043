import React, {useEffect} from "react"
import {DefaultTemplate, NoContent} from "../../components"
import {Feed} from "../components/feed"
import {SkeletonTextStory} from "../story/components"

export const MultiFeed = ({
                            downloadPosts,
                            posts,
                            selectPost,
                            selectedPost,
                            savePost,
                            deletePost,
                            appDownloading,
                            postSaveActions,
                            router,
                            dictionary,
                          }) => {

  useEffect(() => {
    downloadPosts()
  }, [downloadPosts])

  return (
    <DefaultTemplate pageTitle={dictionary.multi_feed}
                     refreshFunction={() => downloadPosts() }
                     dictionary={dictionary}>

      <SkeletonTextStory
        isVisible={appDownloading}
      />

      <NoContent
        isVisible={!appDownloading && !posts?.length}
        text={dictionary.no_posts}
      />

      {
        !appDownloading &&
        <Feed posts={posts}
              selectPost={selectPost}
              selectedPost={selectedPost}
              savePost={savePost}
              deletePost={deletePost}
              postSaveActions={postSaveActions}
              router={router}
        />
      }

    </DefaultTemplate>
  )
}