import {TextToSpeech} from "@capacitor-community/text-to-speech";

export const startSpeaking = async (text) => {
  await TextToSpeech.speak({
    text: text,
    lang: 'en-GB',
    rate: 1.0,
    pitch: 1.0,
    volume: 1.0,
    category: 'ambient',
  })
  return true
}

export const stopSpeaking = async () => {
  await TextToSpeech.stop()
}