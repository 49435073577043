import {connect} from 'react-redux'
import {OrganisationAccessCode} from './organisation-access-code'
import {checkOrganisationCode, selectLanguage} from '../../../actions'
import {appDictionary, selectedLanguage} from "../../../selectors"

const mapStateToProps = (state) => ({
  dictionary: appDictionary(state),
  selectedLanguage: selectedLanguage(state),
})

const mapDispatchToProps = (dispatch) => ({
  checkAccessCode: (status) => dispatch(checkOrganisationCode(status)),
  selectLanguage: (language) => dispatch(selectLanguage(language))
})

export const OrganisationAccessCodeConnected = connect(mapStateToProps, mapDispatchToProps)(OrganisationAccessCode)