import {connect} from 'react-redux'
import {LikeList} from "./like-list";
import {downloadLikeList, clearLikeList, likeList} from '../../../../story'
import {appDictionary} from "../../../../../app"

const mapStateToProps = (state) => ({
  likeList: likeList(state),
  dictionary: appDictionary(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadLikeList: (post) => dispatch(downloadLikeList(post)),
  clearLikeList: () => dispatch(clearLikeList()),
})

export const LikeListConnected = connect(mapStateToProps, mapDispatchToProps)(LikeList)

