import {
  SET_MULTI_FEED_POSTS,
  SET_SELECTED_MULTI_FEED_POSTS_ID,
  UPDATE_MULTI_FEED_POST,
  UPDATE_MULTI_FEED_REPLY,
  RESET_STATE,
} from "./action-types"

export const dispatchMultiFeedPosts = (posts) => ({
  type: SET_MULTI_FEED_POSTS,
  payload: posts
})

export const dispatchSelectedMultiFeedPostId = (postId) => ({
  type: SET_SELECTED_MULTI_FEED_POSTS_ID,
  payload: postId
})

export const dispatchMultiFeedPost = (post) => ({
  type: UPDATE_MULTI_FEED_POST,
  payload: post
})

export const dispatchMultiFeedReply = (reply) => ({
  type: UPDATE_MULTI_FEED_REPLY,
  payload: reply
})

export const resetState = () => ({
  type: RESET_STATE
})