import styles from './styles.module.scss'
import {IonInput, IonIcon,IonAvatar} from "@ionic/react";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ModalTemplate, NoContent, ValidationMessage} from "../../../../../components";
import {SkeletonTextReplies} from '../../../../story/components/skeleton-text'
import {calendarOutline, thumbsUp, thumbsUpOutline, sendOutline, micOutline, stopCircleOutline} from "ionicons/icons";
import { findAvatar } from "../../../../story/logic";
import {startSpeaking, stopSpeaking} from '../../../../../utils/functions/speak'

export const Replies = ({
                          parentPost,
                          isOpen,
                          close,
                          saveReply,
                          downloadReplies,
                          downloadingReplies,
                          replies,
                          toggleLike,
                          avatars,
                          userDetails,
                          postSaveActions,
                          parent,
                          textToSpeechEnabled,
                          setPostForLikeList,
                          dictionary,
                        }) => {

  const [replyText, setReplyText] = useState(null)
  const [validationMessage, setValidationMessage] = useState(null)
  const [speaking, setSpeaking] = useState(false)

  const speak = async (text) => {
    if (speaking){
      await stopSpeaking()
      setSpeaking(false)
    }
    else{
      setSpeaking(true)
      await startSpeaking(text)
      setSpeaking(false)
    }
  }

  useEffect(() => {
    if(isOpen && parentPost){
      downloadReplies(parentPost.profile_id, parentPost.id)
    }
  }, [isOpen, parentPost, downloadReplies])

  const onSave = () => {
    if (!isValid){
      setValidationMessage(dictionary.supply_reply)
      return false
    }

    saveReply({
      parentPostId : parentPost.id,
      profile_id : parentPost.profile_id,
      images : [],
      post_active: true,
      post_text: replyText,
    }, parentPost.id, postSaveActions)

    setReplyText(null)
  }

  const isValid = useMemo(() => {
    return Boolean(replyText)
  }, [replyText])

  const showLikes = useCallback((reply) => {
    if (reply.like_count > 0) {
      setPostForLikeList(reply)
    }
  }, [setPostForLikeList])

  return (
    <ModalTemplate isOpen={isOpen}
                   title={dictionary.replies}
                   closeMethod={()=>{close()}}
                   parent={parent}>
      <div className={styles.reply_modal_content}>

        <SkeletonTextReplies
          isVisible={downloadingReplies}
        />

        <NoContent
          isVisible={!downloadingReplies && !replies?.length}
          text={dictionary.no_replies}
        />

        {
          !downloadingReplies && replies && replies.length > 0 && replies.map((reply) => {
            return(
              <div key={reply?.id}>
                <div className={styles.reply_wrapper}>
                  <div className={styles.user}>
                    <IonAvatar slot="start">
                      <img src={findAvatar(avatars, reply?.group_user_id || reply?.posted_by_id)} alt='' />
                    </IonAvatar>
                  </div>
                  <div className={styles.reply_details}>
                    <div className={styles.reply}>
                      <h3>{reply?.group_user_name || reply?.posted_by_name}</h3>
                      <p onClick={() => speak(reply?.post_text)} className={`${textToSpeechEnabled ? styles.speech_enabled : ''}`}>
                        {reply?.post_text}
                        {textToSpeechEnabled && <IonIcon className={`${speaking ? styles.speech_started : ''}`} icon={speaking ? stopCircleOutline : micOutline} />}
                      </p>
                    </div>
                    <div className={styles.post_date}>
                      <p className={styles.post_date}><IonIcon className={styles.date_icon} icon={calendarOutline} /> {reply?.post_date}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.like_container}>
                  <p className={`${reply?.like_count > 0 ? styles.like_link : ''}`} onClick={() => showLikes(reply)}>{reply?.like_count} likes</p>
                  <IonIcon icon={reply?.like_id ? thumbsUp : thumbsUpOutline} onClick={() => toggleLike(parseInt(reply?.like_active) === 0, reply, reply?.like_id, userDetails.uid) }/>
                </div>
              </div>
              )
          })
        }
      </div>
      <div className={styles.reply_box}>
        <IonInput type='text' autocorrect='on' spellcheck={true} autocapitalize='sentences' placeholder='Write a reply...'
                  value={replyText}
                  onIonChange={
                    (e)=>{
                      setReplyText(e.target.value)
                    }
                  }
        />
        <IonIcon className={styles.reply_icon} icon={sendOutline} onClick={onSave} />
      </div>

      {validationMessage && <ValidationMessage validationMessage={validationMessage} clearMessage={setValidationMessage} /> }
    </ModalTemplate>
  )
}