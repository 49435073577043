import React, {useCallback, useEffect, useMemo, useState} from "react"
import {Eventcalendar} from "@mobiscroll/react"

export const MeetingCalendar = ({
                                  meetings,
                                  openMeetingDetails,
                                  downloadingMeetings,
                                  selectedView,
                                  dictionary,
                                }) => {

  const [events, setEvents] = useState([])

  const calendarView = useMemo(() => {
    return {
      calendar: { type: 'month' },
      agenda: { type: 'day' }
    }
  }, [])

  const listView = useMemo(() => {
    return {
      agenda: { type: 'year' }
    }
  }, [])

  const view = useMemo(() => {
    return selectedView === 'calendar' ? calendarView : listView
  }, [selectedView, calendarView, listView]);


  useEffect(() =>{
    if (meetings?.length){
      let event;
      let eventList = [];
      for (let i = 0; i < meetings.length; ++i) {
        event = meetings[i];
        const meetingDate = new Date(event.meeting_date)

        eventList.push({
          id: event.id,
          start: meetingDate,
          end: meetingDate,
          title: event.meeting_title,
          allDay: true,
          color: event.review_meeting ? '#a8c70e' : '#f08105'

        });
      }
      setEvents(eventList)
    }
  }, [meetings])

  const onEventClick = useCallback((meeting) => {
    openMeetingDetails(meetings?.find(m => m.id === meeting.event.id))
  }, [meetings, openMeetingDetails]);

  return (
    <>

      { // List of available meetings
        !downloadingMeetings && meetings?.length &&
        <Eventcalendar
          theme="auto"
          // can be 'material', 'ios' or 'auto
          themeVariant="light"
          // can be 'light', 'dark' or 'auto
          clickToCreate={false}
          dragToCreate={false}
          dragToMove={false}
          dragToResize={false}
          data={events}
          view={view}
          onEventClick={onEventClick}
          noEventsText={dictionary.no_meetings}
          showControls={true}
        />
      }

    </>

  );
}