import {findOwnJourney} from "../logic"
import {downloadAvatar, downloadJourneys as doDownload} from "../../utils/api/journey"
import {setBusyIndicator} from "../../app"
import {downloadPermissions} from "../../utils/api/user"
import {
  setSelectedJourney,
  setDownloadingJourneys,
  setJourneys,
  setJourneyPermissions,
  dispatchAvatars, resetState,
} from './actions'

export const initialiseJourneys = (uid) => {
  return async (dispatch) => {

    let journeys = await dispatch(downloadJourneys())

    let ownJourney = findOwnJourney(uid, journeys)
    if(ownJourney){
      dispatch(setSelectedJourney(ownJourney))
    }

    if (journeys?.length === 1){
      dispatch(setJourney(journeys[0], uid))
    }
  }
}

export const downloadJourneys = () => {
  return async (dispatch) => {
    dispatch(setDownloadingJourneys(true))

    const journeys = await doDownload(dispatch)

    dispatch(setJourneys(journeys))

    if (journeys?.length){
      dispatch(downloadJourneyAvatars(journeys))
    }
    dispatch(setDownloadingJourneys(false))

    return journeys
  }
}

export const downloadJourneyAvatars = (journeys) => {
  return async (dispatch) => {

    if (!journeys?.length){
      return
    }

    const now = Date.now()

    const journeyAvatars = journeys.map(journey => {
      return {
        uid: journey.uid,
        user_picture: journey.user_picture,
        download_date: now,
        checked_date: now
      }
    })

    dispatch(setAvatars(journeyAvatars))
  }
}

export const downloadPostAvatars = (posts) => {
  return async (dispatch) => {

    if(!posts){
      return
    }

    let localAvatars = localStorage.getItem('avatars') ? JSON.parse(localStorage.getItem('avatars')) : []

    const now = Date.now()

    for(let i = 0; i < posts.length; i ++){
      const post = posts[i]

      if(!post.posted_by_id && !post.group_user_id){
        continue
      }

      const authorId = post.group_user_id || post.posted_by_id
      const authorChangedTime = post.group_user_changed_date || post.user_changed_date

      const localAvatar = localAvatars.find((avatar) => +avatar.uid === +authorId)

      // Only need to check each avatar once
      if(localAvatar?.checked_date === now){
        continue
      }

      // Javascript timestamps are in milliseconds but unix timestamps are in seconds - hence * 1000
      if(!localAvatar || (authorChangedTime * 1000) > localAvatar.download_date) {

        const avatar = await downloadAvatar(authorId, dispatch)

        if (!avatar?.length){
          continue
        }

        if(!localAvatar){
          avatar[0].download_date = Date.now()
          avatar[0].checked_date = now
          localAvatars.push(avatar[0])
        }
        else{
          localAvatar.user_picture = avatar[0].user_picture
          localAvatar.download_date = Date.now()
          localAvatar.checked_date = now
        }
      }
      else{
        localAvatar.checked_date = now
      }
    }

    localStorage.setItem('avatars', JSON.stringify(localAvatars))

    dispatch(setAvatars(localAvatars))
  }
}

export const loadJourneys = (dispatch) => {
  const journeys = localStorage.getItem('journeys')

  dispatch(setJourneys({
    journeys: JSON.parse(journeys),
    journeysDownloaded: false,
    ownJourney: null
  }))
}

export const setJourney = (journey, userId) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    localStorage.setItem('goals', JSON.stringify([]))
    localStorage.setItem('meetings', JSON.stringify([]))
    localStorage.setItem('actions', JSON.stringify([]))
    localStorage.setItem('selectedJourney', JSON.stringify(journey))

    let perms = null
    if(parseInt(journey.uid) !== parseInt(userId)){
      perms = await downloadPermissions(journey.id)
    }

    dispatch(setJourneyPermissions(perms))
    dispatch(setSelectedJourney(journey))

    dispatch(setBusyIndicator(false))
  }
}

export const setAvatars = (avatars) => {
  return (dispatch) => {
    dispatch(dispatchAvatars(avatars))
  }
}

export const clearJourneys = () => {
  return (dispatch) => dispatch(resetState())
}