import React, {useMemo} from "react";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const RatingDial = ({rating}) => {

  const ratingValue = useMemo(() => {
    // For some reason the rating is sometimes an array and sometimes
    // an integer - haven't worked out why so this is a workaround
    if (rating?.length) return ''

    return rating ?? '0'

  }, [rating])

  return(
    <CircularProgressbar
        value={rating * 10}
        text={ratingValue}
        strokeWidth={15}
        styles={buildStyles({
          pathColor: (rating > 8) ? '#a8c70e' : (rating > 2) ? '#f08105' : '#de2d2c',
          textColor: (rating > 8) ? '#a8c70e' : (rating > 2) ? '#f08105' : '#de2d2c'
        })}
    />
  )
}