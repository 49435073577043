import {setBusyIndicator, setDownloadingIndicator, updateGroupActionProps} from '../../../app'
import {
  dispatchPosts,
  updatePost,
  setReportedStatus,
  setStoryFilter,
  setReplies,
  setDownloadingReplies,
  setSelectedPostId,
  updateReply,
  setSelectedMedia,
  setLikeList,
  setGoalList,
  resetState,
} from './actions'

import {
  downloadPosts as doDownload,
  downloadPost,
  uploadPost,
  likePost,
  uploadPostImages,
  uploadPostVideos,
  uploadPostReport,
  dlPostMedia, getLikeList, downloadAvatar, fetchPostGoals,
} from '../../../utils/api/journey'
import {REPORTED_STATUS_REPORTED, REPORTED_STATUS_FAILED} from '../../constants'
import {downloadPostAvatars, setAvatars} from "../../state"

export const selectMedia = (media) => {
  return (dispatch) => dispatch(setSelectedMedia(media))
}

export const refreshPostMedia = (post) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    post.media = await dlPostMedia(post?.id)
    dispatch(updatePost(post))

    dispatch(setBusyIndicator(false))
  }
}

export const downloadPostMedia = (post) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    if (post?.media) {
      dispatch(setSelectedMedia(post.media))
    }

    const media = await dlPostMedia(post?.id)
    if (media) {
      post.media = media
      dispatch(updatePost(post))
      dispatch(setSelectedMedia(post.media))
    }
    dispatch(setBusyIndicator(false))
  }
}

export const downloadPosts = (profileId, getNextPage) => {
  return async (dispatch) => {
    dispatch(setDownloadingIndicator(true))

    const posts = await doDownload(profileId, null, getNextPage, dispatch)

    if (!posts){
      return []
    }

    dispatch(setPosts(posts, !!getNextPage))
    dispatch(setDownloadingIndicator(false))
    dispatch(downloadPostAvatars(posts))
  }
}

export const setPosts = (posts, concat) => {
  return (dispatch) => {
    dispatch(dispatchPosts(posts, concat))
  }
}

export const selectPost = (postId) => {
  return async (dispatch) => {
    dispatch(setSelectedPostId(postId))
    if (postId === null){
      dispatch(updateGroupActionProps(null, null, null))
    }
  }
}

export const savePost = (post) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    await uploadPostImages(post?.media?.filter(m => m.type === 'image'), dispatch)
    await uploadPostVideos(post?.media?.filter(m => m.type === 'video'), post.id, dispatch)

    const savedPostId = await uploadPost(post, dispatch)

    if (!savedPostId) {
      dispatch(setBusyIndicator(false))
      return null
    }

    const savedPost = await downloadPost(savedPostId, dispatch)

    dispatch(updatePost(savedPost))
    dispatch(setBusyIndicator(false))

    return savedPost
  }
}

export const reportPost = (post, reportText) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const reported = await uploadPostReport(post.id, reportText, dispatch)

    if(!reported){
      dispatch(setReportedStatus(REPORTED_STATUS_FAILED))
      return
    }

    post.post_active = 0
    await uploadPost(post, dispatch)
    dispatch(updatePost(post))

    dispatch(setReportedStatus(REPORTED_STATUS_REPORTED))
    dispatch(setBusyIndicator(false))
  }
}

export const toggleLike = (liked, post, reactionId, userId) => {
  return async (dispatch) => {
    const likeId = await likePost(liked, post.id, reactionId, userId, dispatch)

    if (!likeId) {
      return null
    }

    const likedPost = await downloadPost(post.id, dispatch)
    dispatch(refreshPost(likedPost))

    return likedPost
  }
}

export const setFilter = (filter) => {
  return (dispatch) => {
    dispatch(setStoryFilter(filter))
  }
}

export const downloadReplies = (profileId, postId) => {
  return (dispatch) => {
    dispatch(setDownloadingReplies(true))

    return doDownload(profileId, postId).then(
      (posts) => {
        dispatch(setReplies(posts))
        dispatch(setDownloadingReplies(false))
        dispatch(downloadPostAvatars(posts))
      }
    )
  }
}

export const saveReply = (reply, postId) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    const replyId = await uploadPost(reply)

    if(replyId){
      const replyPost = await downloadPost(replyId)
      if (replyPost){
        dispatch(updateReply(replyPost))
      }

      const parentPost = await downloadPost(postId)
      if (parentPost){
        dispatch(updatePost(parentPost))
      }

      dispatch(updateGroupActionProps(null, null, null))
      dispatch(setBusyIndicator(false))
      return parentPost
    }
    else {
      dispatch(updateGroupActionProps(null, null, null))
      dispatch(setBusyIndicator(false))
      return null
    }

  }
}

export const clearReplies = () => {
  return (dispatch) => {
    dispatch(setReplies([]))
  }
}

export const deletePost = (post) => {
  return async (dispatch) => {
    dispatch(setBusyIndicator(true))

    post.deleted = true
    await uploadPost(post, dispatch)

    dispatch(setBusyIndicator(false))
  }
}

export const refreshPost = (post) => {
  return (dispatch) =>
    parseInt(post?.is_reply) === 1 ? dispatch(updateReply(post)) : dispatch(updatePost(post))
}

export const downloadLikeList = (post) => {
  return async (dispatch) => {

    dispatch(setBusyIndicator(true))

    let likes = await getLikeList(post)

    let localAvatars = localStorage.getItem('avatars') ? JSON.parse(localStorage.getItem('avatars')) : []

    for(let i = 0; i < likes.length; i ++) {
      const like = likes[i]

      const avatar = localAvatars.find(a => parseInt(a.uid) === parseInt(like.likedById))

      if (avatar) {
        like.avatar = avatar.user_picture
      }
      else{
        const avatar = await downloadAvatar(like.likedById, dispatch)

        if (!avatar?.length) {
          continue
        }

        like.avatar = avatar[0]?.user_picture

        dispatch(setAvatars(avatar))
      }
    }

    dispatch(setLikeList(likes))
    dispatch(setBusyIndicator(false))
  }
}

export const clearLikeList = () => {
  return async (dispatch) => {
    dispatch(setLikeList([]))
  }
}

export const downloadGoalList = (postId) => {
  return async dispatch => {
    dispatch(setBusyIndicator(true))

    const goals = await fetchPostGoals(postId, dispatch)

    dispatch(setGoalList(goals))
    dispatch(setBusyIndicator(false))
  }
}

export const clearGoalList = () => {
  return async (dispatch) => {
    dispatch(setGoalList([]))
  }
}

export const clearStory = () => {
  return (dispatch) => dispatch(resetState())
}