import {
  DOWNLOADING_GOALS,
  SAVE_GOAL,
  SET_GOALS,
  SET_SHOW_COMPLETE_GOALS,
  SET_SHOW_GOAL_ACTIONS,
  UPDATE_GOAL,
  RESET_STATE,
} from "./action-types"

export const setDownloadingGoals = (downloading) => ({
  type: DOWNLOADING_GOALS,
  payload: downloading
})

export const setGoals = (goals) => ({
  type: SET_GOALS,
  payload: goals
});

export const setSaveGoal = (goal) => ({
  type: SAVE_GOAL,
  payload: goal
})

export const setShowGoalActions = (goal, visible) => ({
  type: SET_SHOW_GOAL_ACTIONS,
  payload: {
    goal: goal,
    visible: visible
  }
})

export const setShowCompleteActions = (showComplete) => ({
  type: SET_SHOW_COMPLETE_GOALS,
  payload: showComplete
})

export const updateGoal = (goal) => ({
  type: UPDATE_GOAL,
  payload: goal
})

export const resetState = () => ({
  type: RESET_STATE
})