import {get, post, patch} from './api'
import { formatISODate } from '../helpers/date'
import {uploadImage, uploadVideo, uploadVideoCover} from "./file";
import { storyPaginationSize } from '../../constants/constants'
import * as urls from '../../constants/urls'

export const downloadJourneys = async (dispatch) => {
  let journeys = await get('/viewsjson/journey-list', null, dispatch)

  if(journeys.message){
    alert(journeys.message)
    journeys = []
  }

  journeys = journeys.map((j) => {
    return {...j, circle_of_support: JSON.parse(j?.circle_of_support)}
  })

  localStorage.setItem('journeys', JSON.stringify(journeys))

  return journeys
}

// TODO - need a view to retrieve a single journey

export const downloadGoals = (profileId, dispatch) => {

  return get('/viewsjson/goalsv2', 'profile_id=' + profileId, dispatch)
}

export const downloadGoalRatings = async (profileId, dispatch) => {

  return get('/viewsjson/goal-ratings-profile', `profile_id=${profileId}`, dispatch)
}

export const uploadGoal = (goal, dispatch) => {

  const upload = {
    "field_goal":[{"value": goal.title}],
    "field_goal_description":[{"value": goal.description}],
    "field_profile_reference":[{"target_id": goal.profile_id}],
    "field_deleted":[{"value": goal.deleted ? 1 : 0}]
  }

  if(goal.id){
    upload.id = [{"value": goal.id}]
  }

  const uploadFunc = (goal.id) ? patch : post
  const url = (goal.id) ? '/goal/' + goal.id : '/entity/goal'

  return uploadFunc(url, upload, null, dispatch).then(
    (response) => {
      // alert(JSON.stringify(response))
      return Boolean(response.id)
    }
  )
}

export const downloadProfile = async (profileId, dispatch) => {
  return await get('/viewsjson/profile', `profile_id=${profileId}`, dispatch)
}

export const downloadSupporters = async (profileId, dispatch) => {
  return await get('/viewsjson/supporters-mentors-per-profile', `profile=${profileId}`, dispatch)
}

export const downloadMeetings = (profileId, dispatch) => {
  return get('/viewsjson/meetings', 'profile_id=' + profileId, dispatch).then(
    (meetings) => {
      localStorage.setItem('meetings', JSON.stringify(meetings))

      return meetings
    }
  )
}

export const downloadMeetingImages = async (meetingId, dispatch) => {
  const images = await get('/viewsjson/meeting-images', `meeting_id=${meetingId}`, dispatch)

  if (!images.length || images[0].image === 'null'){
    return []
  }

  return images
}

export const downloadMeetingLinks = async (meetingId, dispatch) => {
  const links = await get('/viewsjson/meeting-links', `meeting_id=${meetingId}`, dispatch)

  if (!links.length || links[0].link === 'null'){
    return []
  }

  return links
}

export const downloadActions = (profileId, dispatch) => {
  return get('/viewsjson/actionsv2', 'profile_id=' + profileId, dispatch).then(
    (actions) => {

      // Convert complete to boolean
      actions?.forEach(action => {
        action.complete = !!parseInt(action.complete)
      })

      localStorage.setItem('actions', JSON.stringify(actions))

      return actions
    }
  )
}

export const downloadAction = async (profileId, actionId, dispatch) => {
  const action = await get('/viewsjson/actionsv2', `profile_id=${profileId}&id=${actionId}`, dispatch)

  if(action?.length){
    action[0].complete = !!parseInt(action[0].complete)
  }

  return action[0]
}

export const uploadAction = (a, dispatch) => {

  const upload = {
    "field_action":[{"value": a.action}],
    "field_action_notes":[{"value": a.action_notes}],
    "field_mark_as_done":[{"value": a.complete}],
    "field_profile_reference":[{"target_id": a.profile_id}],
    "field_deleted":[{"value": a.deleted ? 1 : 0}]
  }

  upload.field_reminder = (a.reminder_time) ? [{"value": a.reminder_time}] : []

  if(a.goal_id){
    upload.field_goal_reference = [{"target_id": a.goal_id}]
  }

  if(a.due_date){
    upload.field_due_date = [{"value": formatISODate(a.due_date)}]
  }

  if(a.id){
    upload.id = [{"value": a.id}]
  }

  const uploadFunc = (a.id) ? patch : post
  const url = (a.id) ? '/action/' + a.id : '/entity/user_action'

  return uploadFunc(url, upload, null, dispatch).then(
      (response) => {
        if(response.message){
          alert(response.message)
          return false
        }

        if(!response.id){
          alert('Unknown error')
          return false
        }

        // For some unknown reason the action gets stripped of its properties after postback?!
        a.id = response.id[0].value
        a.created_by_id = response.created_by_id[0].target_id
        a.profile_id = response.field_profile_reference[0].target_id
        a.action = response.field_action[0].value
        a.action_notes = response?.field_action_notes[0]?.value
        a.complete = response?.field_mark_as_done[0]?.value
        a.goal_id = response?.field_goal_reference[0]?.target_id
        a.due_date = response?.field_due_date[0]?.value
        a.reminder_time = response?.field_reminder[0]?.value
        a.delete = response?.field_deleted[0]?.value

        return a
      }
  )
}

export const toggleComplete = (actionId, complete, dispatch) => {

  const upload = {
    "id":[{"value": actionId}],
    "field_mark_as_done":[{"value": complete ? 1 : 0}]
  }

  return patch('/action/' + actionId, upload, null, dispatch).then(
      (response) => {
        // alert(JSON.stringify(response))
        return !!response.id
      }
  )
}

export const downloadMeetingRatings = (meetingId, dispatch) => {
  return get('/viewsjson/goal-ratings-meeting', 'meeting_id=' + meetingId, dispatch).then(
      (ratings) => {
        // localStorage.setItem('goalRatings', JSON.stringify(ratings))

        return ratings
      }
  )
}

export const downloadPosts = async (profileId, replyToId, getNextPage = false, dispatch) => {

  let pageNumber = getNextPage ? parseInt(localStorage.getItem('story_page_number')) + 1 : 1
  if(!pageNumber){
    localStorage.setItem('story_page_number', '1')
    pageNumber = 1
  }

  let qs = `api_version=5&profile_id=${profileId}&page=${pageNumber}`
  if(replyToId){
    qs = `${qs}&post_id=${replyToId}`
  }

  try{
    let posts = await get('/user_post_rest_api/user_post', qs, dispatch)

    if(!posts){
      return []
    }

    // Replies aren't currently paginated
    if(!replyToId && posts.length === storyPaginationSize){
      localStorage.setItem('story_page_number', (pageNumber).toString())
    }

    posts = getCoverImages(posts, dispatch)

    return posts
  }
  catch (e) {
    return []
  }
}

export const getMultiFeed = async (dispatch) => {

  let posts = await get('/user_post_rest_api/multi_feed', null, dispatch)

  if(!posts){
    return []
  }

  posts = getCoverImages(posts, dispatch)

  return posts
}

const getCoverImages = async (posts, dispatch) => {
  try {
    const coverImageUrls = await dlFileUrls(posts.filter(f => f.cover_picture_id).map(p => p.cover_picture_id), dispatch)

    posts.forEach((post) => {
      post.cover_picture = coverImageUrls.find(x => x.fileId === post.cover_picture_id)?.url ?? null
    })
  }
  catch (e) {

  }
  return posts
}

export const dlFileUrls = async (fileIds, dispatch) => {
  const data = {
    'ids': fileIds
  }
  // console.log({fileIds})
  return await post('/h2t_rest_api/fileUrl', data, null, dispatch)
}

export const downloadPost = async (postId, dispatch) => {

  const postArray = await get('/user_post_rest_api/user_post', `api_version=5&post_id=${postId}`, dispatch)

  if(!postArray?.length){
    return null
  }

  const post = postArray[0]

  if (post?.cover_picture_id) {
    const coverImageUrl = await dlFileUrls([post.cover_picture_id], dispatch)
    post.cover_picture = coverImageUrl?.length ? coverImageUrl[0]?.url : null
  }

  return post
}

export const getLikeList = async (post, dispatch) => {
  return await get('/h2t_rest_api/likeList', `post_id=${post.id}`, dispatch)
}

export const dlPostMedia = async (postId) => {
  return await get('/user_post_rest_api/media', `post_id=${postId}`)
}

export const downloadAvatar = (userId, dispatch) => {
  return get('/viewsjson/avatar', `user=${userId}`, dispatch).then(
    (avatar) => {
      if (avatar?.message){
        // alert(avatar?.message)
        return false
      }
      return avatar
    }
  )
}

export const uploadPostImages = async (images, dispatch) => {

  for (const image of images){
    if(image.image_fid === 0){
      image.image_fid = await uploadImage(urls.imageUploadUrl, image.image, dispatch)
    }
  }
}

export const uploadPostVideos = async (videos, postId, dispatch) => {

  for (const vid of videos){
    if (vid.video_fid === 0){
      vid.video_fid = await uploadVideo(urls.videoUploadUrl, vid.video, dispatch)
      vid.image_fid = await uploadVideoCover(urls.coverUploadUrl, vid.image, dispatch)

      const paragraph = await uploadParagraph(vid, postId)
      if (paragraph) {
        vid.paragraph_id = paragraph.id[0].value
        vid.paragraph_revision_id = paragraph.revision_id[0].value
      }
    }
  }
}

export const uploadProfileVideo = async (profileId, video, dispatch) => {

  video.video_fid = await uploadVideo(urls.profileVideoUploadUrl, video.video, dispatch)

  if (!video.video_fid){
    return
  }

  const paragraph = await uploadParagraph(video, profileId)
  if (paragraph) {
    video.paragraph_id = paragraph.id[0].value
    video.paragraph_revision_id = paragraph.revision_id[0].value
  }
}

const uploadParagraph = async (video, parentId) => {

  const body = {
    "field_video_secure": {
      "value": video.video_fid
    },
    "field_thumbnail": {
      "value": video.image_fid
    },
    "parent_id": [
      {
        "value": parentId
      }
    ],
    "parent_type": [
      {
        "value": "node"
      }
    ],
    "parent_field_name": [
      {
        "value": "field_videos"
      }
    ],
    "type": [
      {
        "target_id": "video"
      }
    ]
  }
  return await post('/entity/paragraph', body)
}

export const uploadPostReport = async (postId, reportText, dispatch) => {

  const upload = {
    "field_post_reference":[{"target_id":postId}],
    "field_reason_for_flagging":[{"value":reportText}]
  }

  const response = await post('/entity/post_flag', upload, null, dispatch)

  if(response.message){
    alert(response.message)
    return false
  }

  return !!response
}

export const uploadProfile = async (profileId, profileVideo, dispatch) => {

  const upload = {}

  if (profileVideo){
    upload.field_profile_video = [{"target_id": profileVideo.paragraph_id, "target_revision_id": profileVideo.paragraph_revision_id, "target_type": "paragraph"}]
  }
  else{
    upload.field_profile_video = []
  }

  const response = await patch(`/profile/${profileId}`, upload, null, dispatch)
  // console.log({response})

  if(response.message){
    // TODO log error
    console.log(response.message)
    return false
  }

  return true
}

export const uploadPost = (userPost, dispatch) => {

  const upload = {
    "field_profile_reference":[{"target_id": userPost.profile_id}],
    "field_post":[{"value": userPost.post_text}],
    "field_active":[{"value": userPost.post_active}],
    "field_deleted":[{"value": userPost.deleted ? 1 : 0}],
    "field_videos": [],
    "field_images": [],
    "field_goal_reference": []
  }

  const images = userPost?.media?.filter(m => m.type === 'image') || []
  const videos = userPost?.media?.filter(m => m.type === 'video') || []

  images?.forEach((image) => {
    if(image.image_fid){
      upload.field_images.push({"target_id": image.image_fid, "target_type": "file"})
    }
  })

  videos?.forEach((video) => {
    if(video.paragraph_id){
      upload.field_videos.push({"target_id": video.paragraph_id, "target_revision_id": video.paragraph_revision_id, "target_type": "paragraph"})
    }
  })

  userPost?.goal_ids?.forEach(goal => {
    upload.field_goal_reference.push({"target_id": goal})
  })
  // if(userPost.goal_ids.length){
  //   upload.field_goal_reference = [{"target_id": userPost.goal_id}]
  // }
  // else{
  //   upload.field_goal_reference = []
  // }

  if(userPost.id){
    upload.id = [{"value": userPost.id}]
  }

  if(userPost.parentPostId){
    upload.field_reply_to = [{"target_id": userPost.parentPostId}]
  }

  if (userPost.groupLoginAuthorId){
    upload.field_group_login_author_uid = [{"target_id": userPost.groupLoginAuthorId}]
  }

  const uploadFunc = (userPost.id) ? patch : post
  const url = (userPost.id) ? '/post/' + userPost.id : '/entity/user_post'

  return uploadFunc(url, upload, null, dispatch).then(
      (response) => {
        if(response.message){
          alert(response.message)
          return null
        }

        return response?.id[0]?.value
      }
  )
}

export const likePost = async (liked, postId, reactionId, userId, dispatch) => {
  if(reactionId){
    return await updateReaction(liked, postId, reactionId, userId, dispatch)
  }
  else{
    return await createReaction(postId, userId, dispatch)
  }
}

const createReaction = async (postId, userId, dispatch) => {
  const upload = {
    "field_post_reference":[{"target_id": postId}],
    "created_by_id": [{"target_id": userId}]
  }

  let response = await post('/entity/reaction', upload, null, dispatch)

  if(response.message){
    alert(response.message)
  }

  return response?.id[0].value ?? 0
}

const updateReaction = async (liked, postId, reactionId, userId, dispatch) => {
  const upload = {
    "field_active":[{"value": liked ? 1 : 0}],
    "changed_by_id": [{"target_id": userId}]
  }

  let response = await patch(`/reaction/${reactionId}`, upload, null, dispatch)

  if(response.message){
    alert(response.message)
  }

  return response.id && response.id.length > 0 ? response.id[0].value : 0
}

export const fetchPostGoals = async (postId, dispatch) => {
  return await get('/viewsjson/goals-per-post', `post=${postId}`, dispatch)
}
