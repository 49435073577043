export const findOwnJourney = (uid, journeys) => {
  if(!journeys || journeys.length === 0) {
    return null
  }
  return journeys.find((j) => parseInt(j.uid) === parseInt(uid))
}

export const userCanViewActions = (journey, journeyPermissions, userId) => {

  if (parseInt(journey.uid) === parseInt(userId)){
    return true
  }

  return parseInt(journeyPermissions?.view_actions) === 1
}

export const userCanAddActions = (journey, journeyPermissions, userId) => {

  if (parseInt(journey.uid) === parseInt(userId)){
    return parseInt(journey.individual_can_add_actions) === 1
  }

  return parseInt(journeyPermissions?.add_actions) === 1
}

export const userCanViewGoals = (journey, journeyPermissions, userId) => {

  if (parseInt(journey.uid) === parseInt(userId)){
    return true
  }

  return parseInt(journeyPermissions?.view_goals) === 1
}

export const userCanAddGoals = (journey, journeyPermissions, userId) => {

  if (parseInt(journey.uid) === parseInt(userId)){
    return parseInt(journey.individual_can_add_goals) === 1
  }

  return parseInt(journeyPermissions?.add_goals) === 1
}

export const userIsMentor = (journey, user) => {
  const journeyUser = journey?.circle_of_support.find((c) => parseInt(c.uid) === parseInt(user.uid))
  return journeyUser?.type === 'mentor'
}