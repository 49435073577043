import {loadUser} from "./actions";
import {downloadDetails} from "../utils/api/user";
import {setUserDetails} from "./action-creators";

export const initialiseUser = async (dispatch) => {

  dispatch(loadUser())

  let userDetails = await downloadDetails(dispatch)

  if(!userDetails){
    return null
  }

  dispatch(setUserDetails(userDetails))

  return userDetails.uid

}