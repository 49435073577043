import {
  IonList,
  IonItem,
  IonLabel,
  IonAvatar
} from "@ionic/react";
import React from "react";
import {DefaultTemplate} from "../../components";
import { useHistory } from "react-router-dom";
import {SkeletonText} from './components'
import {NoContent} from "../../components";

export const Supporting = ({
                             supportedJourneys,
                             selectJourney,
                             downloadingJourneys,
                             downloadJourneys,
                             userId,
                             dictionary,
                           }) => {

  const history = useHistory();

  const select = async (journey) => {
    await selectJourney(journey, userId)
    history.push('/journey/story')
  }

  return (
      <DefaultTemplate pageTitle={dictionary.supporting}
                       refreshFunction={() => downloadJourneys(userId) }
                       dictionary={dictionary}>

        <SkeletonText
          isVisible={downloadingJourneys}
        />

        <NoContent
          isVisible={!downloadingJourneys && !supportedJourneys?.length}
          text={dictionary.not_supporting}
        />

        {
          !downloadingJourneys && supportedJourneys?.length &&
          <IonList lines='inset'>
            {supportedJourneys?.map((elem, index) => {
              return (
                <IonItem key={index} button onClick={() => select(elem)} detail>
                  <IonAvatar slot="start">
                    <img src={elem.user_picture} alt="" />
                  </IonAvatar>
                  <IonLabel>
                    {elem.display_name}
                  </IonLabel>
                </IonItem>
              )
            })}
          </IonList>
        }
      </DefaultTemplate>
  );
}