export const convertBase64ToBlob = (base64Data, mediaType) => {

  const rawData = atob(base64Data);
  const bytes = new Array(rawData.length);
  for (let x = 0; x < rawData.length; x++) {
    bytes[x] = rawData.charCodeAt(x);
  }
  const arr = new Uint8Array(bytes);

  return new Blob([arr], {type: mediaType});
}