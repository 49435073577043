import {IonItem, IonList, IonIcon, IonButton, IonBadge} from "@ionic/react"
import { checkboxOutline } from 'ionicons/icons';
import {GoalItem, RatingBar, RatingGraph} from "../../components"
import {ModalTemplate, NoContent} from "../../../../components"
import React, {useCallback} from "react"
import styles from './styles.module.scss'
import {MeetingDetail} from '../../../meetings'
import he from 'he'
import {userCanViewActions} from '../../../logic'
import {GOAL_ACTIONS} from "../../../../constants/modals"

export const GoalProgress = ({
                               isOpen,
                               close,
                               goal,
                               meetings,
                               openMeetingDetails,
                               parent,
                               userId,
                               openModal,
                               dictionary,
                             }) => {

  const openMeeting = useCallback((meetingId) => {
    const selectedMeeting = meetings?.find((m) => parseInt(m.id) === parseInt(meetingId))
    if(selectedMeeting){
      openMeetingDetails(selectedMeeting)
    }
  }, [meetings, openMeetingDetails])

  if(goal?.ratings?.length === 0){
    return (
      <ModalTemplate isOpen={isOpen}
                     title={dictionary.goal_progress}
                     closeMethod={() => {close()}}
                     parent={parent}>
        <NoContent isVisible={true} text={dictionary.goal_not_reviewed} />
      </ModalTemplate>
    )
  }

  return (
    <ModalTemplate isOpen={isOpen}
                   title={dictionary.goal_progress}
                   closeMethod={() => {close()}}
                   parent={parent}>
      <div className={styles.progress_modal_content}>

        <GoalItem
          goal={goal}
          userId={userId}
          rowDisplay={false} />

        {
          goal?.actions?.length > 0 &&
          <span className={styles.progress_modal_actions}>
            {
              userCanViewActions && 
              <IonButton onClick={() => openModal(GOAL_ACTIONS, goal)} fill="outline" expand="block" color="secondary" size="large"><IonIcon slot="start" icon={checkboxOutline}></IonIcon>Actions <IonBadge color="danger" slot="end" className={styles.action_button_badge}>{goal?.actions?.length}</IonBadge> </IonButton>
            }
          </span>
        } 
        <div className={styles.rating_chart}>
          <RatingGraph ratings={goal?.ratings} />
        </div>
        <IonList lines='full'>
          {
            goal?.ratings?.map((rating, index) => {
              return (
                <IonItem key={index}>
                  <div className={styles.rating_item}>
                    <RatingBar rating={rating} />
                    <div className={styles.rating_item_meeting} onClick={ () => openMeeting(rating.meeting_id)}>
                      {rating.meeting_id && `${dictionary.scored_id} ${he.decode(rating.meeting_title)}`}
                    </div>
                  </div>

                </IonItem>
              )
            })
          }
        </IonList>


        
      </div>

      <MeetingDetail parent={parent} />

    </ModalTemplate>
  )
}